define('browzine-web/routes/confirm-email', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    header: Ember.inject.service(),
    auth: Ember.inject.service(),
    myBookshelf: Ember.inject.service(),
    myArticles: Ember.inject.service(),
    intl: Ember.inject.service(),
    showBpsMessage: null,

    beforeModel: function beforeModel(transition) {
      this.set('myBookshelf.returnRoute', transition.queryParams.returnRoute);
      this.set('myBookshelf.loginRequired', transition.queryParams.loginRequired);
      this.set('myBookshelf.shelve', transition.queryParams.shelve);
      this.set('myArticles.addToMyArticles', transition.queryParams.addToMyArticles);
      localStorage.setItem('myArticles.addToMyArticles', transition.queryParams.addToMyArticles);

      if (transition.queryParams.showBpsMessage && transition.queryParams.showBpsMessage !== "undefined") {
        this.set('showBpsMessage', JSON.parse(transition.queryParams.showBpsMessage));
      }
    },


    model: function model(params) {
      return this.get('auth').confirmEmail(params.token);
    },

    titleToken: function titleToken() {
      var emailConfirmedTitle = this.get('intl').t('auth.confirm_email_title');
      this.get('header').setProperties({
        subject: {
          value: 'BrowZine',
          template: false
        },
        title: {
          value: emailConfirmedTitle,
          template: false
        }
      });

      return emailConfirmedTitle;
    },
    setupController: function setupController(controller) {
      controller.set('showBpsMessage', this.get('showBpsMessage'));
    }
  });
});