define('browzine-web/components/share-modal/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    classNames: ['share-modal'],

    exporters: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var modal = this.get('modal');

      Ember.run.next(function () {
        modal.element = _this.element;
        modal.element.id = _this.element.id;
      });

      modal.ok = function () {
        _this.ok();
      };

      modal.cancel = function () {
        _this.cancel();
      };
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      var modal = this.get('modal');

      modal.onShow = function () {
        Ember.run.next(function () {
          _this2.$('ul.services li:first-child').focus();
        });
      };
    },


    actions: {
      ok: function ok() {
        var modal = this.get('modal');
        modal.hide();
      },
      copyLinkToArticle: function copyLinkToArticle() {
        this.send('ok');

        var copyLinkToArticleModal = this.get('copyLinkToArticleModal');
        copyLinkToArticleModal.show();
      },
      twitter: function twitter() {
        var article = this.get('article');
        var exporters = this.get('exporters');
        exporters.twitter(article);
        this.send('ok');
      },
      facebook: function facebook() {
        var article = this.get('article');
        var exporters = this.get('exporters');
        exporters.facebook(article);
        this.send('ok');
      },
      linkedin: function linkedin() {
        var article = this.get('article');
        var exporters = this.get('exporters');
        exporters.linkedin(article);
        this.send('ok');
      },
      email: function email() {
        var article = this.get('article');
        var exporters = this.get('exporters');
        exporters.email(article);
        this.send('ok');
      }
    }
  });
});