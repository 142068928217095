define('browzine-web/controllers/application', ['exports', 'ember-intl'], function (exports, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    localeClass: (0, _emberIntl.intl)(function (intl) {
      if (intl.get('primaryLocale') === 'fr-fr') {
        return 'locale-fr-fr';
      } else if (intl.get('primaryLocale') === 'ja') {
        return 'locale-ja';
      } else {
        return 'locale-en-us';
      }
    })
  });
});