define('browzine-web/models/my-journal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(Ember.PromiseProxyMixin, {
    store: Ember.inject.service(),
    unreads: Ember.inject.service(),

    isMoving: false,

    journal: Ember.computed('isPending', 'unavailable', 'content', function () {
      if (this.get('isPending') || this.get('unavailable')) {
        return this.get('unresolved');
      } else {
        return this.get('content');
      }
    }),

    hasJournal: function hasJournal(journal) {
      return this.get('journal.id') === journal.get('id');
    },


    articleStatusCollection: Ember.computed('journal.id', function () {
      return this.get('unreads').getJournalStatusById(this.get('journal.id'));
    }),

    unreadCount: Ember.computed.alias('articleStatusCollection.unreadCount'),

    syncData: Ember.computed('journal', 'unavailable', 'isPending', {
      get: function get() {
        return {
          title: this.get('journal.title'),
          coverImage: this.get('journal.coverURL'),
          journal: this.get('journal.id')
        };
      },
      set: function set(key, value) {
        var _this = this;

        var store = this.get('store');
        var imageURL = (value.coverImage || '').replace('http:', 'https:');

        this.set('unresolved', {
          id: value.journal + '',
          title: value.title,
          coverURL: imageURL
        });

        // Look locally for journal, otherwise try to load it for the current library
        var journal = store.peekRecord('journal', value.journal);
        var promise = void 0;

        if (journal) {
          promise = Ember.RSVP.resolve(journal);
          this.set('unavailable', !journal.get('available'));
        } else {
          promise = store.findRecord('journal', value.journal, { preload: { library: this.get('library') } }).then(function (journal) {
            _this.set('unavailable', false);
            return journal;
          }).catch(function () {
            _this.set('unavailable', true);
            return {};
          });
        }

        this.set('promise', promise);

        if (journal) {
          return {
            journal: journal.get('id'),
            title: journal.get('title'),
            coverImage: journal.get('coverURL')
          };
        }

        return {
          journal: value.journal + '',
          title: value.title,
          coverImage: imageURL
        };
      }
    })
  });
});