define('browzine-web/components/my-journal-delete/component', ['exports', 'browzine-web/mixins/accordion-item', 'browzine-web/mixins/click-on-enter', 'browzine-web/mixins/trigger-on-enter'], function (exports, _accordionItem, _clickOnEnter, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_accordionItem.default, _clickOnEnter.default, _triggerOnEnter.default, {
    classNames: ['my-journal-delete', 'corner-element', 'right'],
    classNameBindings: ['isExpanded:showing', 'editBookcase:my-journal-delete-visible'],

    attributeBindings: ['title'],

    title: Ember.computed('journal.title', function () {
      return 'Delete ' + this.get('journal.title');
    }),

    click: function click() {
      if (this.get('isExpanded')) {
        this.send('contract');
      } else {
        this.send('expand');
      }
      return false;
    },


    actions: {
      delete: function _delete() {
        this.attrs.delete();
        return false;
      },
      move: function move() {
        this.attrs['move-start']();
        return false;
      }
    }
  });
});