define('browzine-web/services/error-reporter', ['exports', 'browzine-web/config/environment', 'browzine-web/errors/tracing'], function (exports, _environment, _tracing) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    auth: Ember.inject.service(),
    applicationSession: Ember.inject.service(),

    reportLibraryDetectionError: function reportLibraryDetectionError() {
      this._reportError('libraryDetectionError');
    },
    reportOpenAccessTokenError: function reportOpenAccessTokenError() {
      this._reportError('openAccessTokenError');
    },
    reportTooManySubscribeError: function reportTooManySubscribeError() {
      this._reportError('tooManySubscriptionAttempts', {
        webPageString: 'coming soon signup - too many subscribe attempts'
      });
    },
    reportUnknownSubscribeError: function reportUnknownSubscribeError() {
      this._reportError('unknownSubscribeError', {
        webPageString: 'coming soon signup - unknown error'
      });
    },
    reportLibraryAuthenticationError: function reportLibraryAuthenticationError(id) {
      this._reportError('libraryAuthenticationError', {
        libraryBZID: id
      });
    },
    reportJournalWithNoIssuesError: function reportJournalWithNoIssuesError(id) {
      this._reportError('journalWithNoIssuesError', {
        journal_bzid: id
      });
    },
    reportFailedArticleShare: function reportFailedArticleShare(response) {
      this._reportError('failedArticleShare', {
        webPageString: JSON.stringify(response)
      });
    },
    reportMyBookshelfUpdateError: function reportMyBookshelfUpdateError(error, requests, previousDocument, failedDocument) {
      this._reportError('myBookshelfUpdateError', {
        webPageString: JSON.stringify({
          error: error,
          requests: requests,
          previousDocument: previousDocument,
          failedDocument: failedDocument
        })
      });
    },
    reportMyArticlesUpdateError: function reportMyArticlesUpdateError(error, requests, previousDocument, failedDocument) {
      this._reportError('myArticlesUpdateError', {
        webPageString: JSON.stringify({
          error: error,
          requests: requests,
          previousDocument: previousDocument,
          failedDocument: failedDocument
        })
      });
    },
    reportMyBookshelfFetchError: function reportMyBookshelfFetchError(err) {
      this._reportError('myBookshelfFetchError', {
        webPageString: JSON.stringify(err)
      });
    },
    reportMyBookshelfParseError: function reportMyBookshelfParseError(err) {
      this._reportError('myBookshelfParseError', {
        webPageString: JSON.stringify(err)
      });
    },
    reportMyBookshelfValidationError: function reportMyBookshelfValidationError(failedDocument, errors) {
      this._reportError('myBookshelfValidationError', {
        webPageString: JSON.stringify({ failedDocument: failedDocument, errors: errors })
      });
    },
    reportMyArticlesValidationError: function reportMyArticlesValidationError(failedDocument, errors) {
      this._reportError('myArticlesValidationError', {
        webPageString: JSON.stringify({ failedDocument: failedDocument, errors: errors })
      });
    },
    reportMyBookshelfReadValidationError: function reportMyBookshelfReadValidationError(failedDocument, errors) {
      this._reportError('myBookshelfReadValidationError', {
        webPageString: JSON.stringify({ failedDocument: failedDocument, errors: errors })
      });
    },
    reportArticleStatusUpdateError: function reportArticleStatusUpdateError(err, previousDocument, failedDocument) {
      this._reportError('articleStatusUpdateError', {
        webPageString: JSON.stringify({
          error: err,
          previousDocument: previousDocument,
          failedDocument: failedDocument
        })
      });
    },
    reportArticleStatusValidationError: function reportArticleStatusValidationError(failedDocument, errors) {
      this._reportError('articleStatusValidationError', {
        webPageString: JSON.stringify({ failedDocument: failedDocument, errors: errors })
      });
    },
    reportArticleStatusReadValidationError: function reportArticleStatusReadValidationError(failedDocument, errors) {
      this._reportError('articleStatusReadValidationError', {
        webPageString: JSON.stringify({ failedDocument: failedDocument, errors: errors })
      });
    },
    reportHelpError: function reportHelpError(err) {
      this._reportError('helpError', {
        webPageString: JSON.stringify(err)
      });
    },
    reportUnknownError: function reportUnknownError(message, err) {
      console.log(message, err);
      if (err.status !== undefined) {
        this._reportError(message, {
          webPageString: 'the server responded with an error: ' + JSON.stringify(err),
          lastURLAccessed: err.lastTransitionUrl || window.location.href
        });
      } else {
        this._reportError(message, {
          webPageString: (err.message || message) + ": \n" + err.stack,
          lastURLAccessed: err.lastTransitionUrl || window.location.href
        });
      }
    },
    _reportError: function _reportError(error, data) {
      var couchDb = void 0;
      var loggedInUser = this.get('applicationSession').get('loggedInUser');

      // Browser identifier is namespaced so we can tell them apart from the library tokens that userd to come
      // out of here.
      var identifier = (loggedInUser || {}).email || 'BID-' + this.get('applicationSession').getBrowserIdentifier();

      if (loggedInUser) {
        couchDb = new URL(loggedInUser.couchdbDatabaseLocation).pathname.replace('/', '');
      }
      var email = Ember.$('#user-info-email').text();

      var defaultData = {
        appVersion: _environment.default.APP.version,
        lastURLAccessed: window.location.href,
        libraryBZID: this.get('applicationSession.selectedLibrary'),
        webPageString: error,
        platform: 'web',
        platformVersion: window.navigator.appVersion,
        deviceManuf: '',
        deviceModel: '',
        time_until_error: 0.0,
        errorType: error,
        deviceID: identifier,
        email: email
      };

      var mergedData = Ember.assign(defaultData, data || {});

      var webPageStringValue = {
        original: mergedData.webPageString,
        localStorage: localStorage,
        couchDb: couchDb
      };

      if (_environment.default.recordUserTrace) {
        webPageStringValue.userTrace = (0, _tracing.getTrace)();
      }

      mergedData.webPageString = JSON.stringify(webPageStringValue);

      if (_environment.default.errorReportingHost) {
        Ember.$.ajax({
          url: _environment.default.errorReportingHost + '/bzadmin/reportProblem/',
          type: 'POST',
          data: mergedData
        });
      } else {
        console.log("no errorReportingHost available, so not sending this error: ", mergedData);
      }
    }
  });
});