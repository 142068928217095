define('browzine-web/services/json-validator', ['exports', 'ajv', 'ajv/lib/refs/json-schema-draft-04.json'], function (exports, _ajv, _jsonSchemaDraft) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ajv = new _ajv.default({ schemaId: 'id' });
  // If you want to use both draft-04 and draft-06/07 schemas:
  // var ajv = new Ajv({schemaId: 'auto'});
  ajv.addMetaSchema(_jsonSchemaDraft);

  exports.default = Ember.Service.extend({
    validate: function validate() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var schema = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var result = ajv.validate(schema, data);

      if (!result) {
        console.log("Encountered an invalid document!", ajv.errors, data, schema);
      }

      return { isValid: result, errors: ajv.errors };
    }
  });
});