define('browzine-web/routes/library/subject/bookcase', ['exports', 'browzine-web/mixins/taxonomy-route', 'browzine-web/mixins/query-storing-route'], function (exports, _taxonomyRoute, _queryStoringRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_taxonomyRoute.default, _queryStoringRoute.default, {
    header: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    intl: Ember.inject.service(),

    model: function model(params) {
      return this.store.findRecord('bookcase', params.bookcase_id, { preload: { library: this.modelFor('library') } });
    },
    afterModel: function afterModel(bookcase) {
      return this.buildTaxonomy({
        subject: this.modelFor('library.subject'),
        bookcase: bookcase
      });
    },
    titleToken: function titleToken(model) {
      this.get('header').setProperties({
        subject: {
          value: 'subjects.subject',
          template: true
        },
        title: {
          value: Ember.get(model, 'name'),
          template: false
        }
      });

      return Ember.get(model, 'name');
    }
  });
});