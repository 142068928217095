define('browzine-web/routes/library/user-idle-session-expired', ['exports', 'browzine-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    header: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    intl: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var applicationSession = this.get('applicationSession');
      var library = this.modelFor('library');

      if (library.get('ssoEnabled')) {
        // SSO libraries also clear the library token on user
        // session expiration
        applicationSession.clearLibraryToken(library.get('id'));
      }
      applicationSession.clearLoggedInUser();
    },
    model: function model() {
      var library = this.modelFor('library');
      return {
        library: library
      };
    },
    titleToken: function titleToken() {
      this.get('header').setProperties({
        subject: {
          value: 'library.header.library',
          template: true
        },
        title: {
          value: 'idle_session_expired.document_title_token',
          template: true
        }
      });

      return this.get('intl').t('idle_session_expired.document_title_token');
    },


    actions: {
      reLogin: function reLogin() {
        var library = this.modelFor('library');

        if (library.get('ssoEnabled')) {
          var libraryId = library.get('id');
          var successPath = this.get('router._routerMicrolib').generate('accept-token', libraryId);
          var failurePath = this.get('router._routerMicrolib').generate('token-failure', libraryId);
          Ember.$.ajax({
            url: _environment.default.apiBaseUrl + '/sso/login',
            type: 'POST',
            data: JSON.stringify({
              libraryId: libraryId,
              client: 'bzweb',
              success: successPath,
              failure: failurePath
            }),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json'
          }).then(function (response) {
            var ssoGateway = response.ssoGateway;

            window.location.replace(ssoGateway);
          });
        } else {
          return this.transitionTo('library.login', library);
        }
      },
      selectLibrary: function selectLibrary() {
        return this.transitionTo('libraries');
      },
      continue: function _continue() {
        var library = this.modelFor('library');
        return this.transitionTo('library', library);
      }
    }

  });
});