define('browzine-web/mixins/error-helpers', ['exports', 'browzine-web/errors/ApiSessionExpiredError', 'browzine-web/errors/AuthRequiredError'], function (exports, _ApiSessionExpiredError, _AuthRequiredError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LIBRARY_SESSION_EXPIRED_CODE = 'expired_library_session';

  exports.default = Ember.Mixin.create({
    interpretError: function interpretError(httpStatus, errorPayload) {
      if (httpStatus === 401 && errorPayload.code === LIBRARY_SESSION_EXPIRED_CODE) {
        // The docs suggest DS.InvalidError may not be appropriate for session expirations
        var error = new _ApiSessionExpiredError.default(errorPayload);
        return error;
      } else if (httpStatus === 401) {
        var _error = new _AuthRequiredError.default(errorPayload);
        return _error;
      }
    },


    // The back-end is transitioning from an old format where
    // the error object is the top level object to one that
    // fits the JSON API error response spec, where the error object
    // is in an array held by the errors property of the top level
    // object.  This makes BZWeb forward-compatible with the
    // upcoming new format.
    getErrorObjectFromErrorResponse: function getErrorObjectFromErrorResponse(errorResponse) {
      if (errorResponse.errors && errorResponse.errors.length) {
        return errorResponse.errors[0];
      }

      if (errorResponse.length) {
        return errorResponse[0];
      }

      return errorResponse;
    }
  });
});