define('browzine-web/services/customizations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    auth: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    store: Ember.inject.service(),

    getCustomStyle: function getCustomStyle() {
      var libraryId = this.get('applicationSession.selectedLibrary');

      // This is to prevent a "You need to pass both a model name and id to the store's peekRecord method" error
      if (!libraryId) {
        return '';
      }

      var library = this.get('store').peekRecord('library', libraryId);

      if (!library) {
        return '';
      }

      var link = library.get('httpsCustomBackgroundImageUrl') || '';

      if (!link) {
        return '';
      }

      return 'background-size: auto; background-image: url(' + link + ');';
    }
  });
});