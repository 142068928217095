define('browzine-web/serializers/application', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function modelHasAttributeOrRelationshipNamedType(modelClass) {
    return Ember.get(modelClass, 'attributes').has('type') || Ember.get(modelClass, 'relationshipsByName').has('type');
  }

  exports.default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,

    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      return (0, _emberInflector.singularize)(key);
    },


    /**
     * FIXME This is a fix for an Ember Data bug:
     * https://github.com/emberjs/data/pull/4194
     */
    _normalizePolymorphicRecord: function _normalizePolymorphicRecord(store, hash, prop, primaryModelClass, primarySerializer) {
      var serializer = void 0,
          modelClass = void 0;
      var primaryHasTypeAttribute = modelHasAttributeOrRelationshipNamedType(primaryModelClass);
      // Support polymorphic records in async relationships
      if (!primaryHasTypeAttribute && hash.type && store._hasModelFor(this.modelNameFromPayloadKey(hash.type))) {
        serializer = store.serializerFor(this.modelNameFromPayloadKey(hash.type));
        modelClass = store.modelFor(this.modelNameFromPayloadKey(hash.type));
      } else {
        serializer = primarySerializer;
        modelClass = primaryModelClass;
      }

      return serializer.normalize(modelClass, hash, prop);
    }
  });
});