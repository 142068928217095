define('browzine-web/services/window-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    pushState: function pushState(state, title, url) {
      window.history.pushState(state, title, url);
    }
  });
});