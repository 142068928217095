define('browzine-web/components/collection-items/component', ['exports', 'moment', 'browzine-web/mixins/trigger-on-enter', 'ember-intl'], function (exports, _moment, _triggerOnEnter, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    classNames: ['collection-items'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    myArticles: Ember.inject.service(),
    sync: Ember.inject.service(),
    intl: Ember.inject.service(),

    noArticles: false,

    collectionNameEditableElementLabel: (0, _emberIntl.t)('my_articles.collection_name_editable_element_label'),
    sortByJournalDateAddedLinkAriaLabel: (0, _emberIntl.t)('my_articles.collection_sort_by_journal_date_added_link_label'),
    sortByJournalTitleLinkAriaLabel: (0, _emberIntl.t)('my_articles.collection_sort_by_journal_title_link_label'),
    sortByJournalTitleLinkText: (0, _emberIntl.t)('my_articles.collection_sort_by_journal_title_link_text'),
    removeCollectionModalTitle: (0, _emberIntl.t)('my_articles.remove_collection_modal_title'),
    removeCollectionModalMessage: (0, _emberIntl.t)('my_articles.remove_collection_modal_message'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('collectionRemoveModal', {});

      var state = Ember.getOwner(this).lookup('router:main')._routerMicrolib.state;

      if (state.fullQueryParams.searchVisible) {
        Ember.run.throttle(this, this.refreshCollectionList, 1000);
      }
    },


    sortedArticles: Ember.computed.sort('paginatedArticles', function (a, b) {
      var store = this.get('store');
      var state = Ember.getOwner(this).lookup('router:main')._routerMicrolib.state;
      var collectionId = state.params['library.my-articles.collection'].collection_id;
      var queryParams = state.queryParams;
      var sort = queryParams.sort;

      var collection = store.peekRecord('collection', collectionId);
      var aJournalSortTitle = a.get('journal.sortTitle');
      var bJournalSortTitle = b.get('journal.sortTitle');
      var aDateAdded = void 0;
      var bDateAdded = void 0;

      collection.get('items').forEach(function (collectionItem) {
        if (collectionItem.get('itemType') !== 'article') {
          return;
        }

        var syncId = collectionItem.get('itemId');

        if (syncId === a.get('syncId')) {
          aDateAdded = collectionItem.get('addedAt');
        } else if (syncId === b.get('syncId')) {
          bDateAdded = collectionItem.get('addedAt');
        }
      });

      switch (sort) {
        case 'journal':
          if (aJournalSortTitle > bJournalSortTitle) {
            return 1;
          } else if (aJournalSortTitle < bJournalSortTitle) {
            return -1;
          } else if ((0, _moment.default)(aDateAdded).isAfter(bDateAdded)) {
            return -1;
          } else {
            return 1;
          }
        case 'date':
          if ((0, _moment.default)(aDateAdded).isAfter(bDateAdded)) {
            return -1;
          } else {
            return 1;
          }
      }
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var state = Ember.getOwner(this).lookup('router:main')._routerMicrolib.state;

      if (!state.fullQueryParams.searchVisible) {
        Ember.run.throttle(this, this.refreshCollectionList, 1000);
      }
    },
    refreshCollectionList: function refreshCollectionList() {
      var _this = this;

      var store = this.get('store');
      var myArticles = this.get('myArticles');
      var collectionId = this.get('collectionId');

      if (!this.get('sync.initialCollectionLoadComplete')) {
        this.set('initializing', false);
        this.set('noArticles', true);
        return false;
      }

      this.set('initializing', true);
      this.set('noArticles', false);

      myArticles.loadCollections().then(function () {
        var collection = store.peekRecord('collection', collectionId);
        _this.set('collection', collection);

        var syncIds = collection.get('items').filter(function (item) {
          return !item.get('isDeleted');
        }).mapBy('itemId');

        if (syncIds.length > 0) {
          var start = 0;
          var fetchQueue = [];

          while (start <= syncIds.length) {
            var querySyncIds = syncIds.slice(start, start + 20);

            if (querySyncIds.length > 0) {
              var promise = store.query('article', {
                filter: {
                  syncId: querySyncIds.toString()
                },
                include: 'journal,issue'
              });

              fetchQueue.push(promise);
            }

            start = start + 20;
          }

          Ember.RSVP.all(fetchQueue).then(function () {
            var articles = store.peekAll('article').filter(function (article) {
              return syncIds.indexOf(article.get('syncId')) > -1;
            });

            // TODO - BZ-3556: use server-side paging / sorting
            _this.set('paginatedArticles', articles);
            _this.set('initializing', false);
          }).catch(function () {
            _this.set('noArticles', true);
          });
        } else {
          _this.set('noArticles', true);
        }
      });
    },


    actions: {
      updateCollectionName: function updateCollectionName(collection, newName) {
        if (collection && newName) {
          collection.set('name', newName);
          this.get('myArticles').saveCollection(collection);
        }
      },
      refreshCollectionList: function refreshCollectionList() {
        this.refreshCollectionList();
      },
      confirmRemoveCollection: function confirmRemoveCollection(collection) {
        var _this2 = this;

        var collectionRemoveModal = this.get('collectionRemoveModal');
        var collectionName = collection.get('name');

        collectionRemoveModal.removeCollection = function () {
          _this2.send('removeCollection', collection);
        };

        collectionRemoveModal.show();

        var intl = this.get('intl');
        var line1 = intl.t('my_articles.other_remove_collection_modal_message_1', { collectionName: collectionName });
        var line2 = intl.t('my_articles.other_remove_collection_modal_message_2');

        //TODO: Figure out how to push the content to confirm-modal component
        //See, https://thirdiron.atlassian.net/browse/BZ-4297
        this.$('.collection-remove-modal .bz-modal-content').html('\n        ' + line1 + '\n        <br><br>\n        ' + line2 + '\n      ');
      },
      removeCollection: function removeCollection(collection) {
        if (collection.get('id') !== 'collection-general') {
          collection.deleteRecord();
          this.get('myArticles').saveCollection(collection, 'removeCollection');
          this.get('router').transitionTo('library.my-articles.collection', 'collection-general');
        }
      }
    }
  });
});