define('browzine-web/mixins/taxonomy-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    windowHistory: Ember.inject.service(),

    buildTaxonomy: function buildTaxonomy(components) {
      var _this = this;

      var subject = components.subject;
      var bookcase = components.bookcase;
      var bookshelf = components.bookshelf;

      // This is failing repeatedly with old errors because of the relationship bug in
      // Ember data.  It needs to use our workaround methods for loading subject bookcases
      // and bookcase or subject bookshelves in order to work properly.
      return Ember.RSVP.hash({
        subject: subject,
        bookcases: subject.fixedHasManyLoad('bookcases'),
        bookcase: bookcase,
        bookshelves: (bookcase || subject).hasMany('bookshelves').reload(),
        bookshelf: bookshelf
      }).then(function (taxonomy) {
        _this.set('taxonomy', taxonomy);
      });
    },
    setupController: function setupController() {
      var taxonomy = this.get('taxonomy');
      var controller = this.controllerFor('library.subject');

      controller.set('subject', taxonomy.subject);
      controller.set('bookcases', taxonomy.bookcases);
      controller.set('bookcase', taxonomy.bookcase);
      controller.set('bookshelves', taxonomy.bookshelves);
      controller.set('bookshelf', taxonomy.bookshelf);
      controller.set('journals', undefined);

      var journalsContainer = taxonomy.bookshelf || taxonomy.bookcase || taxonomy.subject;
      controller.set('journalsContainer', journalsContainer);
    },


    actions: {
      willTransition: function willTransition() {
        var windowHistory = this.get('windowHistory');
        var applicationController = this.controllerFor('application');
        var routerUrl = this.get('router.url');

        applicationController.set('previousTaxonomyPath', routerUrl);
        windowHistory.pushState(null, null, routerUrl);

        return true;
      }
    }
  });
});