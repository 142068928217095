define('browzine-web/models/bookshelf', ['exports', 'ember-data', 'ember-data-has-many-query'], function (exports, _emberData, _emberDataHasManyQuery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberDataHasManyQuery.default.ModelMixin, {
    library: _emberData.default.belongsTo('library', { async: true }),
    name: _emberData.default.attr('string'),
    subject: _emberData.default.belongsTo('subject', { async: true }),
    bookcase: _emberData.default.belongsTo('bookcase', { async: true }),
    journals: _emberData.default.hasMany('journal', { async: true }),
    rankSortedJournals: _emberData.default.hasMany('journal', { async: true, inverse: null })
  });
});