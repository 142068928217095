define('browzine-web/components/article-loading-box/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),

    shouldShowHelp: Ember.computed(function () {

      if (this.get('article.openAccess') && (this.get('article.contentLocation') || this.get('article.fullTextFile'))) {
        return false;
      }

      if (this.get('loadingType') === 'fullText') {
        return !this.get('hasArticle') || this.get('issue.embargoed') || this.get('article.journal.accessedThroughAggregator') && this.get('article.inPress') || this.get('article.journal.embargoDescription') && this.get('article.inPress');
      } else if (this.get('loadingType') === 'inContext') {
        return !this.get('article.browseable');
      }
    }),

    supportTitle: Ember.computed('intl.primaryLocale', function () {
      return this.get('intl').t('library.interstitial.support');
    }),

    actions: {
      retry: function retry() {
        window.location.reload();
      }
    }
  });
});