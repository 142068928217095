define('browzine-web/services/exporters', ['exports', 'browzine-web/config/environment', 'browzine-web/services/exporters/zotero', 'browzine-web/services/exporters/mendeley'], function (exports, _environment, _zotero, _mendeley) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    flashMessages: Ember.inject.service(),
    errorReporter: Ember.inject.service(),
    intl: Ember.inject.service(),

    services: {
      zotero: _zotero.default.create(),
      mendeley: _mendeley.default.create()
    },

    share: function share(serviceName, library, article) {
      var _this = this;

      var service = this.get('services')[serviceName];
      var hasCredentials = service.hasCredentials();

      if (hasCredentials) {
        this.saveArticle(service, article, library, function () {
          _this.showSuccess(service.get('name'));
        });
      } else {
        this.addSuccessListener();
        this.setExportArticle(article);
        service.share(library, article);
      }
    },
    saveArticle: function saveArticle(service, article, library, onsuccess) {
      var _this2 = this;

      var options = service.getSaveParams();
      options.libraryId = library.get('id');

      Ember.$.ajax({
        url: _environment.default.apiHost + "/oauth/" + service.get('name') + "/save-article/" + article.id + "?" + Ember.$.param(options),
        type: "POST",
        success: onsuccess,
        error: function error(response) {
          _this2.get('flashMessages').alert("Error saving article");
          _this2.get('errorReporter').reportFailedArticleShare(response);
        }
      });
    },
    setExportArticle: function setExportArticle(article) {
      localStorage.setItem('export_article', article.id);
    },
    addSuccessListener: function addSuccessListener() {
      var _this3 = this;

      window.addEventListener('storage', function () {
        if (localStorage.getItem('save_article_success') === 'yes') {
          localStorage.setItem('save_article_success', 'no');
          _this3.showSuccess(localStorage.getItem('export_service'));
        }
      });
    },
    showSuccess: function showSuccess(service) {
      this.get('flashMessages').success("Succesfully saved article to " + service + "!");
    },
    zotero: function zotero(library, article) {
      this.share('zotero', library, article);
    },
    mendeley: function mendeley(library, article) {
      this.share('mendeley', library, article);
    },
    bibtex: function bibtex(library, article) {
      var libraryId = library.get('id');
      var url = _environment.default.apiHost + '/oauth/bibtext/' + article.id + '?' + Ember.$.param({ libraryId: libraryId });

      window.open(url);
    },
    refworks: function refworks(library, article) {
      var vendor = 'BrowZine';
      var filter = 'RIS Format';
      var libraryId = library.get('id');
      var callbackUrl = _environment.default.apiHost + '/oauth/endnote-ris/' + article.id + '?libraryId=' + libraryId;
      var params = Ember.$.param({
        vendor: vendor,
        filter: filter,
        url: callbackUrl
      });
      var url = 'http://www.refworks.com/express/expressimport.asp?' + params;

      window.open(url, 'RefWorksMain');
    },
    endnote: function endnote(library, article) {
      var libraryId = library.get('id');
      var url = _environment.default.apiHost + '/oauth/endnote-ris/' + article.id + '?' + Ember.$.param({ libraryId: libraryId });

      window.open(url);
    },
    twitter: function twitter(article) {
      //https://dev.twitter.com/web/intents
      var title = article.get('strippedTitle');
      var shareUrl = 'https://browzine.com/articles/' + article.id;
      var maxTitle = 280 - shareUrl.length;

      if (title.length > maxTitle) {
        title = title.substring(0, maxTitle) + "...";
      }

      var options = {
        text: title,
        url: shareUrl
      };

      var url = "https://twitter.com/intent/tweet?" + Ember.$.param(options);
      window.open(url, "_blank", "width=800,height=500");
    },
    facebook: function facebook(article) {
      //https://developers.facebook.com/docs/sharing/reference/share-dialog
      var journal = void 0;

      if (article.get('inPress')) {
        journal = article.get('journal');
      } else {
        // Note all this code that calls .get to navigate relationships
        // on the article assumes that these async relationships have all
        // already loaded.  As is this code will fail if execution ever gets
        // here without something already loading these relationships and then
        // waiting for their results to resolve successfully
        journal = article.get('issue').get('journal');
      }

      var options = {
        app_id: 1608060246153326,
        display: 'popup',
        caption: 'Article from BrowZine',
        description: article.get('strippedTitle') + ' ' + 'https://browzine.com/articles/' + article.id,
        href: 'https://browzine.com/articles/' + article.id,
        picture: journal.get('coverURL'),
        hashtag: '#BrowZine'
      };

      var url = "https://www.facebook.com/dialog/share?" + Ember.$.param(options);
      window.open(url, "_blank", "width=800,height=500");
    },
    linkedin: function linkedin(article) {
      //https://developer.linkedin.com/docs/share-on-linkedin
      var title = article.get('strippedTitle');
      var maxTitle = 200;

      if (title.length > maxTitle) {
        title = title.substring(0, maxTitle) + "...";
      }

      var shareUrl = 'https://browzine.com/articles/' + article.id;

      var options = {
        title: title,
        summary: title + ' ' + shareUrl,
        url: shareUrl,
        source: "https://browzine.com/",
        mini: true
      };

      var url = "https://www.linkedin.com/shareArticle?" + Ember.$.param(options);
      window.open(url, "_blank", "width=520,height=570");
    },
    email: function email(article) {
      var title = article.get('strippedTitle');
      var maxTitle = 200;

      if (title.length > maxTitle) {
        title = title.substring(0, maxTitle) + "...";
      }

      var issueTitle = '';
      if (!article.get('inPress')) {
        issueTitle = article.get('issue').get('title');
      }

      var pages = article.getWithDefault('startPage', '');
      if (pages.length > 0) {
        pages = 'pp. ' + pages;

        if (issueTitle.length > 0) {
          pages = ', ' + pages;
        }
      }

      if (!article.getWithDefault('hasNoEndPage', true)) {
        pages += '-' + article.get('endPage');
      }

      var journal = void 0;
      if (article.get('inPress')) {
        journal = article.get('journal');
      } else {
        journal = article.get('issue').get('journal');
      }

      var year = '';
      if (!article.get('inPress')) {
        year = article.get('issue').get('year');

        if (issueTitle.length > 0 || pages.length > 0) {
          year = ', ' + year;
        }
      }

      var options = {
        subject: title,
        body: article.get('strippedTitle') + '\n' + journal.get('title') + '\n' + issueTitle + pages + year + '\n\n' + this.get('intl').t('journal.article.email.link') + ': https://browzine.com/articles/' + article.id + '\n\n\n' + this.get('intl').t('journal.article.email.header') + '\n\n' + this.get('intl').t('journal.article.email.body') + '\n\n' + this.get('intl').t('journal.article.email.cta') + '\n'
      };

      window.location.href = "mailto:?subject=" + options.subject + "&body=" + encodeURIComponent(options.body);
    }
  });
});