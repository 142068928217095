define('browzine-web/models/article', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    syncId: _emberData.default.attr('string'),

    library: _emberData.default.belongsTo('library', { async: true }),

    issue: _emberData.default.belongsTo('issue', { async: true }),
    journal: _emberData.default.belongsTo('journal', { async: true, inverse: 'allArticles' }),

    title: _emberData.default.attr('string'),
    parsedTitle: function () {
      var title = this.get('title');
      title = title.replace(/\$\$[^$]+\$\$/g, ' '); // Remove anything surrounded by $$
      title = title.replace(/(<\/?(?!\/?[bi]>)[^<>]+>)/g, ' '); // Anything not <i> or <b> remove
      title = title.replace(/(<(\/[bi])>)/g, '$1 '); // Put a space after </i> or </b>
      return title;
    }.property('title'),

    strippedTitle: function () {
      var parsedTitle = this.get('parsedTitle');
      parsedTitle = parsedTitle.replace(/(<(\/[bi])>)/g, '');
      parsedTitle = parsedTitle.replace(/(<([bi])>)/g, '');
      return parsedTitle;
    }.property('title'),

    abstract: _emberData.default.attr('string'),
    authors: _emberData.default.attr('string'),
    authorsPreview: Ember.computed('authors', function () {
      var preview = [];
      var authors = this.get('authors').split(';');

      if (authors.length < 4) {
        preview = authors.join(';');
      } else {
        preview.push(authors[0]);
        preview.push(authors[1]);
        preview.push(authors[2]);
        preview.push(' … ' + authors[authors.length - 1].trim());
        preview = preview.join(';');
      }

      return preview;
    }),

    inPress: _emberData.default.attr('boolean'),

    startPage: _emberData.default.attr('string'),
    endPage: _emberData.default.attr('string'),

    doi: _emberData.default.attr('string'),

    contentLocation: _emberData.default.attr('string'),
    ILLURL: _emberData.default.attr('string'),
    browzineWebInContextLink: _emberData.default.attr('string'),
    browseable: Ember.computed.notEmpty('browzineWebInContextLink'),

    permalink: _emberData.default.attr('string'),
    fullTextLocation: _emberData.default.attr('string'),
    fullTextFile: _emberData.default.attr('string'),
    suppressed: _emberData.default.attr('boolean'),

    vpnRequired: _emberData.default.attr('boolean'),
    ipRangeError: _emberData.default.attr(),

    hasNoEndPage: Ember.computed.empty('endPage'),
    startPageEqualsEndPage: Ember.computed('startPage', 'endPage', function () {
      return this.get('startPage') === this.get('endPage');
    }),
    isSinglePage: Ember.computed.or('hasNoEndPage', 'startPageEqualsEndPage'),
    date: _emberData.default.attr('date'),
    year: Ember.computed('date', function () {
      return this.get('date').getFullYear();
    }),
    openAccess: _emberData.default.attr('boolean'),
    retractionNoticeUrl: _emberData.default.attr('string'),
    expressionOfConcernNoticeUrl: _emberData.default.attr('string')
  });
});