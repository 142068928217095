define('browzine-web/components/bz-modal/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    a11y: Ember.inject.service(),

    previousActiveElement: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var modal = this.get('modal');
      modal.hide = function () {
        _this.hide();
      };
      modal.show = function () {
        _this.show();
      };

      var handleKeyPress = function handleKeyPress(event) {
        //Esc Key
        if (event.keyCode === 27) {
          Ember.run.next(function () {
            _this.hide();
          });
        }
      };

      Ember.$(document).on('keyup', handleKeyPress);
      this.set('handleKeyPress', handleKeyPress);
    },
    didDestroyElement: function didDestroyElement() {
      Ember.$(document).unbind('keyup', this.get('handleKeyPress'));
    },
    show: function show() {
      var modal = this.get('modal');
      var a11y = this.get('a11y');

      a11y.set('previousActiveElement', document.activeElement);
      a11y.bzModalFocus(modal);

      Ember.$('body').addClass('no-scroll');
      this.$('.bz-modal-container').show();

      if (modal.onShow) {
        modal.onShow();
      }
    },
    hide: function hide() {
      var modal = this.get('modal');
      var a11y = this.get('a11y');

      a11y.bzModalBlur();

      Ember.$('body').removeClass('no-scroll');
      this.$('.bz-modal-container').hide();

      if (modal.onHide) {
        modal.onHide();
      }
    },


    actions: {
      hide: function hide() {
        this.hide();
      },
      show: function show() {
        this.show();
      },
      hold: function hold() {
        //Placeholder action to disable iOS moving the cursor and
        //closing BZ Modal using the backdrop element in responsive mode
        //See, The following articles for background:
        //https://bugs.webkit.org/show_bug.cgi?id=176896
        //https://hackernoon.com/how-to-fix-the-ios-11-input-elemaent-in-fixed-modals-bug-aaf66c7ba3f8
        //https://github.com/ftlabs/fastclick/issues/338
        //https://stackoverflow.com/questions/46339063/ios-11-safari-bootstrap-modal-text-area-outside-of-cursor
      }
    }
  });
});