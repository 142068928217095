define('browzine-web/services/unreads', ['exports', 'lodash.foreach', 'browzine-web/models/article-status-collection', 'npm:browzine_schema_hub/couchDB/article', 'moment'], function (exports, _lodash, _articleStatusCollection, _article, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Service.extend(Ember.Evented, {
    myBookshelf: Ember.inject.service(),
    errorReporter: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    jsonValidator: Ember.inject.service(),
    sync: Ember.inject.service(),
    intl: Ember.inject.service(),

    database: Ember.computed.alias('myBookshelf.database'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('articleStatusDocuments', {});
      this.set('issueStatuses', {});
      this.set('journalStatuses', {});
      this.set('articlesInPressStatuses', {});

      // This preloads the Notifier worker if notifications are enabled
      this.get('notifications');
    },
    getStatusCollectionByTypeAndId: function getStatusCollectionByTypeAndId(type, id, createIfNotExists) {
      var statusCollectionMap = this.get(type + 'Statuses');
      var statusCollection = statusCollectionMap[id];
      if (createIfNotExists && !statusCollection) {
        statusCollection = _articleStatusCollection.default.create({ type: type, id: id });
        statusCollectionMap[id] = statusCollection;
      }
      return statusCollection;
    },
    processUnreadCounts: function processUnreadCounts(res) {
      var _this = this;

      var rows = res.rows;

      var unreadCounts = { _total: 0 };
      rows.forEach(function (row) {
        var key = row.key,
            value = row.value;

        var journalId = key[0];
        var issueId = key[1];
        if (issueId === 'articles-in-press') {
          var articlesInPressStatus = _this.getStatusCollectionByTypeAndId('articlesInPress', journalId, true);
          articlesInPressStatus.set('cachedCount', value);
        } else {
          var issueStatus = _this.getStatusCollectionByTypeAndId('issue', issueId, true);
          issueStatus.set('cachedCount', value);
        }
        unreadCounts[journalId] = unreadCounts[journalId] || { _total: 0 };
        unreadCounts[journalId]._total += value;
        unreadCounts._total += value;
      });
      (0, _lodash.default)(unreadCounts, function (countsForJournal, journalId) {
        var journalStatus = _this.getStatusCollectionByTypeAndId('journal', journalId, true);
        journalStatus.set('cachedCount', countsForJournal._total);
      });
    },
    updateCounts: function updateCounts(_ref) {
      var issueId = _ref.issueId,
          journalId = _ref.journalId;

      var num = -1;
      var articlesInPressStatuses = this.get('articlesInPressStatuses');
      var journalStatuses = this.get('journalStatuses');
      var issueStatuses = this.get('issueStatuses');
      var issueStatus = issueStatuses[issueId];
      var journalStatus = journalStatuses[journalId];
      if (issueId === 'articles-in-press') {
        var count = articlesInPressStatuses[journalId].cachedCount;
        articlesInPressStatuses[journalId].set('cachedCount', count + num);
      } else if (issueStatus) {
        var issueCount = issueStatuses[issueId].cachedCount;
        issueStatuses[issueId].set('cachedCount', issueCount + num);
      }
      if (journalStatus) {
        var journalCount = journalStatuses[journalId].cachedCount;
        journalStatuses[journalId].set('cachedCount', journalCount + num);
      }
    },
    syncUnreadDocument: function syncUnreadDocument(doc) {
      if (this.get('isDestroyed')) {
        return;
      }

      var sync = this.get('sync');
      var journalStatusCollections = this.get('journalStatuses');
      var articleStatusDocuments = this.get('articleStatusDocuments');
      var syncId = this._syncIdFromDocumentId(doc._id);
      var articleStatus = articleStatusDocuments[syncId];

      // Check if we already have this revision of the doc
      if (articleStatus && articleStatus.get('rev') === doc._rev) {
        return;
      }

      var journalId = doc.journal;
      if (journalStatusCollections[journalId]) {
        this.handleArticleStatusDocument(doc);
      }
      sync.getUnreadCounts();
      // Trigger an event indicating the sync of the doc is done
      // so tests can wait for a certain number of docs to sync
      this.trigger('unreadSynced');
    },
    getArticleStatusBySyncId: function getArticleStatusBySyncId(syncId, initialLoad) {
      var articleStatusDocuments = this.get('articleStatusDocuments');
      var articleStatus = articleStatusDocuments[syncId];

      if (!articleStatus) {
        articleStatus = Ember.getOwner(this).resolveRegistration('model:article-status').create({ unreads: this, syncId: syncId, isNewSinceInitialLoad: !initialLoad });
        articleStatusDocuments[syncId] = articleStatus;
      }

      return articleStatus;
    },
    handleArticleStatusDocument: function handleArticleStatusDocument(doc, initialLoad) {
      // Trigger an event so that tests can detect whether the app has noticed
      // new unread documents added to the database
      this.trigger('handlingStatusDoc');
      var syncId = this._syncIdFromDocumentId(doc._id);

      if (syncId) {
        var validation = this.get('jsonValidator').validate(doc, _article.default);

        // FIXME why validate if deleted???
        if (doc._deleted || validation.isValid) {
          this._setArticleStatus(syncId, doc, initialLoad);
        } else {
          this.get('errorReporter').reportArticleStatusReadValidationError(doc, validation.errors);
        }
      }
    },
    markArticleReadBySyncId: function markArticleReadBySyncId(syncId, viewed) {
      var statusDocument = this.getArticleStatusBySyncId(syncId);
      var changes = { markedRead: true };
      var canBeMarkedUnread = statusDocument.get('canBeMarkedUnread');

      if (canBeMarkedUnread) {
        if (viewed) {
          changes.viewedAt = (0, _moment.default)(new Date()).format("YYYY-MM-DDTHH:mm:ssZ");
          changes.viewCount = (statusDocument.get('viewCount') || 0) + 1;
        }

        this._updateArticleStatuses([syncId], changes);
      }
    },
    filterUnreadArticleStatusSyncIds: function filterUnreadArticleStatusSyncIds(fn) {
      var articleStatusDocuments = this.get('articleStatusDocuments');
      var syncIds = [];
      (0, _lodash.default)(articleStatusDocuments, function (status) {
        if (status.get('isUnread') && fn(status)) {
          syncIds.push(status.get('syncId'));
        }
      });
      return syncIds;
    },
    filterUnreadArticleStatusSyncIdsByIssue: function filterUnreadArticleStatusSyncIdsByIssue(issueId) {
      return this.filterUnreadArticleStatusSyncIds(function (status) {
        return status.get('issueId') === issueId;
      });
    },
    filterUnreadArticleStatusSyncIdsByJournal: function filterUnreadArticleStatusSyncIdsByJournal(journalId) {
      return this.filterUnreadArticleStatusSyncIds(function (status) {
        return status.get('journalId') === journalId;
      });
    },
    markIssueReadById: function markIssueReadById(issueId) {
      var issueStatus = this.getIssueStatusById(issueId);

      if (issueStatus) {
        var _issueId = issueStatus.get('id');
        var syncIds = this.filterUnreadArticleStatusSyncIdsByIssue(_issueId);
        this._updateArticleStatuses(syncIds, { markedRead: true });
      }
    },
    markArticlesInPressReadByJournalId: function markArticlesInPressReadByJournalId(journalId) {
      var articlesInPressStatus = this.getArticlesInPressStatusById(journalId);

      if (articlesInPressStatus) {
        var _journalId = articlesInPressStatus.get('id');
        var syncIds = this.filterUnreadArticleStatusSyncIds(function (status) {
          return status.get('journalId') === _journalId && status.get('issueId') === 'articles-in-press';
        });
        this._updateArticleStatuses(syncIds, { markedRead: true });
      }
    },
    markIssuesReadByJournalId: function markIssuesReadByJournalId(journalId) {
      var _this2 = this;

      return this.get('sync').getUnreadsForJournal(journalId).then(function () {
        var journalStatus = _this2.getJournalStatusById(journalId);

        if (journalStatus) {
          var _journalId2 = journalStatus.get('id');
          var syncIds = _this2.filterUnreadArticleStatusSyncIdsByJournal(_journalId2);
          _this2._updateArticleStatuses(syncIds, { markedRead: true }).then(function () {});
        }
      });
    },
    markAllJournalsRead: function markAllJournalsRead() {
      var _this3 = this;

      var sync = this.get('sync');
      return sync.fetchAllUnreads().then(function () {
        var journalIds = _this3.get('myBookshelf.bookshelves.journalIds');
        var syncIds = journalIds.reduce(function (syncIds, journalId) {
          var journalStatus = _this3.getJournalStatusById(journalId);

          if (journalStatus) {
            var _journalId3 = journalStatus.get('id');
            var ids = _this3.filterUnreadArticleStatusSyncIdsByJournal(_journalId3);
            syncIds.push.apply(syncIds, _toConsumableArray(ids));
            return syncIds;
          } else {
            return syncIds;
          }
        }, []);

        return _this3._updateArticleStatuses(syncIds, { markedRead: true });
      });
    },
    markArticleUnreadBySyncId: function markArticleUnreadBySyncId(syncId) {
      this._updateArticleStatuses([syncId], {
        markedRead: undefined,
        viewCount: undefined,
        viewedAt: undefined
      });
    },
    getIssueStatusById: function getIssueStatusById(issueId) {
      return this.getStatusCollectionByTypeAndId('issue', issueId, true);
    },
    getArticlesInPressStatusById: function getArticlesInPressStatusById(journalId) {
      return this.getStatusCollectionByTypeAndId('articlesInPress', journalId, true);
    },
    getJournalStatusById: function getJournalStatusById(journalId) {
      return this.getStatusCollectionByTypeAndId('journal', journalId, true);
    },
    _documentIsArticleInPress: function _documentIsArticleInPress(doc) {
      return doc.issue.indexOf('articles-in-press') > -1;
    },
    _syncIdFromDocumentId: function _syncIdFromDocumentId(documentId) {
      var match = documentId.match(/^article-(\d*)$/);

      if (match) {
        return match[1];
      }
    },
    _setArticleStatus: function _setArticleStatus(syncId, rawDocument, initialLoad) {
      var issue = rawDocument.issue,
          journal = rawDocument.journal;

      var articleStatus = this.getArticleStatusBySyncId(syncId, initialLoad);

      if (articleStatus.get('issueId') !== issue) {
        this._addArticleStatusToIssue(issue, articleStatus);

        if (articleStatus.get('issueId')) {
          this._removeArticleStatusFromIssue(articleStatus.get('issueId'), articleStatus);
        }
      }

      if (articleStatus.get('journalId') !== journal) {
        this._addArticleStatusToJournal(journal, articleStatus);

        if (articleStatus.get('journalId')) {
          this._removeArticleStatusFromJournal(articleStatus.get('journalId'), articleStatus);
        }
      }

      if (this._documentIsArticleInPress(rawDocument)) {
        this._addArticleStatusToArticlesInPress(journal, articleStatus);

        if (articleStatus.get('markedRead')) {
          this._removeArticleStatusFromArticlesInPress(articleStatus.get('journalId'), articleStatus);
        }
      }

      // This run call is needed for tests to pass
      Ember.run(function () {
        articleStatus.set('syncData', rawDocument);
      });

      if (articleStatus.get('isNewSinceInitialLoad')) {
        this.get('notifications').add(articleStatus);
      }

      return articleStatus;
    },
    _updateArticleStatuses: function _updateArticleStatuses(syncIds, properties) {
      var _this4 = this;

      var db = this.get('database');

      var articleStatuses = syncIds.map(function (syncId) {
        return _this4.getArticleStatusBySyncId(syncId);
      }).filter(function (status) {
        var validation = _this4.get('jsonValidator').validate(status.get('syncData'), _article.default);

        if (validation.isValid) {
          return true;
        } else {
          _this4.get('flashMessages').alert(_this4.get('intl').t('unreads.error_saving_error_message'));
          validation.syncId = status.syncId;
          validation.status = JSON.stringify(status.get('syncData'));
          console.log('ArticleStatus !validation.isValid (object)', status);
          console.log('ArticleStatus syncData', status.get('syncData'));
          _this4.get('errorReporter').reportArticleStatusValidationError(status.get('syncData'), validation);
        }
      });

      articleStatuses.forEach(function (status) {
        status.set('previousDocument', status.get('syncData'));
        status.setProperties(properties);
        status.set('isSyncing', true);
      });

      return db.bulkDocs(articleStatuses.mapBy('syncData')).then(function (results) {
        Ember.run(function () {
          results.forEach(function (result, index) {
            var articleStatus = articleStatuses[index];
            articleStatus.set('isSyncing', false);

            if (result.error) {
              var previousDocument = articleStatus.get('previousDocument');
              var failedDocument = articleStatus.get('syncData');

              console.log('Error saving article status document: ' + result + '.');
              console.log('Previous document:', previousDocument);
              console.log('Failed document:', failedDocument);

              _this4.get('flashMessages').alert(_this4.get('intl').t('unreads.error_saving_error_message'));
              _this4.get('errorReporter').reportArticleStatusUpdateError(result.error, previousDocument, failedDocument);

              articleStatus.set('syncData', previousDocument, true);
            } else {
              articleStatus.set('rev', result.rev);
            }
          });
        });
      });
    },
    _addArticleStatusToArticlesInPress: function _addArticleStatusToArticlesInPress(journalId, statusDocument) {
      var articlesInPressStatus = this.getArticlesInPressStatusById(journalId);
      articlesInPressStatus.addArticleStatus(statusDocument);
    },
    _removeArticleStatusFromArticlesInPress: function _removeArticleStatusFromArticlesInPress(journalId, statusDocument) {
      var articlesInPressStatus = this.getArticlesInPressStatusById(journalId);
      articlesInPressStatus.removeArticleStatus(statusDocument);
    },
    _addArticleStatusToIssue: function _addArticleStatusToIssue(issueId, statusDocument) {
      var issueStatus = this.getIssueStatusById(issueId);
      issueStatus.addArticleStatus(statusDocument);
    },
    _removeArticleStatusFromIssue: function _removeArticleStatusFromIssue(issueId, statusDocument) {
      var issueStatus = this.getIssueStatusById(issueId);
      issueStatus.removeArticleStatus(statusDocument);
    },
    _addArticleStatusToJournal: function _addArticleStatusToJournal(journalId, statusDocument) {
      var journalStatus = this.getJournalStatusById(journalId);
      journalStatus.addArticleStatus(statusDocument);
    },
    _removeArticleStatusFromJournal: function _removeArticleStatusFromJournal(journalId, statusDocument) {
      var journalStatus = this.getJournalStatusById(journalId);
      journalStatus.removeArticleStatus(statusDocument);
    },
    _deleteAll: function _deleteAll() {
      var db = this.get('database');

      db.allDocs().then(function (response) {
        response.rows.forEach(function (doc) {
          if (doc.id.indexOf('article') > -1) {
            db.remove(doc.id, doc.value.rev);
          }
        });
      });
    },
    _createForLoadedArticles: function _createForLoadedArticles() {
      var db = this.get('database');

      this.get('store').peekAll('article').forEach(function (article) {
        article.fixedBelongsToLoad('issue').then(function (issue) {
          issue.fixedBelongsToLoad('journal').then(function (journal) {
            db.put({
              _id: 'article-' + article.get('syncId'),
              type: 'article',
              journal: journal.get('id'),
              issue: issue.get('id'),
              metadataUpdated: (0, _moment.default)(new Date(2006, 4, 26)).format("YYYY-MM-DDTHH:mm:ssZ")
            });
          });
        });
      });
    }
  });
});