define('browzine-web/routes/my-bookshelf', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),
    myBookshelf: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    sync: Ember.inject.service(),
    header: Ember.inject.service(),
    intl: Ember.inject.service(),

    model: function model() {
      var _this = this;

      if (this.get('applicationSession').get('loggedInUser')) {
        return this.get('sync').ensureDataLoaded().then(function () {
          return _this.get('myBookshelf.model');
        }).catch(function () {
          var myBookshelf = _this.get('myBookshelf');
          return Promise.resolve(myBookshelf.adaptModel(myBookshelf.get('emptyBookcases')));
        });
      }
    },
    afterModel: function afterModel() {
      var syncMessageDismissed = JSON.parse(localStorage.getItem('syncMessageDismissed'));

      if (!syncMessageDismissed && this.get('myBookshelf.isAvailable')) {
        this.get('flashMessages').add({
          componentName: 'my-bookshelf-intro-flash-message',
          type: 'side',
          sticky: true
        });
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('library', this.modelFor('library'));
      controller.set('model', model);
    },


    onLoggedInUserChange: Ember.observer('sync.database', function (sender, key, value, rev) {
      var _this2 = this;

      // We need to refresh our model if the logged in user changes.  A change in user
      // should change the database property of the sync service.  If the design changes
      // such that that is no longer the case, update this so its dependent on some set of
      // ovservables that indicate the user has changed so we can properly refresh our model
      // to dump portions that pertained to the old user and pick up data pertinent to the
      // new user.

      // Refresh only if the value changed
      if (this.get(key) != rev) {
        Ember.run.once(this, function () {
          _this2.refresh();
        });
      }
    }),

    titleToken: function titleToken() {
      this.get('header').setProperties({
        subject: {
          value: 'BrowZine',
          template: false
        },
        title: {
          value: this.get('intl').t('my_bookshelf.feature_name'),
          template: false
        }
      });

      return this.get('intl').t('my_bookshelf.feature_name');
    },


    actions: {
      willTransition: function willTransition() {
        this.get('flashMessages').clearMessages();
      }
    }
  });
});