define('browzine-web/mixins/accordion-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    expandedItem: null,

    actions: {
      itemExpanded: function itemExpanded(item) {
        this.set('expandedItem', item);
      }
    }
  });
});