define('browzine-web/routes/auth/reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      this.set('token', transition.queryParams.token);
      this.set('email', transition.queryParams.email);
    },
    model: function model() {
      return {
        token: this.get('token'),
        email: this.get('email')
      };
    },


    actions: {
      signUpUnknownUser: function signUpUnknownUser(email) {
        this.transitionTo('auth.signup', { queryParams: { addPasswordFor: email } });
      }
    }
  });
});