define('browzine-web/components/confirm-modal/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    classNames: ['confirm-modal'],

    myArticles: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var modal = this.get('modal');

      Ember.run.next(function () {
        modal.element = _this.element;
        modal.element.id = _this.element.id;
      });

      modal.ok = function () {
        _this.ok();
      };

      modal.cancel = function () {
        _this.cancel();
      };
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      var modal = this.get('modal');

      modal.onShow = function () {
        Ember.run.next(function () {
          _this2.$('.bz-modal-content').focus();
        });
      };
    },


    actions: {
      ok: function ok() {
        var modal = this.get('modal');
        var successAction = this.get('successAction');

        modal[successAction]();
        modal.hide();
      },
      cancel: function cancel() {
        var modal = this.get('modal');
        modal.hide();
      }
    }
  });
});