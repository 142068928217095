define('browzine-web/routes/auth/bps', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),

    libraryId: null,

    beforeModel: function beforeModel(transition) {
      this.set('libraryId', transition.queryParams['library-id']);
    },
    model: function model() {
      return {
        check: this.modelFor('auth').checkingBps,
        showContinueButton: !this.modelFor('auth').confirmingBpsEmail,
        libraryId: this.get('libraryId')
      };
    }
  });
});