define('browzine-web/breakpoints', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    mobile: '(max-width: 685px)',
    tablet: '(min-width: 686px) and (max-width: 1024px)',
    desktop: '(min-width: 1025px) and (max-width: 1440px)',
    jumbo: '(min-width: 1441px)'
  };
});