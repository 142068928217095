define('browzine-web/mixins/client-adapter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ajaxOptions: function ajaxOptions() {

      var originalUrl = arguments[0];
      try {
        var url = new URL(originalUrl);
        if (url.searchParams) {
          url.searchParams.set('client', 'bzweb');
        } else if (url.search.length > 1) {
          // IE 11 support: if the search value is more than just "?", let's just add &client=bzweb  at the end
          url.search = url.search + '&client=bzweb';
        } else {
          // IE 11 support
          url.search = '?client=bzweb';
        }
        arguments[0] = url.toString();
      } catch (err) {
        // cannot parse the URL - probably a test like in pretender
        if (originalUrl.indexOf('/') === 0) {
          //an absolute prefix, this happens when we test with pretender.
          var urlString = originalUrl;
          if (originalUrl.indexOf('?') >= 0) {
            urlString += '&client=bzweb';
          } else {
            urlString += '?client=bzweb';
          }
          arguments[0] = urlString;
        }
      }

      var options = this._super.apply(this, arguments);
      return options;
    }
  });
});