define('browzine-web/helpers/grouped-library-list-entries', ['exports', 'diacritics'], function (exports, _diacritics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.groupedLibraryListEntries = groupedLibraryListEntries;
  function groupedLibraryListEntries(params /*, hash*/) {
    var listEntries = params[0];

    var alpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map(function (letter) {
      return Ember.Object.create({
        name: letter,
        listEntries: listEntries.filter(function (entry) {
          return _diacritics.default.remove(entry.entryName[0]).toUpperCase() === letter;
        })
      });
    });

    var numeric = Ember.Object.create({
      name: '0-9',
      listEntries: listEntries.filter(function (entry) {
        return isFinite(_diacritics.default.remove(entry.entryName[0])) === true;
      })
    });

    alpha.unshiftObject(numeric);

    return alpha;
  }

  exports.default = Ember.Helper.helper(groupedLibraryListEntries);
});