define('browzine-web/routes/library/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var libraryId = transition.params.library.library_id;
      this.transitionTo('library.subjects', libraryId);
    }
  });
});