define('browzine-web/services/sync', ['exports', 'browzine-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var MAX_JOURNAL_REQUEST = 100;

  exports.default = Ember.Service.extend({
    lastUnreadUpdate: Date.now(),

    store: Ember.inject.service(),
    applicationSession: Ember.inject.service(),

    myBookshelf: Ember.inject.service(),
    myArticles: Ember.inject.service(),
    unreads: Ember.inject.service(),

    initialCollectionLoadComplete: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // The computed property will not be set until it is requested.
      // This triggers the initial fetch of data, populates records
      // for the collections in the store
      // and registers the app to listen to changes to keep the store up
      // to date
      this.get('database');

      this._logPouchDBRequests();

      if (Ember.testing) {
        this._loadingQueue = 0;
        Ember.Test.registerWaiter(function () {
          return _this._loadingQueue <= 0;
        });
      }
    },
    ensureDataLoaded: function ensureDataLoaded() {
      var _this2 = this;

      // TODO: Design decision - should this be safely callable when
      // there is no logged in user?  Need consensus on how this
      // function should behave in that scenario
      if (this.get('isLoaded')) {
        // If we're already loaded there's nothing to do, dependent code
        // can just proceed
        return Ember.RSVP.resolve();
      } else if (this.get('isLoading')) {
        // If we're not loaded but a load has started, return the promise
        // that resolves when load is done
        return this.get('dataLoadingPromise');
      } else {
        // Otherwise we need to kick off a load
        // and subscribe to changes for the loaded data
        var loadPromise = new Ember.RSVP.Promise(function (resolve, reject) {

          var loadDidComplete = function loadDidComplete(loadedData) {
            // Handle setting coordination flags
            // and resolve our load promise.
            this.set('isLoaded', true);
            this.set('isLoading', false);
            return resolve(loadedData);
          }.bind(_this2);

          var db = _this2.get('database');

          // The database has changed.  We should dump whatever values
          // from couch we may currently have stuck in the Ember Store,
          // load whatever data the new couch database may have,
          // and subscribe to the changes feed so that the Ember store
          // records are kept up to date.

          var store = _this2.get('store');
          store.unloadAll('collection');
          store.unloadAll('collectionItem');

          // If there's no database, (like when no-one is logged in)
          // there's no data to load
          if (!db) {
            // We can't load my-bookshelf and we can't load collections
            // so reject those promises here
            //
            // NOTE: There _has_ to be a better way of managing this than
            // what we're doing here with these promises held on properties.
            //
            // First call getter to set up resolve/reject properties
            _this2.get('myBookshelfDataPromise');
            // Then reject so downstream code understands no bookshelf is coming
            _this2.get('myBookshelfDataReject')(new Error('Can\'t load bookshelf because database property is somehow empty'));
            _this2.get('collectionDataPromise');
            _this2.get('collectionDataReject')(new Error('Can\'t load article collection because database property is somehow empty'));

            return loadDidComplete();
          }

          // Since we're loading new data, we need to set up our promises
          // that will resolve when the data loads, replacing promises
          // from a prior load

          return _this2._populateTestData(db).then(function () {
            return _this2._fetchMinimumData(db);
          }).then(function (result) {
            // Data is done loading, set our flags and resolve the promise
            return loadDidComplete(result);
          }).catch(function (err) {
            // Data is never going to load properly, so just set the flag
            // and reject our promise to trigger appropriate error handling
            if (!(_this2.get('isDestroyed') || _this2.get('isDestroying'))) {
              _this2.set('isLoading', false);
            }
            return reject(err);
          });
        });

        this.set('dataLoadingPromise', loadPromise);
        this.set('isLoading', true);

        return loadPromise.then(function (result) {
          _this2.subscribeToChanges();
          return result;
        });
      }
    },
    subscribeToChanges: function subscribeToChanges() {
      var db = this.get('database');
      if (db) {
        return this._subscribeToChanges(db);
      }
    },


    myBookshelfDataPromise: Ember.computed('database', function () {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!(_this3.get('isDestroyed') || _this3.get('isDestroying'))) {
          _this3.set('myBookshelfDataResolve', resolve);
          _this3.set('myBookshelfDataReject', reject);
        }
      });
    }),

    collectionDataPromise: Ember.computed('database', function () {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!(_this4.get('isDestroyed') || _this4.get('isDestroying'))) {
          _this4.set('collectionDataResolve', resolve);
          _this4.set('collectionDataReject', reject);
        }
      });
    }),

    getMyBookshelfData: function getMyBookshelfData() {
      return this.get('myBookshelfDataPromise');
    },
    getCollectionData: function getCollectionData() {
      return this.get('collectionDataPromise');
    },


    database: Ember.computed('applicationSession.loggedInUser', function () {
      var loggedInUser = this.get('applicationSession').get('loggedInUser');

      if (!loggedInUser) {
        return undefined;
      }

      var couchdbDatabaseLocation = loggedInUser.couchdbDatabaseLocation || _environment.default.couchDatabase;

      if (Ember.isEmpty(couchdbDatabaseLocation)) {
        return undefined;
      }

      var db = new PouchDB(couchdbDatabaseLocation, _environment.default.couchOptions);

      return db;
    }),

    databaseDidChange: Ember.observer('database', function () {
      var _this5 = this;

      // The database property has changed, schedule some code to
      // clear the loaded status for the service, shut down
      // the old changes feed
      // Routes whose models depend upon values in the user database
      // indicated by the database property should register their
      // own observer to schedule a model refresh and trigger the load
      // of data from the new database

      Ember.run.once(this, function () {
        _this5.set('isLoaded', false);
        _this5.set('isLoading', false);

        var feedCanceller = _this5.get('changesFeedCanceller');

        if (feedCanceller) {
          feedCanceller.cancel();
        }
      });
    }),

    _TEST_HELPER_refreshStore: function _TEST_HELPER_refreshStore() {
      this.notifyPropertyChange('database');
    },
    _populateTestData: function _populateTestData(db) {
      var _this6 = this;

      if (this._fixture) {
        if (!this._fixture.length) {
          this._fixture = [this._fixture];
        }

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.schedule('afterRender', _this6, function () {
            db.bulkDocs(_this6._fixture).then(function () {
              return resolve();
            }).catch(function (err) {
              return reject(err);
            });
          });
        });
      } else {
        return Ember.RSVP.Promise.resolve(true);
      }
    },
    getUnreadCounts: function getUnreadCounts() {
      var _this7 = this;

      if (this.get('isDestroyed') || !this.get('applicationSession').get('userIsLoggedIn')) {
        return Ember.RSVP.resolve([]);
      }

      if (this.get('syncingUnreadCounts')) {
        this.set('syncingUnreadCountsInvalid', true);
        return Ember.RSVP.resolve([]);
      }

      this.set('syncingUnreadCounts', true);
      this.set('syncingUnreadCountsInvalid', false);

      if (Ember.testing) {
        this._loadingQueue++;
      }

      var db = this.get('database');
      var unreads = this.get('unreads');

      return db.query('myBookshelf/unread-count', { group: true, group_level: 2 }).then(function (res) {
        if (Ember.testing) {
          _this7._loadingQueue--;
        }

        _this7.set('syncingUnreadCounts', false);
        // getUnreadCounts was called before this query returned and therefore the results are invalid
        // call getUnreadCounts again to get the latest data

        if (_this7.get('syncingUnreadCountsInvalid')) {
          _this7.getUnreadCounts();
          return;
        }

        // Run loop for testing: remove if able
        // https://guides.emberjs.com/v2.11.0/applications/run-loop/#toc_how-is-run-loop-behaviour-different-when-testing
        Ember.run(function () {
          unreads.processUnreadCounts(res);
        });
      });
    },
    _fetchMinimumData: function _fetchMinimumData(db) {
      var _this8 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.run.schedule('afterRender', _this8, function () {
          _this8.getUnreadCounts().then(function () {
            return db.query('myBookshelf/bookshelf');
          }).then(function (bookshelfResult) {
            var bookshelfDoc = (bookshelfResult.rows[0] || {}).value;

            if (bookshelfDoc) {
              return Ember.RSVP.all([_this8.fetchBookshelfJournals(bookshelfDoc), db.query('myArticles/collections')]).then(function (_ref) {
                var _ref2 = _slicedToArray(_ref, 2),
                    collectionView = _ref2[1];

                _this8.get('myBookshelfDataPromise');
                _this8.get('myBookshelfDataResolve')(bookshelfDoc);
                return collectionView;
              });
            }

            _this8.get('myBookshelfDataPromise');
            _this8.get('myBookshelfDataReject')();
            return db.query('myArticles/collections');
          }).then(function (collectionView) {
            var collections = collectionView.rows.map(function (x) {
              return x.value;
            });

            _this8.get('myArticles').populateCollectionData(collections).then(function () {
              if (!(_this8.get('isDestroyed') || _this8.get('isDestroying'))) {
                _this8.set('initialCollectionLoadComplete', true);
                _this8.get('collectionDataPromise');
                _this8.get('collectionDataResolve')(collections);
              }
              return resolve();
            });
          }).catch(function (err) {
            return reject(err);
          });
        });
      });
    },
    fetchBookshelfJournals: function fetchBookshelfJournals(bookshelfDoc) {
      var store = this.get('store');
      var journalIds = [];

      bookshelfDoc.bookcases.forEach(function (bookcase) {
        bookcase.bookshelves.forEach(function (bookshelf) {
          bookshelf.items.forEach(function (item) {
            if (item.journal) {
              journalIds.push(item.journal);
            }
          });
        });
      });

      if (journalIds.length) {
        var start = 0;
        var fetchQueue = [];

        while (start <= journalIds.length) {
          var queryIds = journalIds.slice(start, start + MAX_JOURNAL_REQUEST);

          if (queryIds.length > 0) {
            var promise = store.query('journal', {
              filter: {
                id: queryIds.toString()
              }
            });

            fetchQueue.push(promise);
          }

          start = start + MAX_JOURNAL_REQUEST;
        }

        return Ember.RSVP.all(fetchQueue);
      }

      return Ember.RSVP.resolve(true);
    },
    fetchAllUnreads: function fetchAllUnreads() {
      var _this9 = this;

      var db = this.get('database');

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run.schedule('actions', _this9, function () {
          db.allDocs({ include_docs: true }).then(function (allDocs) {
            Ember.run(function () {
              Ember.run.schedule('actions', _this9, function () {
                allDocs.rows.forEach(function (_ref3) {
                  var doc = _ref3.doc;

                  if (_this9._documentIsArticleStatus(doc)) {
                    _this9.get('unreads').handleArticleStatusDocument(doc, true);
                  }
                });
                resolve(true);
              });
            });
          });
        });
      });
    },
    getUnreadsForJournal: function getUnreadsForJournal(journalId) {
      var _this10 = this;

      var db = this.get('database');

      if (!db) {
        return Ember.RSVP.resolve([]);
      }

      if (Ember.testing) {
        this._loadingQueue++;
      }

      return new Ember.RSVP.Promise(function (resolve) {

        Ember.run(function () {
          Ember.run.schedule('afterRender', _this10, function () {
            var query = {
              key: journalId
            };

            db.query('myBookshelf/unreads-by-journal', query).then(function (res) {
              var rows = res.rows;
              // Run loop for testing
              // https://guides.emberjs.com/v2.11.0/applications/run-loop/#toc_how-is-run-loop-behaviour-different-when-testing

              Ember.run(function () {
                rows.forEach(function (row) {
                  _this10.get('unreads').handleArticleStatusDocument(row.value, true);
                });

                _this10.set('initialLoadComplete', true);
              });

              if (Ember.testing) {
                _this10._loadingQueue--;
              }

              resolve(rows);
            }).catch(function (err) {
              console.log("getUnreadsForJournal", { err: err });
              _this10.set('initialLoadComplete', true);
              resolve();
            });
          });
        });
      });
    },


    _reconnects: 0,

    _subscribeToChanges: function _subscribeToChanges(db) {
      var _this11 = this;

      var latestChange = this.get('latestChangeReceived');
      var since = latestChange ? latestChange : 'now';

      var cancelObject = db.changes({
        live: true,
        include_docs: true,
        since: since
      }).on('change', function (change) {
        if (!Ember.testing) {
          _this11.set('latestChangeReceived', change.seq);
        }

        Ember.run(function () {
          if (!_this11.get('isDestroyed')) {
            _this11.set('_reconnects', 0);
          }
          _this11._processChange(change);
        });
      }).on('error', function (e) {
        Ember.run(function () {
          console.log('Error in changes feed:', e);

          var BACKOFF_INTERVAL = 1000;
          var MAX = 1000 * 60 * 10;
          var NEXT_ATTEMPT = Math.min(Math.pow(2, _this11.get('_reconnects')) * BACKOFF_INTERVAL, MAX);
          console.log('Retrying subscription to changes in ' + NEXT_ATTEMPT + '\u2026');

          Ember.run.later(function () {
            if (!_this11.get('isDestroyed')) {
              _this11.set('_reconnects', _this11.get('_reconnects') + 1);
              _this11._subscribeToChanges(db);
            }
          }, NEXT_ATTEMPT);
        });
      });

      // Set this so tests can wait for it before proceeding
      this.set('listeningForChanges', true);

      if (!Ember.testing) {
        Ember.run.later(function () {
          cancelObject.cancel();
          Ember.run.later(function () {
            _this11._subscribeToChanges(db);
          }, 10000);
        }, 10000);
      }

      this.set('changesFeedCanceller', cancelObject);
    },
    _processChange: function _processChange(change) {
      var doc = change.doc;

      if (this._documentIsMyBookshelf(doc)) {
        if (!this.get('isDestroyed')) {
          this.get('myBookshelf').adaptModel(doc);
        }
      } else if (this._documentIsArticleStatus(doc)) {
        this.get('unreads').syncUnreadDocument(doc);
      } else if (this._documentIsCollection(doc)) {
        this.get('myArticles').processCollectionChange(doc);
      }
    },
    _documentIsMyBookshelf: function _documentIsMyBookshelf(document) {
      return document._id === 'my_bookshelf';
    },
    _documentIsCollection: function _documentIsCollection() {
      var doc = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return doc._id.startsWith('collection');
    },
    _documentIsArticleStatus: function _documentIsArticleStatus() {
      var doc = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return doc._id.startsWith('article');
    },
    _logPouchDBRequests: function _logPouchDBRequests() {
      // PouchDB debug output looks like this:
      // console.log("%cpouchdb:http %cGET http://localhost:5984/bookbook/%c +31ms", "color: lightseagreen", "color: inherit", "color: lightseagreen")

      var syncService = this;
      syncService.set('loggedRequests', []);

      PouchDB.debug.log = function (colouredString) {
        var _colouredString$split = colouredString.split("%c"),
            _colouredString$split2 = _slicedToArray(_colouredString$split, 3),
            category = _colouredString$split2[1],
            request = _colouredString$split2[2];

        if (category.trim() === 'pouchdb:http') {
          console.log("want to store this request");
          console.log(request);
          syncService.get('loggedRequests').push(request);
        }
      };

      PouchDB.debug.log.bind(PouchDB.debug);
    },
    getLoggedRequests: function getLoggedRequests() {
      return this.get('loggedRequests');
    },
    clearLoggedRequests: function clearLoggedRequests() {
      this.set('loggedRequests', []);
    }
  });
});