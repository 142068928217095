define('browzine-web/components/journal-content-navigation/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    a11y: Ember.inject.service(),
    intl: Ember.inject.service(),

    init: function init() {
      this.set('year', this.get('issueNavigationState.selectedYear'));
      this.set('currentIssueId', this.get('currentlyDisplayedContent.id'));
      this._super.apply(this, arguments);
    },

    year: undefined, // Gets initialized to issueNavigationState.selectedYear
    currentIssueId: null,

    // When model changes, we want to take the year from it
    // over whatever the user may have clicked on
    modelYearObserver: Ember.observer('issueNavigationState.selectedYear', function () {
      this.set('year', this.get('issueNavigationState.selectedYear'));
    }),

    issueYearObserver: Ember.observer('currentlyDisplayedContent.year', function () {
      var currentIssueId = this.get('currentIssueId');
      var issueId = this.get('currentlyDisplayedContent.id');
      if (currentIssueId !== issueId) {
        this.set('currentIssueId', issueId);
      } else {
        this.set('year', this.get('currentlyDisplayedContent.year'));
      }
    }),

    issues: Ember.computed('journal.issues.[]', 'year', function () {
      var year = this.get('year');
      var allIssues = this.get('journal.issues') || [];

      return allIssues.filterBy('year', year).filterBy('title');
    }),

    articlesInPressSelected: Ember.computed('currentlyDisplayedContent', function () {
      return !!this.get('currentlyDisplayedContent.articlesInPress');
    }),

    selectedIssueId: Ember.computed('currentlyDisplayedContent', function () {
      // When articles-in-press is the model there'll be an
      // articlesInPress boolean
      if (this.get('currentlyDisplayedContent.articlesInPress')) {
        var VALUE_THAT_MATCHES_NO_ISSUE_ID = 0;
        return VALUE_THAT_MATCHES_NO_ISSUE_ID;
      } else {
        // Otherwise we should have an issue model
        return this.get('currentlyDisplayedContent.id');
      }
    }),

    articlesInPressTitle: Ember.computed('intl.primaryLocale', function () {
      return this.get('intl').t('journal.articles_in_press.link');
    }),

    actions: {
      setYear: function setYear(year) {
        var a11y = this.get('a11y');
        this.set('year', year);

        // set year first, then get current issues for year
        if (!this.get('media.isMobile') && !this.get('media.isTablet')) {
          var issues = this.get('issues');
          var issue = issues.get('firstObject');
          this.get('router').transitionTo('library.journal.issue', issue);
          a11y.issueNavFocus();
        }
      },
      enableFocus: function enableFocus() {
        this.set('focusDisabled', false);
      },
      disableFocus: function disableFocus() {
        this.set('focusDisabled', true);
      }
    }
  });
});