define('browzine-web/components/content-preview-box/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isArticlePreview:article-preview', 'isIssuePreview:issue-preview'],

    isArticlePreview: Ember.computed('article', function () {
      return !!this.get('article');
    }),

    isIssuePreview: Ember.computed('issue', 'article', function () {
      return !!this.get('issue') && !this.get('article');
    })
  });
});