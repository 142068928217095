define('browzine-web/adapters/application', ['exports', 'ember-data', 'browzine-web/config/environment', 'browzine-web/mixins/belongs-to-library-adapter', 'browzine-web/mixins/client-adapter', 'browzine-web/mixins/localization-adapter', 'browzine-web/mixins/authenticating-adapter'], function (exports, _emberData, _environment, _belongsToLibraryAdapter, _clientAdapter, _localizationAdapter, _authenticatingAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attributes = {
    namespace: _environment.default.apiNamespace,

    auth: Ember.inject.service(),
    applicationSession: Ember.inject.service(),

    handleResponse: function handleResponse(status, headers, payload) {
      var unauthorizedError = this.handleUnauthorizedResponse(status, headers, payload);

      if (unauthorizedError) {
        return unauthorizedError;
      }

      return this._super.apply(this, arguments);
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    shouldReloadAll: function shouldReloadAll() {
      return true;
    }
  };

  if (_environment.default.apiHost) {
    attributes.host = _environment.default.apiHost;
  }

  exports.default = _emberData.default.RESTAdapter.extend(_authenticatingAdapter.default, _clientAdapter.default, _localizationAdapter.default, _belongsToLibraryAdapter.default, attributes);
});