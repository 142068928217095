define('browzine-web/components/my-journal-cancel-move/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['cancel-move', 'corner-element', 'right'],

    click: function click() {
      this.attrs['move-cancel']();
      return false;
    }
  });
});