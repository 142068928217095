define('browzine-web/routes/article-wayf', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    header: Ember.inject.service(),

    model: function model(params) {
      var findArticle = void 0;

      if (decodeURIComponent(params.article_id).indexOf('doi:') === 0) {
        var requestedDOI = decodeURIComponent(params.article_id).substring('doi:'.length);
        findArticle = this.store.queryRecord('article', { doi: requestedDOI, reload: true, include: 'issue,journal' });
      } else {
        findArticle = this.store.findRecord('article', params.article_id, { reload: true, include: 'issue, journal' });
      }

      return Ember.RSVP.hash({
        article: findArticle,
        libraries: this.store.findAll('library').then(function (libs) {
          return libs.filterBy('subscriptions.web.active');
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      Ember.set(controller, 'article', model.article);
    },
    titleToken: function titleToken() {
      this.get('header').setProperties({
        subject: {
          value: 'Choose',
          template: false
        },
        title: {
          value: 'Library',
          template: false
        }
      });

      return "Choose Library";
    }
  });
});