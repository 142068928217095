define('browzine-web/components/language-chooser-modal/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    classNames: ['language-chooser-modal'],

    intl: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var modal = this.get('modal');

      Ember.run.next(function () {
        if (_this.element) {
          modal.element = _this.element;
          modal.element.id = _this.element.id;
        }
      });

      modal.ok = function () {
        _this.ok();
      };

      modal.cancel = function () {
        _this.cancel();
      };
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      var modal = this.get('modal');

      modal.onShow = function () {
        Ember.run.next(function () {
          _this2.$('ul.languages li:first-child').focus();
        });
      };
    },


    actions: {
      ok: function ok() {
        var modal = this.get('modal');
        modal.hide();
      },
      selectLanguage: function selectLanguage(language) {
        this.get('selectLanguage')(language);
        this.send('ok');
      }
    }
  });
});