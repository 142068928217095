define('browzine-web/components/focusable-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    autofocus: Ember.computed('autofocusIfAvailable', 'autofocusAvailable', function () {
      //this determines whether we set the autofocus property in this input HTML element or not
      return this.get('autofocusAvailable') && this.get('autofocusIfAvailable');
    }),

    autofocusIfAvailable: true,

    autofocusAvailable: Ember.computed('media.isMobile', 'media.isTablet', 'media.isDesktop', 'media.isJumbo', function () {
      return this.get('media.isDesktop') || this.get('media.isJumbo');
    }),

    becomeFocused: function () {
      if (this.get('autofocus')) {
        this.$().focus();
      }
    }.on('didInsertElement')
  });
});