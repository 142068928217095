define('browzine-web/models/article-status-collection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    cachedCount: null,

    init: function init() {
      this.set('articleStatuses', Ember.A());
    },
    addArticleStatus: function addArticleStatus(articleStatus) {
      this.get('articleStatuses').addObject(articleStatus);
    },
    removeArticleStatus: function removeArticleStatus(articleStatus) {
      this.get('articleStatuses').removeObject(articleStatus);
    },


    unreadCount: Ember.computed.alias('cachedCount')
  });
});