define('browzine-web/errors/tracing', ['exports', 'browzine-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.addActionToTrace = addActionToTrace;
  exports.addTransitionToTrace = addTransitionToTrace;
  exports.getTrace = getTrace;


  var traceLines = [];

  function addActionToTrace(actionName, actionArguments, route, routeName, url) {
    if (!_environment.default.recordUserTrace) {
      return;
    }

    if (traceLines.length > 40) {
      traceLines.shift();
    }

    var newLine = 'ACTION     - actionName: ' + actionName + ', actionArgs: ' + Ember.inspect(actionArguments) + ', route: ' + Ember.inspect(route) + ', routeName: ' + routeName + ', url: ' + url;
    traceLines.push(newLine);
  }

  function addTransitionToTrace(newRouteName, prevRouteName, url, prevUrl) {
    if (!_environment.default.recordUserTrace) {
      return;
    }

    if (traceLines.length > 40) {
      traceLines.shift();
    }

    var newLine = 'TRANSITION - newRouteName: ' + newRouteName + ', prevRouteName: ' + prevRouteName + ', newUrl: ' + url + ', prevUrl: ' + prevUrl;
    traceLines.push(newLine);
  }

  function getTrace() {
    return traceLines.join('\n');
  }
});