define('browzine-web/components/back-issue-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'issue',
    classNameBindings: ['locked', 'active:active-override', 'journal.accessedThroughAggregator'],

    a11y: Ember.inject.service(),
    unreads: Ember.inject.service(),

    issueOrArticlesInPressStatus: Ember.computed(function () {
      if (this.get('articlesInPress')) {
        var articlesInPressUnreads = this.get('unreads').getArticlesInPressStatusById(this.get('journal.id'));
        return articlesInPressUnreads;
      } else {
        var issuesUnreads = this.get('unreads').getIssueStatusById(this.get('issue').id);
        return issuesUnreads;
      }
    }),

    unreadCount: Ember.computed.alias('issueOrArticlesInPressStatus.unreadCount'),

    actions: {
      toggleIssues: function toggleIssues(route, issue) {
        var _this = this;

        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          Ember.run(function () {
            Ember.$('.controls .issues').click();
            Ember.run.schedule('actions', _this, function () {
              _this.get('router').transitionTo(route, issue);
            });
          });
        }
      },
      focusIssueTitle: function focusIssueTitle() {
        var _this2 = this;

        var a11y = this.get('a11y');

        Ember.run(function () {
          Ember.run.schedule('actions', _this2, function () {
            a11y.issueTitleFocus();
          });
        });
      }
    }
  });
});