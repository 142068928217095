define('browzine-web/routes/library/my-articles/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myArticles: Ember.inject.service(),
    applicationSession: Ember.inject.service(),

    beforeModel: function beforeModel(params) {
      var collectionId = params.collection_id;

      if (!collectionId && this.get('applicationSession').get('userIsLoggedIn')) {
        // TODO: BZ-3648 - should this specify a sort order param?
        this.transitionTo('library.my-articles.collection', 'collection-general');
      }
    }
  });
});