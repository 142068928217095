define('browzine-web/initializers/add-announcer-to-router', ['exports', 'browzine-web/router'], function (exports, _router) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'add-announcer-to-router',

    initialize: function initialize() {

      _router.default.reopen({
        announcer: Ember.inject.service('announcer'),
        intl: Ember.inject.service('intl'),

        didTransition: function didTransition() {
          var _this = this;

          this._super.apply(this, arguments);

          this._timerId = Ember.run.later(function () {
            if (_this.isDestroying || _this.isDestroyed) {
              return;
            }

            var pageTitle = document.title.trim();
            var serviceMessage = _this.get('announcer.message');
            // Upstream a11y-announcer allowed for customizing the end of the announcement message
            // via announcer.message, we'll just leave any customization to the ember-intl translations
            // file.
            var message = _this.get('intl').t('route_announcement', { pageTitle: pageTitle });

            _this.get('announcer').announce(message, 'polite');
          }, 100);
        },
        willDestroy: function willDestroy() {
          Ember.run.cancel(this._timerId);
          this._super();
        }
      });
    }
  };
});