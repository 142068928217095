define('browzine-web/routes/doi-lookup', ['exports', 'browzine-web/config/environment', 'browzine-web/mixins/route-auth-helpers', 'browzine-web/mixins/article-loading-route', 'browzine-web/errors/ApiSessionExpiredError', 'browzine-web/errors/AuthRequiredError'], function (exports, _environment, _routeAuthHelpers, _articleLoadingRoute, _ApiSessionExpiredError, _AuthRequiredError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAuthHelpers.default, _articleLoadingRoute.default, {

    intl: Ember.inject.service(),

    // right now the article controller just handles query params that
    // this route also needs.  Might as
    // well reuse it
    controllerName: 'article',
    templateName: 'article',

    beforeModel: function beforeModel(transition) {
      var requestedDOI = transition.params['doi-lookup'].doi;
      return this.ensureLibrarySelectedForArticle(transition, encodeURIComponent('doi:' + requestedDOI));
    },
    model: function model(params) {
      var libraryId = this.get('applicationSession').get('selectedLibrary');
      var requestedDOI = params.doi;
      var findArticleWithJournalAndIssue = this.store.queryRecord('article', { doi: requestedDOI, reload: true, include: 'issue,journal' });
      var findLibrary = this.store.findRecord('library', libraryId);

      this.set('disableRedirect', !!params.disableRedirect);

      this.set('hasArticle', true);
      return this.prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary).then(function (model) {
        return model;
      }).catch(function (error) {
        if (error.status === 404) {
          var _error = new Error("Article not found");
          _error.articleNotFound = true;
          throw _error;
        } else {
          throw error;
        }
      });
    },
    afterModel: function afterModel(model) {

      if (Ember.isEmpty(model.article.get('contentLocation'))) {
        this.set('hasArticle', false);
      }

      if (_environment.default.environment !== 'test' && model.article.get('browseable') && !this.get('disableRedirect')) {

        var transitionToArguments = void 0;

        if (model.article.get('inPress')) {
          transitionToArguments = ['library.journal.articles-in-press', model.library.id, model.journal.id, { queryParams: { showArticleInContext: 'doi:' + model.article.doi } }];
        } else {
          transitionToArguments = ['library.journal.issue', model.library.id, model.journal.id, model.issue.id, { queryParams: { showArticleInContext: 'doi:' + model.article.get('doi') } }];
        }
        this.set('article-in-context-to-transition-to', transitionToArguments);
      }
    },
    renderTemplate: function renderTemplate() {

      this.setupRedirectAfterRender({ transitionToArgumentsPropertyName: 'article-in-context-to-transition-to' });

      this.render();
    },
    titleToken: function titleToken(model) {
      return Ember.getWithDefault(model, 'title', this.get('intl').t('article.default_page_title_token'));
    },
    setupController: function setupController(controller, model) {
      controller.set('article', model.article);
      controller.set('issue', model.issue);
      controller.set('library', model.library);
      controller.set('journal', model.journal);
      controller.set('hasArticle', this.get('hasArticle'));
      controller.set('loadingType', 'inContext');
    },


    actions: {
      error: function error(err, transition) {
        if (err instanceof _ApiSessionExpiredError.default || err instanceof _AuthRequiredError.default) {
          var libraryId = this.get('applicationSession').get('selectedLibrary');
          this.handleAPIAuthDemandError(err, libraryId, transition);
        }
        return true;
      }
    }

  });
});