define('browzine-web/components/user-bps-auth/component', ['exports', 'ember-intl'], function (exports, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['user-bps-auth'],

    auth: Ember.inject.service(),
    errorReporter: Ember.inject.service('error-reporter'),
    inProgress: false,
    unknownError: Ember.computed('error', function () {
      return this.get('error') === 'unknown';
    }),
    outsideRangeError: Ember.computed('error', function () {
      return this.get('error') === 'outsideRange';
    }),

    submitButtonAriaLabel: (0, _emberIntl.intl)('inProgress', function (intl) {
      if (this.get('inProgress')) {
        return intl.t('bps_auth.activate_bps_in_progress');
      } else {
        return intl.t('bps_auth.activate_bps');
      }
    }),

    submitEmailButtonAriaLabel: (0, _emberIntl.intl)('emailInProgress', function (intl) {
      if (this.get('emailInProgress')) {
        return intl.t('bps_auth.activate_via_email_sending_email');
      } else {
        return intl.t('bps_auth.activate_via_email');
      }
    }),

    // Property to hold BPS token in between retrieval
    // and user clicking continue button
    bpsTokenData: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.send('authenticateBps', this.get('libraryId'));
    },


    actions: {
      authenticateBps: function authenticateBps(libraryId) {
        var _this = this;

        var auth = this.get('auth');
        this.set("error", null);
        this.set('inProgress', true);
        this.set('emailSent', false);
        return auth.authenticateBps(libraryId).then(function (bpsTokenData) {

          // If the flow that got us here started from
          // the native app sending over "check-bps",
          // immediately send the user back to the app
          if (_this.get("check")) {
            _this.sendAction('onAuthSuccess', bpsTokenData);
            return;
          }

          _this.set('bpsTokenData', bpsTokenData);

          _this.set('bpsConfirmed', true);
        }).catch(function (err) {
          if (err.status === "403") {
            _this.set('error', 'outsideRange');
          } else {
            _this.set('error', 'unknown');
            _this.get('errorReporter').reportUnknownError('error authenticating BPS', err);
          }
        }).finally(function () {
          return _this.set('inProgress', false);
        });
      },
      sendBpsEmail: function sendBpsEmail(libraryId) {
        var _this2 = this;

        this.set("error", null);
        this.set('emailInProgress', true);
        return this.get('auth').sendBpsEmail(libraryId).then(function (_ref) {
          var sentTo = _ref.sentTo;

          _this2.set('emailSent', true);
          _this2.set('emailSentTo', sentTo);
        }).catch(function (err) {
          _this2.set('error', 'unknown');
          _this2.get('errorReporter').reportUnknownError('error authenticating BPS', err);
        }).finally(function () {
          return _this2.set('emailInProgress', false);
        });
      },
      sendAuthSuccess: function sendAuthSuccess() {
        var bpsTokenData = this.get('bpsTokenData');
        this.sendAction('onAuthSuccess', bpsTokenData);
      }
    }
  });
});