define('browzine-web/routes/oauth', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    errorReporter: Ember.inject.service(),
    exporters: Ember.inject.service(),
    store: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this.params = transition.queryParams;
    },
    model: function model() {
      var _this = this;

      var articleId = localStorage.getItem('export_article');

      return this.get('store').findRecord('article', articleId).then(function (article) {
        var libraryId = localStorage.getItem('BZ_mostRecentLibrary');

        return _this.get('store').findRecord('library', libraryId).then(function (library) {
          return Ember.RSVP.hash({
            article: article,
            library: library
          });
        });
      }, function (error) {
        _this.get('flashMessages').alert("Error authenticating with citation service.");
        _this.get('errorReporter').reportFailedArticleShare(error);
      });
    },
    afterModel: function afterModel(model) {
      var exporters = this.get('exporters');
      var service = exporters.services[this.params['service']];
      this.params.protocol = window.location.protocol;

      service.token(this.params, function () {
        exporters.saveArticle(service, model.article, model.library, function () {
          localStorage.setItem('save_article_success', 'yes');
          localStorage.setItem('export_service', service.get('name'));
          window.close();
        });
      });
    }
  });
});