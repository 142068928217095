define('browzine-web/services/header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    subject: {
      value: '',
      template: false
    },
    title: {
      value: '',
      template: false
    }
  });
});