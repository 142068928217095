define('browzine-web/routes/my-bookshelf/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sync: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    myBookshelf: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      if (this.get('applicationSession').get('loggedInUser')) {
        return this.get('sync').ensureDataLoaded().then(function () {
          if (_this.get('myBookshelf.isAvailable')) {
            var bookcase = _this.modelFor('my-bookshelf').getBookcase(localStorage.getItem('my-bookcase') || 1);
            _this.transitionTo('my-bookshelf.bookcase', bookcase.get('id'));
          }
        }).catch(function () {
          _this.get('flashMessages').alert(_this.get('intl').t('my_bookshelf.my_bookshelf_currently_unavailable_error_message'));

          if (_this.get('myBookshelf.isAvailable')) {
            var bookcase = _this.modelFor('my-bookshelf').getBookcase(localStorage.getItem('my-bookcase') || 1);
            _this.transitionTo('my-bookshelf.bookcase', bookcase.get('id'));
          }
        });
      }
    }
  });
});