define('browzine-web/routes/library/my-articles/collection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sync: Ember.inject.service(),
    store: Ember.inject.service(),
    myArticles: Ember.inject.service(),
    applicationSession: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this.get('myArticles').onCollectionItemChange(function (collection) {
        if (_this.controllerFor('library.my-articles.collection').target.currentRouteName === 'library.my-articles.collection') {
          _this.send('refreshCollection', collection);
        }
      });

      this.get('myArticles').onCollectionRemoved(function () {
        // TODO BZ-3635 FIXME: This code from upstream appears like it will redirect to the general collection
        // any time any collection is removed by another client and causes a removal change event
        // to get processed

        if (_this.controllerFor('library.my-articles.collection').target.currentRouteName === 'library.my-articles.collection') {
          _this.transitionTo('library.my-articles.collection', 'collection-general');
        }
      });

      this._super.apply(this, arguments);
    },
    model: function model(params) {
      var _this2 = this;

      var loggedInUser = this.get('applicationSession').get('loggedInUser');

      if (loggedInUser) {
        var myArticles = this.get('myArticles');
        var collectionId = params.collection_id;

        var sync = this.get('sync');

        return sync.ensureDataLoaded().then(function () {
          return Ember.RSVP.hash({
            collectionId: collectionId,
            loadCollections: myArticles.loadCollections()
          });
        }).catch(function () {
          _this2.get('flashMessages').alert('My Articles is currently unavailable. Please refresh or contact support if this persists.');
        });
      } else {
        this.transitionTo('library.my-articles');
      }
    },
    afterModel: function afterModel(model) {
      if (model) {
        var store = this.get('store');
        var collectionId = model.collectionId;
        var collection = store.peekRecord('collection', collectionId);

        if (!collection && collectionId === 'collection-general') {
          console.log('Somehow the general collection failed to load!');
          var error = new Error('Model for general collection didn\'t load');
          throw error;
        }

        if (!collection) {
          this.transitionTo('library.my-articles');
        }
      } else {
        this.transitionTo('library.my-articles.collection', 'collection-general');
      }
    },


    actions: {
      queryParamsDidChange: function queryParamsDidChange() {
        this._super.apply(this, arguments);
        this.refresh();
      },
      refreshCollection: function refreshCollection(collection) {
        var state = this.get('router._routerMicrolib.state');
        var params = state.params;

        var curCollectionId = params['library.my-articles.collection'].collection_id;
        if (curCollectionId === collection.get('id')) {
          this.refresh();
        }
      }
    }
  });
});