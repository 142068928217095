define('browzine-web/components/reset-password/component', ['exports', 'ember-intl', 'browzine-web/config/environment'], function (exports, _emberIntl, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['reset-password'],

    auth: Ember.inject.service(),
    errorReporter: Ember.inject.service(),
    intl: Ember.inject.service(),

    accountActionsDisabled: Ember.computed(function () {
      return _environment.default.disableAccountActions;
    }),
    inProgress: false,
    newPasswordLabel: (0, _emberIntl.t)('auth.new_password_label'),
    newPasswordConfirmLabel: (0, _emberIntl.t)('auth.new_password_confirm_label'),
    savePasswordButtonLabel: (0, _emberIntl.intl)('inProgress', function (intl) {
      if (this.get('inProgress')) {
        return intl.t('auth.save_password_button_in_progress');
      } else {
        return intl.t('auth.save_password_button');
      }
    }),
    resetPasswordButtonLabel: (0, _emberIntl.intl)('inProgress', function (intl) {
      if (this.get('inProgress')) {
        return intl.t('auth.reset_password_button_label_in_progress');
      } else {
        return intl.t('auth.reset_password_button_label');
      }
    }),

    actions: {
      sendToken: function sendToken() {
        var _this = this;

        var _getProperties = this.getProperties('email', 'auth'),
            email = _getProperties.email,
            auth = _getProperties.auth;

        this.set("error", null);

        if (!email || !email.length || email.indexOf("@") < 0) {
          this.set('error', this.get('intl').t('auth.invalid_email_error_message'));
          return;
        }

        this.set('inProgress', true);

        return auth.emailResetToken(email).then(function () {
          return _this.set('tokenSent', true);
        }).catch(function (error) {
          if (error.code === 'unknown_user') {
            _this.sendAction('onUnknownUser', email);
            return;
          }
          console.log(error.message);
          // Aside from the unknown_user error, just show a generic error message
          // for errors from the reset password endpoint
          _this.set('error', _this.get('intl').t('auth.reset_password_server_error_message'));
        }).finally(function () {
          return _this.set('inProgress', false);
        });
      },
      updatePassword: function updatePassword() {
        var _this2 = this;

        var auth = this.get('auth');

        var resetProps = this.getProperties('email', 'token', 'newPassword', 'newPasswordConfirm');

        var email = resetProps.email,
            newPassword = resetProps.newPassword,
            newPasswordConfirm = resetProps.newPasswordConfirm;


        this.set('error', null);

        var _auth$validatePasswor = auth.validatePassword(newPassword, newPasswordConfirm),
            valid = _auth$validatePasswor.valid,
            validationErrorMessage = _auth$validatePasswor.validationErrorMessage;

        if (!valid) {
          this.set('error', validationErrorMessage);
          this.set('tokenSent', false);
          this.set('token', null);
          this.set('newPassword', null);
          this.set('newPasswordConfirm', null);
          return;
        }

        this.set('inProgress', true);

        return auth.updatePassword(resetProps).then(function () {
          return auth.authenticateUser(email, newPassword);
        }).then(function () {
          return _this2.set('resetComplete', true);
        }).catch(function (error) {
          console.log(error.message);
          _this2.set('error', _this2.get('intl').t('auth.reset_password_server_error_message'));
          _this2.set('tokenSent', false);
          _this2.set('token', null);
          _this2.set('newPassword', null);
          _this2.set('newPasswordConfirm', null);
        }).finally(function () {
          return _this2.set('inProgress', false);
        });
      },
      continue: function _continue() {
        this.sendAction('onLoggedIn');
      }
    }
  });
});