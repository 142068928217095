define('browzine-web/routes/library/subject/bookcase/index', ['exports', 'browzine-web/mixins/taxonomy-route'], function (exports, _taxonomyRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_taxonomyRoute.default, {
    intl: Ember.inject.service(),

    afterModel: function afterModel(bookcase) {
      return this.buildTaxonomy({
        subject: this.modelFor('library.subject'),
        bookcase: bookcase
      });
    },
    titleToken: function titleToken() {
      return this.get('intl').t('subjects.all_journals');
    }
  });
});