define('browzine-web/routes/library/subject/bookcase/bookshelf', ['exports', 'browzine-web/mixins/taxonomy-route', 'browzine-web/mixins/query-storing-route'], function (exports, _taxonomyRoute, _queryStoringRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_taxonomyRoute.default, _queryStoringRoute.default, {
    header: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    intl: Ember.inject.service(),

    model: function model(params) {
      return this.store.findRecord('bookshelf', params.bookshelf_id, { preload: { library: this.modelFor('library') } }).then(function (bookshelf) {
        // Have to reload the journals list or else when we're coming back from looking at a specific
        // journal, for some reason the journal that was visited appears at the end of the list
        // rather than where it should be in the alphabetical order
        // See BZ-4676: https://thirdiron.atlassian.net/browse/BZ-4676
        return bookshelf.hasMany('journals').reload().then(function () {
          return bookshelf;
        });
      });
    },
    afterModel: function afterModel(bookshelf) {
      return this.buildTaxonomy({
        subject: this.modelFor('library.subject'),
        bookcase: this.modelFor('library.subject.bookcase'),
        bookshelf: bookshelf
      });
    },
    titleToken: function titleToken(model) {
      this.get('header').setProperties({
        subject: {
          value: 'subjects.subject',
          template: true
        },
        title: {
          value: Ember.get(model, 'name'),
          template: false
        }
      });

      return Ember.get(model, 'name');
    }
  });
});