define('browzine-web/routes/auth', ['exports', 'urijs'], function (exports, _urijs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),
    windowLocation: Ember.inject.service(),
    myBookshelf: Ember.inject.service(),
    myArticles: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    intl: Ember.inject.service(),

    // Properties whose values come from the queryString but the keys use hyphens, so I'm not sure if queryParams property would work
    callback: null,
    deviceId: null,
    libraryId: null,
    userToken: null,
    checkBps: null,

    beforeModel: function beforeModel(transition) {
      transition.queryParams.returnRoute = transition.queryParams.returnRoute || this.get('myBookshelf.returnRoute');
      transition.queryParams.loginRequired = transition.queryParams.loginRequired || this.get('myBookshelf.loginRequired');

      // Values that come from the native apps
      this.set('callback', transition.queryParams['callback']);
      this.set('deviceId', transition.queryParams['device-id']);
      this.set('libraryId', transition.queryParams['library-id']);
      this.set('userToken', transition.queryParams['user-token']);
      this.set('checkBps', transition.queryParams['check-bps']);

      this.set('returnRoute', transition.queryParams.returnRoute);
      this.set('loginRequired', transition.queryParams.loginRequired);
      this.set('returnParameter', transition.queryParams.returnParameter);
      this.set('confirmBpsEmail', transition.queryParams['confirm-bps-email']);
    },


    model: function model() {
      var _this = this;

      var libraryId = this.get('libraryId');

      return this.get('auth').checkIfLibraryUsesBPS(libraryId).then(function (isBpsAuthNecessary) {
        return Ember.RSVP.hash({
          confirmingBpsEmail: _this.get('confirmBpsEmail'),
          checkingBps: _this.get('checkBps'),
          isBpsLibrary: isBpsAuthNecessary,
          loginRequired: _this.get('loginRequired'),
          returnRoute: _this.get('returnRoute'),
          deviceId: _this.get('deviceId'),
          userToken: _this.get('userToken')
        });
      });
    },

    titleToken: function titleToken() {
      return this.get('intl').t('auth.login_page_title');
    },


    actions: {
      loginComplete: function loginComplete() {
        var _this2 = this;

        var auth = this.get('auth'),
            callback = this.get('callback'),
            returnRoute = this.get('returnRoute'),
            confirmingBpsEmail = this.get('confirmBpsEmail'),
            checkingBps = this.get('checkingBps'),
            loginRequired = this.get('loginRequired'),
            libraryId = this.get('libraryId');

        var transitionToRetry = this.get('applicationSession').get('transitionToRetryAfterAuth');

        // ?? checkingBps here is never true, due to presumably a typo.
        // checkBps is a property on the route that gets set, but
        // checkingBps is not.  There is a "checkingBps" property on this
        // route's model, but no property named "checkingBps"
        //
        // Does this path ever get taken due to "confirmingBpsEmail" being
        // set?  Would it ever be set at the time that loginComplete is
        // invoked?
        if (confirmingBpsEmail || checkingBps) {
          this.transitionTo('auth.bps', { queryParams: { "library-id": this.get('libraryId') } });
        } else if (callback) {
          return auth.checkIfLibraryUsesBPS(libraryId).then(function (bpsAuthNecessary) {
            if (bpsAuthNecessary) {
              _this2.transitionTo('auth.bps', { queryParams: { "library-id": _this2.get('libraryId') } });
            } else {
              _this2.send('sendDeviceCallback');
            }
          });
        } else if (transitionToRetry) {
          this.get('applicationSession').set('transitionToRetryAfterAuth', undefined);
          return transitionToRetry.retry();
        } else if (Ember.isEmpty(returnRoute)) {
          this.send('goToCurrentLibrary');
        } else if (loginRequired && returnRoute) {
          localStorage.setItem('myBookshelf.shelve', JSON.stringify(this.get('myBookshelf.shelve')));
          localStorage.setItem('myArticles.addToMyArticles', this.get('myArticles.addToMyArticles'));

          this.get('windowLocation').assign(returnRoute);
        } else {
          this.transitionTo(returnRoute, this.get('returnParameter'));
        }
      },
      sendDeviceCallback: function sendDeviceCallback(bpsTokenData) {
        var originalCallback = this.get('callback');

        var _get$get = this.get('applicationSession').get('loggedInUser'),
            userId = _get$get.userId,
            email = _get$get.email,
            token = _get$get.token,
            couchdbDatabaseLocation = _get$get.couchdbDatabaseLocation;

        var callback = (0, _urijs.default)(originalCallback).addQuery('user-id', userId).addQuery('email', email).addQuery('api-token', token).addQuery('couch-url', couchdbDatabaseLocation);

        if (bpsTokenData) {
          callback.addQuery('bps-token', bpsTokenData.token);
          callback.addQuery('bps-expires-at', bpsTokenData.expiresAt);
        }
        this.get('windowLocation').assign(callback.toString());
      }
    }
  });
});