define('browzine-web/routes/library/settings/library-customization', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    permissions: Ember.inject.service(),

    afterModel: function afterModel(model) {
      var permissions = this.get('permissions');
      var requestedPermissions = ['admin.library.edit'];
      var libraryPermissionRequestedFor = model.library.get('id');
      var hasPermission = permissions.hasPermission(requestedPermissions, libraryPermissionRequestedFor);
      if (!hasPermission) {
        this.transitionTo('library.settings.user-settings');
      }
    }
  });
});