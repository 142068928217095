define('browzine-web/components/scimago-rank/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['url:href', 'target'],
    classNames: 'scimago-rank',

    tagName: 'a',

    url: Ember.computed.alias('journal.scimagoURL'),

    target: '_new'
  });
});