define('browzine-web/components/scroll-top/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      if (this.get('media.isMobile') || this.get('media.isTablet')) {
        Ember.$(window).scrollTop(0);
      }
    }
  });
});