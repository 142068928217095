define('browzine-web/components/loading-indicator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['inline', 'white', 'smaller'],

    alignment: 'right'
  });
});