define('browzine-web/components/login-information/component', ['exports', 'ember-intl'], function (exports, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    shouldDisplayVpnError: Ember.computed('error', 'library', function () {
      return this.get('error') && this.get('library.id') === this.getWithDefault('error.libraryId', 0).toString();
    }),

    vpnErrorMessage: (0, _emberIntl.intl)('error', function (intl) {
      return intl.t('login_info.vpn_error_message_2', {
        libraryName: this.get('library.name'),
        libraryId: this.get('library.id'),
        ipAddress: this.get('error.requestIp')
      });
    }),

    authenticationInfoLinkLabel: (0, _emberIntl.intl)('library.webLoginInfo.linkLabel', function (intl) {
      var label = this.get('library.webLoginInfo.linkLabel');

      if (Ember.isBlank(label)) {
        return intl.t('login_info.auth_info_link_default_text');
      } else {
        return label;
      }
    })
  });
});