define('browzine-web/models/library', ['exports', 'ember-data', 'ember-intl'], function (exports, _emberData, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    aliases: _emberData.default.attr(),

    rawLogo: _emberData.default.attr('string'),
    logo: Ember.computed('rawLogo', function () {
      var rawLogo = this.get('rawLogo') || '';
      return rawLogo.replace('http:', 'https:');
    }),
    customBackgroundImageUrl: _emberData.default.attr('string'),
    httpsCustomBackgroundImageUrl: Ember.computed('customBackgroundImageUrl', function () {
      var url = this.get('customBackgroundImageUrl') || '';
      return url.replace('http:', 'https:');
    }),

    customEmbargoMessage: _emberData.default.attr('string'),
    customAvailabilityMessage: _emberData.default.attr('string'),

    noPersonalization: Ember.computed('subscriptions.web.type', function () {
      var webSubType = this.get('subscriptions.web.type');
      if (webSubType === 'activeNoPersonalization' || webSubType === 'trialNoPersonalization') {
        return true;
      }
      return false;
    }),

    logoHasLibraryName: _emberData.default.attr('boolean'),

    promoteNativeApp: _emberData.default.attr('boolean'),

    azISSNSearchTemplate: _emberData.default.attr('string'),
    azPreProxy: _emberData.default.attr('string'),
    azSystem: _emberData.default.attr('string'),
    azSystemLibraryId: _emberData.default.attr('string'),
    azTitleSearchTemplate: _emberData.default.attr('string'),
    aToZListUrl: _emberData.default.attr('string'),
    knownJournalLookupTemplate: _emberData.default.attr('string'),
    displayPublisherHomePageLinkButton: _emberData.default.attr('boolean'),
    promoLink: _emberData.default.attr('string'),
    promoLabel: _emberData.default.attr('string'),
    accessProvidedByLink: _emberData.default.attr('string'),
    accessProvidedByLabel: _emberData.default.attr('string'),

    externalSearchHeader: _emberData.default.attr('string'),

    infoURL: _emberData.default.attr('string'),
    webInfoURL: _emberData.default.attr('string'),

    subscriptions: _emberData.default.attr(),
    webLoginInfo: _emberData.default.attr(),

    defaultIssue: _emberData.default.attr('string'),

    supportsILL: _emberData.default.attr('boolean'),

    webLoginInfoTitle: _emberData.default.attr('string'),
    webLoginInfoDescription: _emberData.default.attr('string'),
    webLoginInfoContinueLabel: _emberData.default.attr('string'),
    webLoginInfoLinkLabel: _emberData.default.attr('string'),
    webLoginInfoLinkUrl: _emberData.default.attr('string'),

    nativeLoginInfoTitle: _emberData.default.attr('string'),
    nativeLoginInfoDescription: _emberData.default.attr('string'),
    nativeLoginInfoContinueLabel: _emberData.default.attr('string'),
    nativeLoginInfoLinkLabel: _emberData.default.attr('string'),
    nativeLoginInfoLinkUrl: _emberData.default.attr('string'),

    allBackIssuesMessage: _emberData.default.attr('string'),

    formattedExternalSearchHeader: (0, _emberIntl.intl)('externalSearchHeader', function (intl) {
      if (Ember.isEmpty(this.get('externalSearchHeader'))) {
        return intl.t('search.search_results.external_results_header');
      }
      return this.get('externalSearchHeader');
    }),

    subjects: _emberData.default.hasMany('subject', { async: true }),

    bpsLibrary: _emberData.default.attr('boolean'),

    forceAuthAtFrontDoor: _emberData.default.attr('boolean'),
    ssoEnabled: _emberData.default.attr('boolean'),
    ssoRequiredOnSite: _emberData.default.attr('boolean'),

    articleTitleUsesBestAvailableLink: _emberData.default.attr('boolean'),

    languagesSupported: _emberData.default.attr('string')
  });
});