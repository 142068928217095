define('browzine-web/components/my-journal-menu/component', ['exports', 'browzine-web/mixins/accordion-item', 'browzine-web/mixins/click-on-enter', 'browzine-web/mixins/trigger-on-enter', 'ember-intl'], function (exports, _accordionItem, _clickOnEnter, _triggerOnEnter, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_accordionItem.default, _clickOnEnter.default, _triggerOnEnter.default, {
    classNames: ['menu', 'corner-element', 'right'],
    classNameBindings: ['isExpanded:showing', 'enableVisibleMenu:my-journal-menu-visible'],

    attributeBindings: ['title'],

    title: (0, _emberIntl.intl)('journal.title', function (intl) {
      return intl.t('my_bookshelf.journal_menu_title', { journalTitle: this.get('journal.title') });
    }),

    enableVisibleMenu: Ember.computed('editBookcase', 'media.isMobile', 'media.isTablet', function () {
      return this.get('editBookcase') && (this.get('media.isMobile') || this.get('media.isTablet'));
    }),

    click: function click() {
      if (this.get('isExpanded')) {
        this.send('contract');
      } else {
        this.send('expand');
      }
      return false;
    },


    actions: {
      delete: function _delete() {
        this.attrs.delete();
        return false;
      },
      move: function move() {
        this.attrs['move-start']();
        return false;
      }
    }
  });
});