define('browzine-web/mixins/query-storing-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var libraryParams = this.paramsFor('library');

      if (libraryParams.storeQuery) {
        var libraryController = this.controllerFor('library');
        libraryController.send('storePreviousSearch', libraryParams.query);
      }
    }
  });
});