define('browzine-web/services/notifications', ['exports', 'fetch', 'browzine-web/config/environment'], function (exports, _fetch, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var inTestEnvironment = _environment.default.environment === 'test';

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('articleJournalTitles', []);

      this._setPermissionGranted();
    },
    requestPermission: function requestPermission() {
      var _this = this;

      if (window.Notification && Notification.permission === 'default') {
        Notification.requestPermission(function () {
          _this._setPermissionGranted();
        });
      }
    },


    isSupported: Ember.computed(function () {
      return window.SharedWorker;
    }),

    canBeRequested: Ember.computed('isSupported', 'permissionGranted', 'permissionDenied', function () {
      return this.get('isSupported') && !this.get('permissionGranted') && !this.get('permissionDenied');
    }),

    add: function add(articleStatus) {
      var _this2 = this;

      var journalId = articleStatus.get('journalId');

      if (journalId && this.get('worker')) {
        this.get('store').findRecord('journal', journalId).then(function (journal) {
          _this2.get('worker').port.postMessage({
            syncId: articleStatus.get('id'),
            journalTitle: journal.get('title'),
            journalCoverURL: journal.get('coverURL'),
            test: _environment.default.environment === 'test'
          });
        });
      }
    },
    _setPermissionGranted: function _setPermissionGranted() {
      var permissionGranted = window.Notification && Notification.permission === 'granted';
      var permissionDenied = window.Notification && Notification.permission === 'denied';
      this.set('permissionGranted', permissionGranted || inTestEnvironment);
      this.set('permissionDenied', permissionDenied);

      if (this.get('permissionGranted')) {
        this._fetchOrCreateWorker();
      }
    },


    // Below is an unfortunate mess for two good reasons:
    // * to allow a SharedWorker to be instatiated from a different origin (assets server)
    // * to let the worker code be tested

    _fetchOrCreateWorker: function _fetchOrCreateWorker() {
      var _this3 = this;

      var existingWorkerBlobURL = localStorage.getItem('workerBlobURL');

      if (existingWorkerBlobURL && !inTestEnvironment) {
        console.log('Blob URL stored, checking…');

        (0, _fetch.default)(existingWorkerBlobURL).then(function (_ref) {
          var status = _ref.status;

          if (status === 404) {
            // It’s an expired blob URL, try to get another
            console.log('Blob is not valid, fetching another');
            _this3._fetchWorker();
          } else {
            // If the stored blob points to worker code, instantiate it
            console.log('Blob seems valid, creating worker');
            _this3._createWorkerFromBlobURL(existingWorkerBlobURL);
          }
        });
      } else {
        this._fetchWorker();
      }
    },
    _fetchWorker: function _fetchWorker() {
      var _this4 = this;

      // This lets us wait for the worker to be ready in tests
      this.set('workerPromise', new Ember.RSVP.Promise(function (resolve) {
        (0, _fetch.default)('assets/notifier.js').then(function (response) {
          return response.blob();
        }).then(function (blob) {
          return URL.createObjectURL(blob);
        }).then(function (workerBlobURL) {
          resolve(_this4._createWorkerFromBlobURL(workerBlobURL));
        });
      }));
    },
    _createWorkerFromBlobURL: function _createWorkerFromBlobURL(workerBlobURL) {
      localStorage.setItem('workerBlobURL', workerBlobURL);

      var worker = new SharedWorker(workerBlobURL);
      this.set('worker', worker);

      if (inTestEnvironment) {
        // This listens for the worker to send back the notification,
        // which it only does in the test environment.
        this.set('notificationPromise', new Ember.RSVP.Promise(function (resolve) {
          worker.port.onmessage = function (e) {
            resolve(e.data);
          };
        }));
      }

      return worker;
    }
  });
});