define('browzine-web/components/service-list/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    classNames: ['share-modal'],

    exporters: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var handleKeyPress = function handleKeyPress(event) {
        //Esc Key
        if (event.keyCode === 27) {
          Ember.run.next(function () {
            _this.hide();
          });
        }
      };

      Ember.$(document).on('keyup', handleKeyPress);
      this.set('handleKeyPress', handleKeyPress);
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      var onOutsideClick = function onOutsideClick(event) {
        var container = _this2.$('.services-list').parent().parent();
        if (!container) {
          return;
        }
        if (!container.is(event.target) && container.has(event.target).length === 0) {
          _this2.hide();
        }
      };

      Ember.$(document).mouseup(onOutsideClick);
      Ember.$(document).keypress(onOutsideClick);
      this.set('hideListener', onOutsideClick);
    },
    didDestroyElement: function didDestroyElement() {
      Ember.$(document).unbind('mouseup', this.get('hideListener'));
      Ember.$(document).unbind('keypress', this.get('hideListener'));
      Ember.$(document).unbind('keyup', this.get('handleKeyPress'));
    },
    hide: function hide() {
      this.sendAction('hide');
    },


    actions: {
      share: function share(service) {
        this.hide();
        var libraryFromArticle = this.get('article') ? this.get('article').get('library') : undefined;
        var libraryToUse = this.get('library') || libraryFromArticle || this.get('article.library');
        service.call(this, this.get('article'), libraryToUse, this.get('exporters'));
      },
      zotero: function zotero() {
        var article = this.get('article');
        var library = this.get('library');
        var exporters = this.get('exporters');
        exporters.zotero(library, article);
        this.hide();
      },
      mendeley: function mendeley() {
        var article = this.get('article');
        var library = this.get('library');
        var exporters = this.get('exporters');
        exporters.mendeley(library, article);
        this.hide();
      },
      bibtex: function bibtex() {
        var article = this.get('article');
        var library = this.get('library');
        var exporters = this.get('exporters');
        exporters.bibtex(library, article);
        this.hide();
      },
      refworks: function refworks() {
        var article = this.get('article');
        var library = this.get('library');
        var exporters = this.get('exporters');
        exporters.refworks(library, article);
        this.hide();
      },
      endnote: function endnote() {
        var article = this.get('article');
        var library = this.get('library');
        var exporters = this.get('exporters');
        exporters.endnote(library, article);
        this.hide();
      },
      universal: function universal() {
        //Same output as Endnote RIS. Helps Sales overcome generic RIS use questions.
        this.send('endnote');
      },
      citavi: function citavi() {
        //Same output as Endnote RIS. Helps Sales overcome generic RIS use questions.
        this.send('endnote');
      },
      copyLinkToArticle: function copyLinkToArticle() {
        this.hide();

        var copyLinkToArticleModal = this.get('copyLinkToArticleModal');
        copyLinkToArticleModal.show();
      },
      twitter: function twitter() {
        var article = this.get('article');
        var exporters = this.get('exporters');
        exporters.twitter(article);
        this.hide();
      },
      facebook: function facebook() {
        var article = this.get('article');
        var exporters = this.get('exporters');
        exporters.facebook(article);
        this.hide();
      },
      linkedin: function linkedin() {
        var article = this.get('article');
        var exporters = this.get('exporters');
        exporters.linkedin(article);
        this.hide();
      },
      email: function email() {
        var article = this.get('article');
        var exporters = this.get('exporters');
        exporters.email(article);
        this.hide();
      }
    }
  });
});