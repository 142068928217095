define('browzine-web/components/scroll-to/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var animatedScrollStyle = 'animated',
      jumpScrollStyle = 'jump',
      defaultScrollDuration = 1000,
      defaultScrollEasing = 'easeOutCubic',
      defaultScrollStyle = jumpScrollStyle;

  function animateScroll($container, scrollDestination, scrollDuration, scrollEasing, pixelsToJumpToWithin) {
    if (scrollDestination - $container.scrollTop() > pixelsToJumpToWithin) {
      $container.scrollTop(scrollDestination - pixelsToJumpToWithin);
    }

    $container.animate({ scrollTop: scrollDestination }, scrollDuration, scrollEasing);
  }

  exports.default = Ember.Component.extend({
    userAgent: Ember.inject.service('user-agent'),

    didInsertElement: function didInsertElement() {
      if (!this.get('scrollTo')) {
        return;
      }

      var isIOS = this.get('userAgent').get('isIOS');
      var isSafari = this.get('userAgent').get('isSafari');
      var isEdge = this.get('userAgent').get('isEdge');
      var deviceContainer = isIOS || isSafari || isEdge ? 'body' : 'html';
      var defaultContainer = Ember.testing ? '#ember-testing-container' : deviceContainer;
      var container = this.get('containerSelector') || defaultContainer;
      var $container = Ember.$(container);

      var scrollStyle = this.get('scrollStyle') || defaultScrollStyle;
      var scrollDuration = parseInt(this.get('scrollDuration') || defaultScrollDuration, 10);
      var scrollEasing = this.get('scrollEasing') || defaultScrollEasing;

      var offsetSelector = this.get('offsetSelector');
      var offset = offsetSelector ? Math.ceil(Ember.$(offsetSelector).height()) : 0;

      var containerHeight = Math.ceil($container.height() - offset);
      var defaultResultTopMargin = 80;

      var resultTopMargin = parseInt(this.get('resultTopMargin') || defaultResultTopMargin, 10);

      // By default jump down the content so that the animation goes for no more than one container's length
      var defaultPixelsToJumpToWithin = containerHeight;
      var pixelsToJumpToWithin = parseInt(this.get('pixelsToJumpToWithin') || defaultPixelsToJumpToWithin, 10);

      var scrollDestination = Math.ceil(this.$().offset().top) - (offset + resultTopMargin);

      // Adjust for testing wrapper
      if (Ember.testing) {
        scrollDestination -= 120;
      }

      if (scrollStyle === animatedScrollStyle) {
        animateScroll($container, scrollDestination, scrollDuration, scrollEasing, pixelsToJumpToWithin);
      } else if (scrollStyle === jumpScrollStyle) {
        Ember.$(container).scrollTop(scrollDestination);
      } else {
        throw new Error('scroll-to component configured with unsupported scrollStyle');
      }

      var emphasisSelector = this.get('emphasisSelector');
      if (emphasisSelector) {
        Ember.$(emphasisSelector).parent().addClass('article-in-context-highlight');
      }
    }
  });
});