define('browzine-web/mixins/ember-data-workarounds', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service(),

    /**
     * ensures that if a model is already in the
     * Ember Data store that it has its belongsTo
     * relationship to the selected library
     *
     * We need to do this because our adapters construct API
     * urls based upon the belongsTo relationship and in the case
     * where a model isn't already in the store this gets set
     * by passing the library as a preload option to the store's
     * find method.  This however does nothing if the model
     * is already present in the store.
     */
    relateCachedModelToLibrary: function relateCachedModelToLibrary(modelType, modelId) {
      var cachedModel = this.store.peekRecord(modelType, modelId);

      if (cachedModel && !cachedModel.belongsTo('library').value()) {
        cachedModel.belongsTo('library').push(this.modelFor('library'));
      }
    }
  });
});