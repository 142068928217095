define('browzine-web/routes/library/vpn-required', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    header: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    intl: Ember.inject.service(),

    model: function model(params, transition) {
      return {
        context: transition.queryParams.context,
        library: this.modelFor('library')
      };
    },
    titleToken: function titleToken() {
      this.get('header').setProperties({
        subject: {
          value: 'vpn_required.responsive_page_subject',
          template: true
        },
        title: {
          value: 'vpn_required.responsive_page_title',
          template: true
        }
      });

      return this.get('intl').t('vpn_required.responsive_page_title');
    },


    actions: {
      selectLibrary: function selectLibrary() {
        // User decided to select a different library instead.
        // Clear the saved transition
        this.get('applicationSession').set('transitionToRetryAfterAuth', undefined);
        this.transitionTo('libraries');
      },
      continue: function _continue() {
        // The user should now be connected to their VPN, retry the original
        // transition.  This time it should manage to get an auth token
        // and proceed to the originally requested function
        var originalTransition = this.get('applicationSession').get('transitionToRetryAfterAuth');
        originalTransition.retry();
      }
    }
  });
});