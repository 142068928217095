define('browzine-web/components/my-journal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['journal', 'bookshelf-journal'],
    classNameBindings: ['unavailable', 'moving', 'isPending:pending'],

    myBookshelf: Ember.inject.service(),

    unread: Ember.computed.alias('my-journal.unreadCount'),

    unavailable: Ember.computed.alias('my-journal.unavailable'),
    isPending: Ember.computed.alias('my-journal.isPending'),

    moving: Ember.computed.alias('my-journal.isMoving'),

    journal: Ember.computed.alias('my-journal.journal'),

    actions: {
      visitUnavailableJournal: function visitUnavailableJournal() {
        this.get('flashMessages').alert('This journal is currently unavailable at this library. Please contact your librarian for more information.');
      }
    }
  });
});