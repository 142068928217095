define('browzine-web/components/unread-badge/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'unread-badge',
    maximum: 999,
    aboveMaximum: Ember.computed.gt('count', 999),
    singular: Ember.computed.equal('count', 1),
    align: 'left'
  });
});