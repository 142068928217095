define('browzine-web/routes/token-failure', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      this.get('flashMessages').alert('Library authentication failed');
      this.transitionTo('libraries', { queryParams: { selectedLibrary: params.library_id } });
    }
  });
});