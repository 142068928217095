define('browzine-web/components/intro-screen/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    errorReporter: Ember.inject.service(),

    classNames: ['intro-screen'],

    actions: {
      // selectOpenAccessLibrary is injected by route that uses this component
      // Until routable components actually land, you can't inject an action from
      // a route, so we relay the action generated by the open access button
      // to the route here using sendAction
      selectOpenAccessLibrary: function selectOpenAccessLibrary() {
        this.sendAction('selectOpenAccessLibrary');
      }
    }
  });
});