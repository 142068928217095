define("browzine-web/components/article-loading-unavailable-article-help/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      gotoILL: function gotoILL(article) {
        window.location.assign(article.get("ILLURL"));
      }
    }
  });
});