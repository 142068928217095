define('browzine-web/components/help-link/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    errorReporter: Ember.inject.service(),

    label: 'Visit Third Iron Support',
    icon: '',

    didInsertElement: function didInsertElement() {
      var errorDetails = this.get('errorDetails');

      if (errorDetails) {
        this.get('errorReporter').reportHelpError(errorDetails);
      }
    },


    linkTrackingSelector: Ember.computed(function () {
      return '#' + this.elementId + ' > a';
    }),

    currentUrl: Ember.computed(function () {
      return window.location.href;
    })
  });
});