define('browzine-web/routes/library/unavailable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    header: Ember.inject.service(),
    intl: Ember.inject.service(),

    model: function model(params, transition) {
      return {
        context: transition.queryParams.context,
        library: this.modelFor('library')
      };
    },
    titleToken: function titleToken() {
      this.get('header').setProperties({
        subject: {
          value: 'unavailable.responsive_page_subject',
          template: true
        },
        title: {
          value: 'unavailable.responsive_page_title',
          template: true
        }
      });

      return this.get('intl').t('unavailable.responsive_page_title');
    },


    actions: {
      selectLibrary: function selectLibrary() {
        this.transitionTo('libraries');
      }
    }
  });
});