define('browzine-web/services/ajax', ['exports', 'ember-ajax/services/ajax', 'browzine-web/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    auth: Ember.inject.service(),
    applicationSession: Ember.inject.service(),

    namespace: _environment.default.apiNamespace,
    host: _environment.default.apiHost,

    headers: Ember.computed('session.authToken', {
      get: function get() {
        var headers = {};

        var selectedLibraryId = this.get('applicationSession').get('selectedLibrary');
        if (selectedLibraryId) {
          var authHeader = this.get('auth').getAPIAuthHeaderForLibrary(selectedLibraryId);
          if (authHeader) {
            headers['Authorization'] = authHeader;
          }
        }

        return headers;
      }
    })
  });
});