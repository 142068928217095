define('browzine-web/models/my-bookshelves', ['exports', 'browzine-web/mixins/journal-container'], function (exports, _journalContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BOOKCASES = 4;

  exports.default = Ember.Object.extend(_journalContainer.default, {
    children: Ember.computed.alias('bookcases'),

    init: function init() {
      var bookcases = [];
      for (var i = 0; i < BOOKCASES; i++) {
        var owner = Ember.getOwner(this);
        bookcases.push(owner.resolveRegistration('model:my-bookcase').create({
          index: i + 1
        }));
      }

      bookcases.forEach(function (bookcase, index) {
        var nextIndex = (index + 1) % BOOKCASES;
        var previousIndex = (BOOKCASES + index - 1) % BOOKCASES;

        bookcase.set('next', bookcases.objectAt(nextIndex));
        bookcase.set('previous', bookcases.objectAt(previousIndex));
      });

      this.set('bookcases', bookcases);
    },
    getBookcase: function getBookcase(index) {
      var parsedIndex = parseInt(index, 10);
      return this.get('bookcases').objectAt(parsedIndex - 1);
    },


    syncData: Ember.computed('bookcases.@each.syncData', function () {
      return {
        $schema: this.get('schema'),
        _id: 'my_bookshelf',
        bookcases: this.get('bookcases').mapBy('syncData'),
        type: this.get('type')
      };
    })
  });
});