define('browzine-web/services/exporters/zotero', ['exports', 'browzine-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    name: 'Zotero',

    share: function share(library, article) {
      var _this = this;

      var popup = window.open("https://www.zotero.org/oauth/authorize?oauth_token=&write_access=1&library_access=1", "Zotero Authorization", "width=800,height=800");
      var callback = window.location.protocol + "//" + window.location.host + "/oauth?article_id=" + article.id + "&service=zotero";

      Ember.$.ajax({
        url: _environment.default.apiHost + "/oauth/zotero/request-token?callback_url=" + encodeURIComponent(callback),
        type: "POST",
        success: function success(response) {
          localStorage.setItem("zotero_temp_oauth_token", response.oauth_token);
          localStorage.setItem("zotero_temp_oauth_token_secret", response.oauth_token_secret);

          popup.focus();
          popup.location.assign("https://www.zotero.org/oauth/authorize?oauth_token=" + response.oauth_token + '&write_access=1&library_access=1');
        },
        error: function error(response) {
          _this.get('flashMessages').alert("Error getting Zotero share");
          _this.get('errorReporter').reportFailedArticleShare(response);
        }
      });
    },
    token: function token(params, cb) {
      var _this2 = this;

      params.oauth_token = localStorage.getItem("zotero_temp_oauth_token");
      params.oauth_token_secret = localStorage.getItem("zotero_temp_oauth_token_secret");

      Ember.$.ajax({
        url: _environment.default.apiHost + "/oauth/zotero/access-token?" + Ember.$.param(params),
        type: "POST",
        success: function success(response) {
          localStorage.setItem('zotero_oauth_token_secret', response.oauth_token_secret);
          localStorage.setItem('zotero_user_id', response.userID);

          cb();
        },
        error: function error(response) {
          _this2.get('flashMessages').alert("Error getting Zotero token");
          _this2.get('errorReporter').reportFailedArticleShare(response);
        }
      });
    },
    getSaveParams: function getSaveParams() {
      return {
        userId: localStorage.getItem('zotero_user_id'),
        secretToken: localStorage.getItem('zotero_oauth_token_secret')
      };
    },
    hasCredentials: function hasCredentials() {
      var userId = localStorage.getItem('zotero_user_id');
      var tokenSecret = localStorage.getItem('zotero_oauth_token_secret');

      return userId && tokenSecret;
    }
  });
});