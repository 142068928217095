define('browzine-web/mixins/journal-container', ['exports', 'browzine-web/utils/lodash-replacements'], function (exports, _lodashReplacements) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isFull: Ember.computed('children.@each.isFull', function () {
      var bookshelves = this.get('children');
      return Ember.A(bookshelves).isEvery('isFull', true);
    }),

    hasJournal: function hasJournal(journal) {
      return Ember.A(this.get('children')).any(function (bookshelf) {
        return bookshelf.hasJournal(journal);
      });
    },
    addJournal: function addJournal(journal) {
      var notFullChild = this.get('children').findBy('isFull', false);
      notFullChild.addJournal(journal);
    },


    childUnreadCounts: Ember.computed('children.@each.unreadCount', function () {
      return this.get('children').mapBy('unreadCount');
    }),

    unreadCount: Ember.computed.sum('childUnreadCounts'),

    journalIds: Ember.computed('children.@each.journalIds', function () {
      return (0, _lodashReplacements.flatten)(this.get('children').mapBy('journalIds'));
    })
  });
});