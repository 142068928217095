define('browzine-web/routes/library/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myBookshelf: Ember.inject.service(),

    afterModel: function afterModel(model, transition) {
      var returnRoute = transition.queryParams.returnRoute;
      var returnParameter = transition.queryParams.returnParameter;

      var library = this.modelFor('library');
      if (library.get('ssoEnabled')) {
        if (!!returnRoute) {
          // To integrate with legacy code, the sso-login route looks for the returnUrl
          // in the returnRoute property of the myBookshelf service.  However not all client
          // code sets returnUrl up this way.  Some of it passes a route name and parameter
          // on the querystring.  When that happens set myBookshelf service property here
          var returnUrl = this.get('router._routerMicrolib').generate(returnRoute, returnParameter);
          this.set('myBookshelf.returnRoute', returnUrl);
        }
        this.transitionTo('library.sso-login', library);
      } else {

        this.transitionTo('auth.login', { queryParams: { returnRoute: returnRoute, returnParameter: returnParameter } });
      }
    }
  });
});