define('browzine-web/initializers/activity-tracking', ['exports', 'browzine-web/errors/tracing', 'browzine-web/config/environment'], function (exports, _tracing, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  // activity-tracking... inspired by https://github.com/ember-insights/ember-insights
  // monkey patches the Ember router and the ActionHandler mixin
  // to generate traces that list all transitions made by the app
  // and all actions sent so they may be sent up to the error reporter
  // for better insight into how error states are reached

  function initialize() /* application */{
    if (!_environment.default.recordUserTrace) {
      return;
    }

    Ember.ActionHandler.reopen({
      send: function send(actionName) {
        for (var _len = arguments.length, actionArguments = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          actionArguments[_key - 1] = arguments[_key];
        }

        var appController = Ember.getOwner(this).lookup('controller:application');
        var routeName = appController.get('currentRouteName');
        var route = Ember.getOwner(this).lookup('route:' + routeName);
        var url = Ember.getOwner(this).lookup('router:main').get('url');

        (0, _tracing.addActionToTrace)(actionName, actionArguments, route, routeName, url);
        this._super.apply(this, arguments);
      }
    });
    // start catching transitions
    Ember.Router.reopen({
      didTransition: function didTransition() {
        var _this = this;

        var appController = Ember.getOwner(this).lookup('controller:application');
        var prevRouteName = appController.get('currentRouteName');
        var prevUrl = prevRouteName ? this.get('url') : '';
        var newRouteName = arguments[0][arguments[0].length - 1].name;

        Ember.run.schedule('afterRender', this, function () {
          (0, _tracing.addTransitionToTrace)(newRouteName, prevRouteName, _this.get('url') || '/', prevUrl);
        });

        this._super.apply(this, arguments);
      }
    });
  }

  exports.default = { initialize: initialize };
});