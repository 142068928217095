define('browzine-web/mixins/accordion-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    attributeBindings: ['tabindex'],

    tabindex: 0,

    isExpanded: false,

    keyPress: function keyPress(e) {
      if (e.keyCode === 13 && !this.get('isExpanded')) {
        this.send('expand');
      }
    },


    expandedChanged: Ember.observer('expandedItem', function () {
      var expanded = this.get('expandedItem');

      if (expanded !== this) {
        this.send('contract', true);
      }
    }),

    shouldExpand: function shouldExpand() {
      return true;
    },


    actions: {
      toggleExpansion: function toggleExpansion(external) {
        if (this.get('isExpanded')) {
          this.send('contract', external);
        } else {
          this.send('expand');
        }
      },
      expand: function expand() {
        if (this.get('contracting')) {
          this.set('contracting', false);
        } else {
          if (this.shouldExpand()) {
            this.set('isExpanded', true);
          }

          this.sendAction('expanded', this);
        }
      },
      contract: function contract(external) {
        this.set('isExpanded', false);

        if (!external) {
          this.set('contracting', true);
        }
      }
    }
  });
});