define("browzine-web/utils/lodash-replacements", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.flatten = flatten;
  exports.isUndefined = isUndefined;
  exports.pick = pick;
  function flatten(arr) {
    return arr.reduce(function (a, b) {
      return a.concat(b);
    }, []);
  }

  /**
   * Checks if `value` is undefined.
   * @param {*} value The param to check if it is undefined
   */
  function isUndefined(value) {
    return value === undefined;
  }

  function pick(object, keys) {
    return keys.reduce(function (obj, key) {
      if (object && object.hasOwnProperty(key)) {
        obj[key] = object[key];
      }
      return obj;
    }, {});
  }
});