define('browzine-web/routes/my-bookshelf/bookcase', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    applicationSession: Ember.inject.service(),

    model: function model(params) {
      var loggedInUser = this.get('applicationSession').get('loggedInUser');

      if (loggedInUser) {
        localStorage.setItem('my-bookcase', params.bookcase_index);
        return this.modelFor('my-bookshelf').getBookcase(params.bookcase_index);
      } else {
        this.transitionTo('my-bookshelf');
      }
    },
    titleToken: function titleToken(model) {
      return Ember.get(model, 'title');
    }
  });
});