define('browzine-web/instance-initializers/announce-flash-messages', ['exports', 'ember-cli-flash/flash/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var announcer = application.lookup('service:announcer');

    _object.default.reopen({
      announce: Ember.on('init', function () {
        var message = this.get('type') + ': ' + this.get('message');

        announcer.announce(message, 'assertive');
      })
    });
  }

  exports.default = {
    name: 'announce-flash-messages',
    initialize: initialize
  };
});