define('browzine-web/components/language-chooser/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    intl: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    i18n: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('languageChooserModal', {});
    },


    languages: Ember.computed(function () {
      var languagesSupported = this.get('library.languagesSupported');
      return languagesSupported.split(",");
    }),

    abbreviation: Ember.computed(function () {
      var locale = this.get('intl.locale');

      if (locale) {
        return locale[0].slice(0, 2).toUpperCase();
      } else {
        return 'EN';
      }
    }),

    actions: {
      chooseLanguage: function chooseLanguage() {
        var languageChooserModal = this.get('languageChooserModal');
        languageChooserModal.show();
        this.get('closeMenu')();
      },
      selectLanguage: function selectLanguage(locale) {
        this.get('applicationSession').set('localeOverride', locale);
        this.get('intl').setLocale(locale);

        var abbreviation = locale.slice(0, 2).toUpperCase();
        this.set('abbreviation', abbreviation);
        this.sendAction('refreshCurrentRoute');
      }
    }
  });
});