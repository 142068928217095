define('browzine-web/routes/libraries/images', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    renderTemplate: function renderTemplate() {
      this.render('libraries.images', { into: 'application' });
    }
  });
});