define('browzine-web/router', ['exports', 'browzine-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {

    if (_environment.default.environment === 'test') {
      this.route('refresh-collection-in-store-from-pouch', { path: '/test-helper/refresh-collection-in-store-from-pouch' });
    }

    this.route('libraries', function () {
      this.route('images');
    });

    this.route('oauth');

    this.route('auth', function () {
      this.route('login');
      this.route('signup');
      this.route('reset');
      this.route('bps');
    });

    this.route('confirm-email', { path: '/confirm-email/:token' });
    this.route('token-failure', { path: '/token-failure/:library_id' });
    this.route('article-full-text-file', { path: 'libraries/:library_id/articles/:article_id/full-text-file' });

    this.route('accept-token', { path: 'libraries/:library_id/accept-token' });

    this.route('libraryless-settings', { path: '/settings' });

    this.route('library', { path: 'libraries/:library_id' }, function () {
      this.route('login');
      this.route('sso-login');
      this.route('user-idle-session-expired');

      this.route('my-articles', function () {
        this.route('index', { path: '/' });
        this.route('collection', { path: ':collection_id' });
      });
      this.route('app-promotion');

      this.route('my-bookshelf', { resetNamespace: true }, function () {
        this.route('bookcase', { path: ':bookcase_index' });
      });

      this.route('settings', function () {
        this.route('user-settings', { path: '/' });
        this.route('library-customization', { path: '/library' });
      });

      this.route('journal', { path: 'journals/:journal_id' }, function () {
        this.route('issue', { path: 'issues/:issue_id' }, function () {});
        this.route('current-issue', { path: 'issues/current' });
        this.route('latest-full-text-issue', { path: 'issues/latest-full-text' });
        this.route('articles-in-press', { path: 'articles-in-press' });
      });

      this.route('subjects');

      this.route('subject', { path: 'subjects/:subject_id' }, function () {
        this.route('bookcase', { path: 'bookcases/:bookcase_id' }, function () {
          this.route('bookshelf', { path: 'bookshelves/:bookshelf_id' });
        });
      });

      this.route('unavailable');
      this.route('vpn-required');

      this.route('article', { path: 'articles/:article_id' });
      this.route('article-download', { path: 'articles/:article_id/pdf' });
      this.route('article-retraction-notice', { path: 'articles/:article_id/retraction-notice' });
      this.route('article-eoc-notice', { path: 'articles/:article_id/eoc-notice' });
    });

    // Shows article in BZ Web
    this.route('doi-lookup', { path: 'articles/doi/*doi' });

    // Redirects to article after resolving library information
    this.route('doi-redirect', { path: 'doi/*doi' });

    // Shareable article link that sends user to wayf if no library is selected
    this.route('article', { path: 'articles/:article_id' });
    // Shareable issue link that sends user to wayf if no library is selected.
    // Is used in notification emails
    this.route('issue', { path: '/journals/:journal_id/issues/:issue_id' });
    this.route('article-download', { path: 'articles/:article_id/pdf' });
    // Generic WAYF with no preview that can redirect to anything
    this.route('wayf', { path: 'wayf' });
    // WAYF with an article preview
    this.route('article-wayf', { path: 'wayf/:article_id' });
    // WAYF with an issue preview
    this.route('issue-wayf', { path: 'issue-wayf/:issue_id' });

    this.route('unknown', { path: '*path' });
  });

  exports.default = Router;
});