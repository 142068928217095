define('browzine-web/components/library-list-item/component', ['exports', 'browzine-web/mixins/accordion-item', 'browzine-web/mixins/trigger-on-enter'], function (exports, _accordionItem, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_accordionItem.default, _triggerOnEnter.default, {
    classNames: ['library-list-item'],
    tagName: 'li',

    // This is set by library-list, indicates authentication in progress
    selecting: false,

    classNameBindings: ['selecting'],

    shouldExpand: function shouldExpand() {
      // TODO: BZ-4086
      // No meaningful default for login info?  If a library is configured to have VPN, but doesn't
      // get set up with a webLoginInfo value, the user isn't presented with any useful
      // feedback if they click the authenticate button and they're not attached to
      // the organization's VPN.
      // It seems like this should be keyed off of whether the library has VPN or some other situation
      // that would require informing the user (like perhaps secured libraries that do not offer
      // off-site access)
      return this.get('library.webLoginInfo');
    },


    authenticateButtonLabel: Ember.computed('library.webLoginInfo.continueLabel', function () {
      var label = this.get('library.webLoginInfo.continueLabel');

      if (Ember.isBlank(label)) {
        return 'Authenticate Library';
      } else {
        return label;
      }
    }),

    isExpandedOrSelected: Ember.computed('isExpanded', 'selected', function () {
      return this.get('isExpanded') || this.get('selected') && this.shouldExpand();
    }),

    click: function click() {
      //this.send('collapsedItemClicked');
    },


    actions: {
      collapsedItemClicked: function collapsedItemClicked() {
        if (this.shouldExpand()) {
          this.send('expand');
        } else {
          this.sendAction('viewLibrary', this.get('library'));
        }
      },
      viewLibrary: function viewLibrary(library) {
        this.sendAction('viewLibrary', library);
      }
    }
  });
});