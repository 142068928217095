define('browzine-web/models/issue', ['exports', 'ember-data', 'ember-data-has-many-query'], function (exports, _emberData, _emberDataHasManyQuery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberDataHasManyQuery.default.ModelMixin, {
    library: _emberData.default.belongsTo('library', { async: true }),
    journal: _emberData.default.belongsTo('journal', { async: true, inverse: 'issues' }),

    title: _emberData.default.attr('string'),
    shortTitle: Ember.computed('title', function () {
      var title = this.get('title');
      return title.replace('Vol.', 'Vol');
    }),

    // This is a `string` rather than a `date` to bypass the default parsing that
    // assumes UTC. Could instead be solved with an overridden serialiser.
    date: _emberData.default.attr('string'),

    year: Ember.computed('date', function () {
      var date = this.get('date');
      return date.substr(0, 4);
    }),

    // If an issue is unavailable at the library
    // the back end provides a message why in this property
    availabilityMessage: _emberData.default.attr('string'),

    withinSubscription: _emberData.default.attr('boolean'),
    embargoed: _emberData.default.attr('boolean'),
    suppressed: _emberData.default.attr('boolean'),

    articles: _emberData.default.hasMany('article', { async: true }),
    isValidIssue: _emberData.default.attr('boolean')
  });
});