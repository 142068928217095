define('browzine-web/services/application-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var userDataKey = 'browzine-logged-in-user-data';
  var browserIdKey = 'browzine-browser-identifier';
  var libraryTokenKeyPrefix = 'browzine-library-token-';
  var localeOverrideKey = 'libkey-locale-override';

  function getStorageKeyForLibraryToken(libraryId) {
    return '' + libraryTokenKeyPrefix + libraryId;
  }

  // Generates the configuration to be passed to the
  // Ember computed helper in order to create a computed
  // property that holds an object value which is stored
  // in local storage as JSON
  function localStorageJSONProperty(key) {
    return {
      get: function get() {
        if (!localStorage.getItem(key)) {
          return undefined;
        }
        return JSON.parse(localStorage.getItem(key));
      },
      set: function set(_, value) {
        if (!value) {
          localStorage.removeItem(key);
          return value;
        }

        localStorage.setItem(key, JSON.stringify(value));
        return value;
      }
    };
  }

  // Courtesy of https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  function generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
    });
  }

  /*
   * An abstraction around
   * application state that's
   * persisted on the client
   */
  exports.default = Ember.Service.extend({

    transitionToRetryAfterAuth: null,

    selectedLibrary: null,

    mostRecentLibrary: Ember.computed({
      get: function get() {
        return localStorage.getItem('BZ_mostRecentLibrary');
      },
      set: function set(key, value) {
        localStorage.setItem('BZ_mostRecentLibrary', value.toString());
        return value.toString();
      }
    }),

    localeOverride: Ember.computed(localStorageJSONProperty(localeOverrideKey)),

    clearSelectedLibrary: function clearSelectedLibrary() {
      this.set('selectedLibrary', undefined);
    },


    loggedInUser: Ember.computed(localStorageJSONProperty(userDataKey)),

    getBrowserIdentifier: function getBrowserIdentifier() {
      var browserId = localStorage.getItem(browserIdKey);

      if (browserId) {
        return browserId;
      } else {
        var newId = generateUUID();

        localStorage.setItem(browserIdKey, newId);
        return newId;
      }
    },


    userIsLoggedIn: Ember.computed('loggedInUser', function () {
      return !!this.get('loggedInUser');
    }),

    setLoggedInUser: function setLoggedInUser(userAuthInfo) {
      this.set('loggedInUser', userAuthInfo);
    },
    clearLoggedInUser: function clearLoggedInUser() {
      this.set('loggedInUser', null);
      this.notifyPropertyChange('loggedInUser');
    },
    lookupLibraryToken: function lookupLibraryToken(libraryId) {
      var bzwebLibraryTokenKey = getStorageKeyForLibraryToken(libraryId);

      var storedValue = localStorage.getItem(bzwebLibraryTokenKey);

      if (!storedValue) {
        return undefined;
      }

      return JSON.parse(storedValue);
    },


    /***
     * libraryId: the library associated with the token getting stored
     * tokenObject: {
     *   token: token value
     *   expires_at: UTC timestamp when token expires
     * }
     */
    storeTokenForLibrary: function storeTokenForLibrary(libraryId, tokenObject) {
      var bzwebLibraryTokenKey = getStorageKeyForLibraryToken(libraryId);

      localStorage.setItem(bzwebLibraryTokenKey, JSON.stringify(tokenObject));

      localStorage.setItem('BZ_mostRecentLibrary', libraryId);
    },
    clearLibraryToken: function clearLibraryToken(libraryId) {
      var bzwebLibraryTokenKey = getStorageKeyForLibraryToken(libraryId);

      localStorage.removeItem(bzwebLibraryTokenKey);
    },


    /**
     * Seems unlikely to be used in production, but useful during testing
     * to initialize to a clean slate
     */
    clearAllLibraryTokens: function clearAllLibraryTokens() {
      var keysToRemove = [];
      for (var i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).indexOf(libraryTokenKeyPrefix) !== -1) {
          keysToRemove.push(localStorage.key(i));
        }
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = keysToRemove[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var key = _step.value;

          localStorage.removeItem(key);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },


    currentIssueNeedsReload: false

  });
});