define('browzine-web/controllers/library/my-articles/collection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    libraryController: Ember.inject.controller('library'),
    library: Ember.computed.alias('libraryController.model')
  });
});