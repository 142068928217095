define('browzine-web/components/user-login/component', ['exports', 'browzine-web/mixins/error-helpers', 'ember-intl'], function (exports, _errorHelpers, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHelpers.default, {
    classNames: ['user-login'],

    auth: Ember.inject.service(),
    errorReporter: Ember.inject.service('error-reporter'),
    intl: Ember.inject.service(),

    inProgress: false,

    // Properties provided by template that uses component:
    deviceId: null,
    userToken: null,

    // Properties that get their values from controls in the
    // component:
    email: null,
    password: null,

    emailInputAriaLabel: (0, _emberIntl.t)('auth.login_email_input_aria_label'),
    passwordInputLabel: (0, _emberIntl.t)('auth.login_password_input_label'),
    forgotPasswordLinkText: (0, _emberIntl.t)('auth.login_forgot_password_link_text'),
    forgotPasswordLinkAriaLabel: (0, _emberIntl.t)('auth.login_forgot_password_link_aria_label'),
    submitButtonAriaLabel: (0, _emberIntl.intl)('inProgress', function (intl) {
      if (this.get('inProgress')) {
        return intl.t('auth.login_submit_button_aria_label_in_progress');
      } else {
        return intl.t('auth.login_submit_button_aria_label');
      }
    }),
    noAccountMessageSignUpLinkText: (0, _emberIntl.t)('auth.login_no_account_sign_up_link_text'),
    noAccountMessageSignUpLinkAriaLabel: (0, _emberIntl.t)('auth.login_no_account_sign_up_link_aria_label'),

    init: function init() {
      this._super.apply(this, arguments);
      // Why an action here?  Does it have to be an action?
      // Does this defer the timing of the code in the action
      // so that it can work with things that aren't available
      // yet in the init hook?  It's hard to tell because the
      // Ember API docs don't provide any description for what
      // is appropriate during the init hook and what isn't.
      //
      // Perhaps this can be simplified to a simple function
      // call, but I'm punting on investigating that.
      this.send('authenticateExistingUser');
    },
    _performLoginWithToken: function _performLoginWithToken(existingUserToken) {
      var _this = this;

      this.set('error', null);
      this.set('inProgress', true);
      return this.get('auth').authenticateWithUserToken(existingUserToken).then(function () {
        return _this.sendAction('onLoggedIn');
      }).catch(function (err) {
        var error = _this.getErrorObjectFromErrorResponse(err);
        console.log('Error while attempting to login with token: ' + error.userDetail);
        _this.set('error', _this.get('intl').t('auth.login_form_token_generic_error_message'));
      }).finally(function () {
        return _this.set('inProgress', false);
      });
    },
    _performLoginWithCredentials: function _performLoginWithCredentials(email, password, deviceId) {
      var _this2 = this;

      var _getProperties = this.getProperties('errorReporter', 'auth'),
          errorReporter = _getProperties.errorReporter,
          auth = _getProperties.auth;

      this.set('error', null);
      this.set('inProgress', true);

      return auth.authenticateUser(email, password).then(function () {
        return _this2.sendAction('onLoggedIn');
      }).catch(function (err) {
        var error = _this2.getErrorObjectFromErrorResponse(err);
        if (error.userData && error.userData.confirmationRequired) {

          _this2.set('error', _this2.get('intl').t('auth.login_form_needs_confirmation_error_message', { email: email }));

          return auth.signUpUser(email, password, deviceId).then(function (signup) {
            return auth.awaitSignupConfirmationAndAuthenticate(signup.id, email, password);
          }).then(function () {
            return _this2.sendAction('onLoggedIn');
          });
        } else if (error.userData && error.userData.code === 'bad_credentials') {
          _this2.set('error', _this2.get('intl').t('auth.login_form_bad_credentials_error_message'));
        } else {
          _this2.set('error', _this2.get('intl').t('auth.login_form_credentials_generic_error_message'));
          errorReporter.reportUnknownError('error authenticating ' + email, error);
        }
      }).finally(function () {
        return _this2.set('inProgress', false);
      });
    },


    actions: {
      authenticateExistingUser: function authenticateExistingUser() {
        var userToken = this.get('userToken');

        if (userToken) {
          return this._performLoginWithToken(userToken);
        }
      },
      performLogin: function performLogin() {
        var email = this.get('email'),
            password = this.get('password'),
            deviceId = this.get('deviceId');

        return this._performLoginWithCredentials(email, password, deviceId);
      }
    }
  });
});