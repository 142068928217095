define('browzine-web/services/sorting', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    sortCollections: function sortCollections(a, b) {
      if (a.get('id') === 'collection-general') {
        return -1;
      } else if (b.get('id') === 'collection-general') {
        return 1;
      }

      if (a.get('name').toUpperCase() < b.get('name').toUpperCase()) {
        return -1;
      } else {
        return 1;
      }
    }
  });
});