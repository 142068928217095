define('browzine-web/errors/AuthRequiredError', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = AuthRequiredError;


  /***
   * An AuthRequiredError is used by our data adapters
   * to signal that the API is rejecting requests
   * because the client is not sending over any
   * valid authentication credential.
   *
   * @class AuthRequiredError
   */

  function AuthRequiredError(payload) {
    Ember.Error.call(this, 'The API reported that an authentication credential is required');
    this.payload = payload;
  }

  AuthRequiredError.prototype = Object.create(Ember.Error.prototype);
});