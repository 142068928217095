define('browzine-web/controllers/my-bookshelf', ['exports', 'ember-intl'], function (exports, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    myBookshelf: Ember.inject.service(),
    router: Ember.inject.service('router'),
    intl: Ember.inject.service(),

    libraryController: Ember.inject.controller('library'),
    library: Ember.computed.alias('libraryController.model'),

    preLoginLoginLinkText: (0, _emberIntl.t)('my_bookshelf.pre_login_login_link_text'),
    preLoginSignupLinkText: (0, _emberIntl.t)('my_bookshelf.pre_login_signup_link_text'),
    primaryLocale: Ember.computed.alias('intl.primaryLocale')

  });
});