define('browzine-web/routes/library/journal/current-issue', ['exports', 'browzine-web/routes/library/journal/issue', 'browzine-web/mixins/query-storing-route'], function (exports, _issue, _queryStoringRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _issue.default.extend(_queryStoringRoute.default, {
    header: Ember.inject.service(),
    intl: Ember.inject.service(),
    applicationSession: Ember.inject.service(),

    model: function model() {
      var journal = this.modelFor('library.journal');

      var currentIssueNeedsReload = this.get('applicationSession').get('localizedModelsNeedReload');
      var loadCurrentIssue = journal.fixedBelongsToLoad('currentIssue', currentIssueNeedsReload);
      return loadCurrentIssue;
    },


    controllerName: 'library.journal.issue',
    templateName: 'library.journal.issue',

    titleToken: function titleToken(model) {
      this.get('header').setProperties({
        subject: {
          value: 'journal.responsive.header',
          template: true
        },
        title: {
          value: model.get('journal.title'),
          template: false
        }
      });

      return this.get('intl').t('journal.current_issue');
    }
  });
});