define('browzine-web/routes/refresh-collection-in-store-from-pouch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sync: Ember.inject.service(),

    beforeModel: function beforeModel() {
      this.get('sync')._TEST_HELPER_refreshStore();

      return this.transitionTo('/');
    }
  });
});