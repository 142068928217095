define('browzine-web/components/copy-link-to-article-modal/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    classNames: ['copy-link-to-article-modal'],

    clipboard: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var modal = this.get('modal');

      Ember.run.next(function () {
        modal.element = _this.element;
        modal.element.id = _this.element.id;
      });

      modal.ok = function () {
        _this.ok();
      };
    },


    actions: {
      ok: function ok() {
        var modal = this.get('modal');
        modal.hide();
      },
      copyToClipboard: function copyToClipboard() {
        var clipboard = this.get('clipboard');
        var browzineWebInContextLink = this.get('article.browzineWebInContextLink');
        var textarea = this.$('.article-in-context-link')[0];
        clipboard.copy(browzineWebInContextLink, textarea);
        this.send('ok');
      }
    }
  });
});