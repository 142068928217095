define('browzine-web/helpers/filtered-list-entries', ['exports', 'diacritics'], function (exports, _diacritics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.filteredListEntries = filteredListEntries;
  function filteredListEntries(params /*, hash*/) {
    var listEntries = params[0];
    var search = params[1];

    if (Ember.isEmpty(search)) {
      return listEntries;
    }

    search = _diacritics.default.remove(search).toLowerCase();

    var filteredEntries = listEntries.filter(function (entry) {
      var name = entry.entryName || '';

      name = _diacritics.default.remove(name).toLowerCase();

      return name.indexOf(search) > -1;
    });

    return filteredEntries;
  }

  exports.default = Ember.Helper.helper(filteredListEntries);
});