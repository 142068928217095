define('browzine-web/models/my-bookshelf', ['exports', 'browzine-web/mixins/journal-container'], function (exports, _journalContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_journalContainer.default, {
    children: Ember.computed.alias('journals'),

    isFull: Ember.computed.gte('notMovingJournals.length', 4),
    notMovingJournals: Ember.computed.filterBy('journals', 'isMoving', false),

    addJournal: function addJournal(newJournal, followingJournal) {
      var journals = this.get('journals');

      if (followingJournal) {
        var targetIndex = journals.indexOf(followingJournal);
        journals.insertAt(targetIndex, newJournal);
      } else {
        journals.addObject(newJournal);
      }
    },
    deleteJournal: function deleteJournal(journal) {
      this.get('journals').removeObject(journal);
    },


    syncData: Ember.computed('title', 'journals.@each.syncData', function () {
      return {
        title: this.get('title'),
        items: this.get('journals').mapBy('syncData')
      };
    }),

    /**
     * This bizarre formulation avoids this warning:
     * WARNING: Dependent keys containing @each only work one level deep. You cannot use nested forms like todos.@each.owner.name or todos.@each.owner.@each.name. Please create an intermediary computed property.
     */
    myJournalJournals: Ember.computed.mapBy('journals', 'journal'),
    journalIds: Ember.computed.mapBy('myJournalJournals', 'id')

  });
});