define('browzine-web/helpers/list-entries-for-libraries', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.listEntriesForLibraries = listEntriesForLibraries;
  function listEntriesForLibraries(params /*, hash*/) {
    var libraries = params[0];

    var mappedLibraries = libraries.flatMap(function (l) {
      if (!l.get('aliases') || !l.get('aliases').length) {
        // If no aliases return just an entry for the library
        return {
          entryName: l.get('name'),
          model: l
        };
      } else {
        // if aliases return an entry for each alias and one for the library
        var aliasEntries = l.get('aliases').map(function (a) {
          return {
            entryName: a,
            entryAlias: a,
            model: l
          };
        });

        aliasEntries.push({
          entryName: l.get('name'),
          model: l
        });

        return aliasEntries;
      }
    });

    var sortedMappedLibraries = mappedLibraries.filter(function (entry) {
      // Guard against aliases of "" at least until that bug is fixed and the CMS no longer serves any of them
      return !!entry.entryName;
    }).sort(function (a, b) {
      return a.entryName.localeCompare(b.entryName);
    });
    return sortedMappedLibraries;
  }

  exports.default = Ember.Helper.helper(listEntriesForLibraries);
});