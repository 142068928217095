define('browzine-web/components/collection-list/component', ['exports', 'browzine-web/mixins/trigger-on-enter', 'ember-intl'], function (exports, _triggerOnEnter, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    classNameBindings: ['issue.embargoed', 'active:active-override', 'journal.accessedThroughAggregator'],

    store: Ember.inject.service(),
    myArticles: Ember.inject.service(),
    sorting: Ember.inject.service(),
    intl: Ember.inject.service(),

    removeCollectionModalTitle: (0, _emberIntl.t)('my_articles.remove_collection_modal_title'),
    removeCollectionModalMessage: (0, _emberIntl.t)('my_articles.remove_collection_modal_message'),

    init: function init() {
      this._super.apply(this, arguments);
      var store = this.get('store');
      var collections = store.peekAll('collection');
      this.set('collections', collections);

      this.set('collectionRemoveModal', {});
    },


    sortedCollections: Ember.computed.sort('collections.@each.{name}', function (a, b) {
      return this.get('sorting').get('sortCollections')(a, b);
    }),

    actions: {
      confirmRemoveCollection: function confirmRemoveCollection(collection) {
        var _this = this;

        var collectionRemoveModal = this.get('collectionRemoveModal');
        var collectionName = collection.get('name');

        collectionRemoveModal.removeCollection = function () {
          _this.send('removeCollection', collection);
        };

        collectionRemoveModal.show();

        var intl = this.get('intl');
        var line1 = intl.t('my_articles.other_remove_collection_modal_message_1', { collectionName: collectionName });
        var line2 = intl.t('my_articles.other_remove_collection_modal_message_2');

        //TODO: Figure out how to push the content to confirm-modal component
        //See, https://thirdiron.atlassian.net/browse/BZ-4297
        this.$('.bz-modal-content').html('\n        ' + line1 + '\n        <br><br>\n        ' + line2 + '\n      ');
      },
      removeCollection: function removeCollection(collection) {
        if (collection.get('id') !== 'collection-general') {
          collection.deleteRecord();
          this.get('myArticles').saveCollection(collection, 'removeCollection');
          this.get('router').transitionTo('library.my-articles.collection', 'collection-general');
        }
      },
      toggleCollections: function toggleCollections() {
        this.sendAction('toggleCollections');
      },
      createCollection: function createCollection() {
        this.sendAction('createCollection');
      }
    }
  });
});