define('browzine-web/routes/libraries', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    header: Ember.inject.service(),

    model: function model() {
      return this.store.findAll('library').then(function (libs) {
        return libs.filterBy('subscriptions.web.active');
      });
    },


    titleToken: function titleToken() {
      this.get('header').setProperties({
        subject: {
          value: 'Choose',
          template: false
        },
        title: {
          value: 'Library',
          template: false
        }
      });

      return "Choose Library";
    }
  });
});