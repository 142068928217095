define('browzine-web/routes/auth/signup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),
    myBookshelf: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this.set('deviceId', transition.queryParams['device-id']);

      transition.queryParams.returnRoute = transition.queryParams.returnRoute || this.get('myBookshelf.returnRoute');
      transition.queryParams.loginRequired = transition.queryParams.loginRequired || this.get('myBookshelf.loginRequired');

      this.set('addPasswordFor', transition.queryParams.addPasswordFor);
      this.set('loginRequired', transition.queryParams.loginRequired);
      this.set('returnRoute', transition.queryParams.returnRoute);
    },
    model: function model() {
      var _this = this;

      return Ember.RSVP.Promise.resolve().then(function () {
        return new Ember.RSVP.hash({
          email: _this.get('addPasswordFor'),
          addPassword: !!_this.get('addPasswordFor'),
          isBpsLibrary: _this.modelFor('auth').isBpsLibrary,
          loginRequired: _this.get('loginRequired'),
          returnRoute: _this.get('returnRoute'),
          deviceId: _this.get('deviceId')
        });
      });
    }
  });
});