define('browzine-web/models/journal', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    library: _emberData.default.belongsTo('library', { async: true }),
    title: _emberData.default.attr('string'),
    sortTitle: Ember.computed('title', function () {
      var title = this.get('title').toLowerCase();
      title = title.replace(/^(a\s+|an\s+|the\s+)/, '');
      title = title.replace(/&/g, 'and');
      return title;
    }),
    available: _emberData.default.attr('boolean'),

    rawCoverURL: _emberData.default.attr('string'),

    coverURL: Ember.computed('rawCoverURL', function () {
      var rawCoverURL = this.get('rawCoverURL') || '';
      return rawCoverURL.replace('http:', 'https:');
    }),

    issn: Ember.computed('coverURL', function () {
      var coverURL = this.get('coverURL') || '';
      var issn = coverURL.match(/[\S]{4}-[\S]{4}/i);
      return issn ? issn.toString() : '';
    }),

    scimagoRank: _emberData.default.attr('number'),
    scimagoURL: _emberData.default.attr('string'),
    homePageAtPublisherSite: _emberData.default.attr('string'),

    embargoDescription: _emberData.default.attr('string'),

    issues: _emberData.default.hasMany('issue', { async: true }),
    currentIssue: _emberData.default.belongsTo('issue', { async: true }),
    latestFullTextIssue: _emberData.default.belongsTo('issue', { async: true }),
    articlesInPress: _emberData.default.hasMany('article', { async: true }),
    publicationYears: _emberData.default.hasMany('publicationYear', { async: true }),

    // There isn't any endpoint to request all articles
    // This is only here so that the relationship from an individual article
    // can specify it as its inverse relation so ember data doesn't
    // get confused and decide articlesInPress is the inverse relation
    // for all articles
    allArticles: _emberData.default.hasMany('article', { async: true }),

    externalSearchLocation: _emberData.default.attr('string'),
    externalSearchLinkMessage: _emberData.default.attr('string'),

    accessedThroughAggregator: _emberData.default.attr('boolean'),
    articlesInPressAvailabilityMessage: _emberData.default.attr('string'),

    subjects: _emberData.default.hasMany('subjects', { async: true }),
    bookshelves: _emberData.default.hasMany('bookshelves', { async: true, inverse: 'journals' })
  });
});