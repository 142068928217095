define('browzine-web/mixins/localization-adapter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),

    ajaxOptions: function ajaxOptions() {
      var _this = this;

      var options = this._super.apply(this, arguments);
      var originalBeforeSend = options.beforeSend;

      options.beforeSend = function (xhr) {
        var locale = _this.get('intl.locale');

        xhr.setRequestHeader('Accept-Language', locale);

        if (originalBeforeSend) {
          originalBeforeSend(xhr);
        }
      };

      return options;
    }
  });
});