define('browzine-web/services/exporters/mendeley', ['exports', 'browzine-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    flashMessages: Ember.inject.service(),
    errorReporter: Ember.inject.service(),

    name: 'Mendeley',

    share: function share() {
      var callback = window.location.protocol + '//' + window.location.host + '/oauth?service=mendeley';
      var clientId = window.location.protocol.trim().toLowerCase() === 'https:' ? _environment.default.mendeleyClientIdHttps : _environment.default.mendeleyClientIdHttp;

      var options = {
        client_id: clientId,
        redirect_uri: callback,
        response_type: "code",
        scope: "all"
      };

      window.open("https://api.mendeley.com/oauth/authorize?" + Ember.$.param(options), "Mendeley Authorization", "width=800,height=800");
    },


    token: function token(params, cb) {
      var _this = this;

      Ember.$.ajax({
        url: _environment.default.apiHost + "/oauth/mendeley/token?" + Ember.$.param(params),
        type: "POST",
        success: function success(response) {
          var accessToken = response.access_token;
          var expiration = new Date().getTime() + response.expires_in * 1000;

          localStorage.setItem('mendeley_token', accessToken);
          localStorage.setItem('mendeley_expiration', expiration);

          cb();
        },
        error: function error(response) {
          _this.get('flashMessages').alert("Error getting Mendeley token");
          _this.get('errorReporter').reportFailedArticleShare(response);
        }
      });
    },

    getSaveParams: function getSaveParams() {
      return {
        token: localStorage.getItem('mendeley_token')
      };
    },
    hasCredentials: function hasCredentials() {
      var token = localStorage.getItem('mendeley_token');
      var expiration = +localStorage.getItem('mendeley_expiration');
      var date = new Date();

      return token && expiration && expiration > date.getTime();
    }
  });
});