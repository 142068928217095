define('browzine-web/controllers/wayf', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sorting: ['name'],
    libraries: Ember.computed.sort('model.libraries', 'sorting'),
    queryParams: ['selectedLibrary']
  });
});