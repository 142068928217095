define('browzine-web/routes/library/subjects', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    header: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),

    model: function model() {
      var library = this.modelFor('library');
      return library.fixedHasManyLoad('subjects').then(function (subjects) {
        return {
          subjects: subjects,
          library: library
        };
      });
    },
    afterModel: function afterModel() {
      var applicationSession = this.get('applicationSession');
      var flashMessages = this.get('flashMessages');

      var alertToShow = applicationSession.get('alertAfterTransition');
      if (!!alertToShow) {
        applicationSession.set('alertAfterTransition', undefined);
        flashMessages.alert(alertToShow);
      }
    },
    renderTemplate: function renderTemplate() {
      this.render('library.subjects');
      this.render('library.subjects-search', {
        into: 'library.subjects',
        outlet: 'search',
        controller: 'library'
      });
    },
    titleToken: function titleToken() {
      this.get('header').setProperties({
        subject: {
          value: 'library.header.welcome_to',
          template: true
        },
        title: {
          value: 'BrowZine',
          template: false
        }
      });
    }
  });
});