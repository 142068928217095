define('browzine-web/app', ['exports', 'browzine-web/resolver', 'ember-load-initializers', 'browzine-web/config/environment', 'browzine-web/errors/tracing'], function (exports, _resolver, _emberLoadInitializers, _environment, _tracing) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  window.Browzine = window.Browzine || {};

  // Put getTrace on the window under Browzine
  // so it's readily accessible from the javascript console


  window.Browzine.getTrace = _tracing.getTrace;

  var App = void 0;

  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  var browserIsCompatible = function browserIsCompatible() {
    return supportsFlexbox() || isLegacyWebview() || isAuthPage() || isConfirmEmailPage();
  };

  function supportsFlexbox() {
    return (window.Modernizr || {}).flexbox;
  }

  function isLegacyWebview() {
    return (window.Modernizr || {}).flexboxlegacy && window.navigator.userAgent.indexOf('Android 4.0') >= 0;
  }

  function isAuthPage() {
    return window.location.href.match('auth');
  }

  function isConfirmEmailPage() {
    return !!window.location.href.match('confirm-email');
  }

  if (_environment.default.environment === 'test' || browserIsCompatible()) {
    (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  } else {
    window.location = '/incompatible-browser.html';
  }

  (function () {
    if ("performance" in window === false) {
      window.performance = {};
    }

    Date.now = Date.now || function () {
      // thanks IE8
      return new Date().getTime();
    };

    if ("now" in window.performance === false) {
      var nowOffset = Date.now();

      if (performance.timing && performance.timing.navigationStart) {
        nowOffset = performance.timing.navigationStart;
      }

      window.performance.now = function () {
        return Date.now() - nowOffset;
      };
    }
  })();

  // There are plans to make the router a service so it can be injected; for now,
  // this adds support for that.
  //
  // Apparently a "router as service" MVP was merged in Jan 2017
  // https://github.com/emberjs/ember.js/pull/14805
  //
  // However, best I could tell, it is still behind a feature flag, and not
  // generally enabled yet.
  // Perhaps by the end of 2017 we will be able to remove this though.
  App.reopen({
    init: function init() {
      this._super.apply(this, arguments);
      this.register('service:router', {
        create: function create(attrs) {
          return Ember.getOwner(attrs).lookup('router:main');
        }
      });
    }
  });

  Ember.Router.reopen({
    didTransition: function didTransition() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.schedule('afterRender', this, function () {
        Ember.run.later(_this, function () {
          Ember.$('a').attr('tabindex', 0);
        }, 1000);
      });
    }
  });

  exports.default = App;
});