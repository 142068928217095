define('browzine-web/components/search-field/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    classNames: ['search-field'],

    isEmpty: function () {
      return Ember.isEmpty(this.get('value'));
    }.property('value'),

    becomeFocused: function () {
      if (!this.get('disableFocus') && (this.get('media.isDesktop') || this.get('media.isJumbo'))) {
        this.$('input').focus();
      }
    }.on('didInsertElement'),

    hideKeyboardOnReturn: function () {
      if (this.get('media.isMobile') || this.get('media.isTablet')) {
        this.$('input').keypress(function (e) {
          if ((e.keyCode || e.which) === 13) {
            Ember.$('.search-field-container input').blur();
          }
        });
      }
    }.on('didInsertElement'),

    actions: {
      clear: function clear() {
        this.set('value', '');
        this.becomeFocused();

        this.sendAction('cleared');
      }
    }
  });
});