define('browzine-web/services/user-agent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    isIOS: Ember.computed(function () {
      return navigator.userAgent.match(/(iPhone|iPod|iPad|iOS)/i);
    }),

    isSafari: Ember.computed(function () {
      return navigator.userAgent.match(/Safari/i) && !navigator.userAgent.match(/Chrome/i) && !navigator.userAgent.match(/CriOS/i);
    }),

    isChrome: Ember.computed(function () {
      return navigator.userAgent.match(/Chrome/i) || navigator.userAgent.match(/CriOS/i);
    }),

    safariVersion: Ember.computed(function () {
      var version = 0;

      try {
        var value = "Version/";
        var begin = navigator.appVersion.indexOf(value) + value.length;
        var semver = navigator.appVersion.slice(begin).split(' ')[0].split('.')[0];
        version = isNaN(Number(semver)) ? 0 : Number(semver);
      } catch (err) {
        console.log("Safari version unavailable:", err);
      }

      return version;
    }),

    iOSVersion: Ember.computed(function () {
      var version = 0;

      try {
        var value = "iPhone OS";
        var begin = navigator.appVersion.indexOf(value) + value.length;
        var semver = navigator.appVersion.slice(begin).trim().split(' ')[0].split('_')[0];
        version = isNaN(Number(semver)) ? 0 : Number(semver);

        if (version === 0) {
          var _value = "iPad; CPU OS";
          var _begin = navigator.appVersion.indexOf(_value) + _value.length;
          var _semver = navigator.appVersion.slice(_begin).trim().split(' ')[0].split('_')[0];
          version = isNaN(Number(_semver)) ? 0 : Number(_semver);
        }
      } catch (err) {
        console.log("iOS version unavailable:", err);
      }

      return version;
    }),

    isAndroid: Ember.computed(function () {
      return navigator.userAgent.match(/Android/i);
    }),

    isEdge: Ember.computed(function () {
      return navigator.userAgent.match(/Edge/i);
    }),

    isTest: false,

    platform: Ember.computed(function () {
      if (this.get('isIOS')) {
        return 'iOS';
      } else if (this.get('isAndroid')) {
        return 'Android';
      } else {
        return 'BZ Web';
      }
    })
  });
});