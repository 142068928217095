define('browzine-web/components/export-button/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    exporters: Ember.inject.service(),

    actions: {
      hide: function hide() {
        this.set('show', false);
      },
      toggle: function toggle() {
        this.set('show', !this.get('show'));
      }
    }
  });
});