define('browzine-web/mixins/authenticating-adapter', ['exports', 'browzine-web/mixins/error-helpers'], function (exports, _errorHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_errorHelpers.default, {
    auth: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    ajaxOptions: function ajaxOptions() {
      var _this = this;

      var options = this._super.apply(this, arguments);
      var originalBeforeSend = options.beforeSend;

      options.beforeSend = function (xhr) {
        var selectedLibraryId = _this.get('applicationSession').get('selectedLibrary');
        if (selectedLibraryId) {

          var authHeader = _this.get('auth').getAPIAuthHeaderForLibrary(selectedLibraryId);

          if (authHeader) {
            xhr.setRequestHeader('Authorization', authHeader);
          }
        }

        if (originalBeforeSend) {
          originalBeforeSend(xhr);
        }
      };

      return options;
    },


    /**
     * Helper to be called from handleResponse method of adapters
     * to provide standardized interpretation of 401 & 403 responses
     *
     * returns custom error on responses it interprets, otherwise nothing
     */
    handleUnauthorizedResponse: function handleUnauthorizedResponse(status, headers, payload) {
      var _this2 = this;

      if (status === 401) {
        return this.interpretError(status, payload);
      } else if (status === 403) {
        var auth = this.get('auth');
        var applicationSession = this.get('applicationSession');
        var loggedInUser = applicationSession.get('loggedInUser');
        var token = (loggedInUser || {}).token;
        Ember.run(function () {
          auth.authenticateUser(null, null, token);
          _this2.get('flashMessages').add({
            message: '',
            type: 'alert',
            componentName: 'permissions-error-alert',
            sticky: true
          });
        });
        return new Error('invalid permissions');
      }
    }
  });
});