define('browzine-web/components/scroll-each/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    collectionColumns: [100],
    pageSize: 40,
    pageIndex: 0,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('pagedItems', []);
      this.loadNext();
    },


    itemWatcher: Ember.observer('items.@each.id', function () {
      this.set('pagedItems', []);
      this.set('pageIndex', 0);
      this.loadNext();
    }),

    loadNext: function loadNext() {
      var items = this.get('items');
      var pagedItems = this.get('pagedItems');
      var pageSize = this.get('pageSize');
      var pageIndex = this.get('pageIndex');

      this.set('isLoading', true);

      if (pageIndex < items.length) {
        items.slice(pageIndex, pageIndex + pageSize).forEach(function (item) {
          pagedItems.pushObject(item);
        });

        pageIndex += pageSize;
        this.set('pageIndex', pageIndex);

        if (items.length < pageSize) {
          this.set('isLoading', false);
        }
      } else {
        this.set('isLoading', false);
      }
    },


    actions: {
      loadMore: function loadMore() {
        this.loadNext();
      }
    }
  });
});