define('browzine-web/components/library-customization-settings/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
    },


    actions: {
      reset: function reset() {
        var library = this.get('library');
        library.rollbackAttributes();
      },
      save: function save() {
        var _this = this;

        var library = this.get('library');
        if (!library.get('hasDirtyAttributes')) {
          return;
        }
        library.save().then(function () {
          _this.get('flashMessages').success('Library Saved');
        }).catch(function (err) {
          _this.get('flashMessages').alert('Error Updating Library: ' + err);
        });
      }
    }
  });
});