define('browzine-web/components/move-target/component', ['exports', 'browzine-web/mixins/click-on-enter'], function (exports, _clickOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_clickOnEnter.default, {
    tagName: 'li',
    classNames: 'move-target',
    classNameBindings: ['active', 'inactive'],

    active: Ember.computed('journals', 'following-journal', 'preceding-journal', 'moving-journal', function () {
      // This drop target should be active if the moving journal is not adjacent to it
      var journals = this.get('journals');
      var followingJournal = this.get('following-journal');
      var precedingJournal = this.get('preceding-journal');
      var movingJournal = this.get('moving-journal');

      if (precedingJournal === movingJournal) {
        return false;
      }

      var followingJournalIndex = journals.indexOf(followingJournal);
      var movingJournalIndex = journals.indexOf(movingJournal);

      if (movingJournalIndex === -1 || followingJournalIndex === -1) {
        return true;
      } else if (movingJournalIndex > followingJournalIndex) {
        return true;
      } else if (movingJournalIndex === followingJournalIndex) {
        return false;
      } else if (movingJournalIndex === followingJournalIndex - 1) {
        return false;
      } else {
        return true;
      }
    }),

    inactive: Ember.computed.not('active'),

    tabindex: Ember.computed('active', function () {
      if (this.get('active')) {
        return 0;
      } else {
        return -1;
      }
    }),

    click: function click() {
      if (this.get('active')) {
        this.attrs.move();
      }
    },


    hover: false,

    resetHover: Ember.observer('moving-journal', function () {
      if (Ember.isEmpty(this, 'moving-journal')) {
        this.set('hover', false);
      }
    }),

    mouseEnter: function mouseEnter() {
      this.set('hover', true);
    },
    focusIn: function focusIn() {
      this.set('hover', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('hover', false);
    },
    focusOut: function focusOut() {
      this.set('hover', false);
    }
  });
});