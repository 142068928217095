define('browzine-web/components/route-announcer', ['exports', 'a11y-announcer/components/route-announcer'], function (exports, _routeAnnouncer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _routeAnnouncer.default;
    }
  });
});