define('browzine-web/components/journal-sort-controls/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['sort-options-container'],
    attributeBindings: ['style'],

    intl: Ember.inject.service(),

    // This dynamically positions the sort controls to align to the right edge
    // of the rightmost column of journals. The numbers are embedded in CSS
    // and therefore duplicated here.
    style: Ember.computed('containerWidth', function () {
      var journalCoverWidth = 130;
      var journalPadding = 10;
      var journalWidth = journalCoverWidth + journalPadding * 2;
      var journalContainerPaddingLeft = 55;
      var journalContainerPaddingRight = 5;

      var containerWidthWithoutPadding = this.get('containerWidth') - journalContainerPaddingRight - journalContainerPaddingLeft;

      var journalsWithinContainer = Math.floor(containerWidthWithoutPadding / journalWidth);
      var widthOfAllJournals = journalsWithinContainer * journalWidth;

      var spaceOnRight = containerWidthWithoutPadding - widthOfAllJournals + journalPadding * 2 + journalContainerPaddingRight;

      return new Ember.String.htmlSafe('margin-right: ' + spaceOnRight + 'px');
    }),

    isActiveObserver: Ember.observer('isActive', function () {
      var isActive = this.get('isActive');

      if (!isActive) {
        Ember.$('.sort-control').bind('click', function (e) {
          e.preventDefault();
          return false;
        });
      }

      if (isActive) {
        Ember.run.later(this, function () {
          if (this.get('isActive')) {
            Ember.$('.sort-control').unbind('click');
          }
        }, 1000);
      }
    }),

    sortAToZTitle: Ember.computed('intl.primaryLocale', function () {
      return this.get('intl').t('subjects.menu.sort.desktop.aToZ');
    }),

    sortScimagoRankTitle: Ember.computed('intl.primaryLocale', function () {
      return this.get('intl').t('subjects.menu.sort.desktop.journal_rank');
    }),

    didInsertElement: function didInsertElement() {
      this._resizeHandler = function () {
        this.set('containerWidth', this.$().parent().width());
      }.bind(this);
      Ember.$(window).on('resize', this._resizeHandler);
      this._resizeHandler();
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off('resize', this._resizeHandler);
      Ember.$('.sort-control').unbind('click');
    }
  });
});