define('browzine-web/components/error-screen/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    errorReporter: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var error = this.get('error');
      this.get('errorReporter').reportUnknownError('hitFailBook', error);
    },


    actions: {
      retry: function retry() {
        this.sendAction('retry');
      }
    }
  });
});