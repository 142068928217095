define('browzine-web/models/collection-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    itemId: _emberData.default.attr('string'),
    itemType: _emberData.default.attr('string'),
    rawDoc: _emberData.default.attr(),
    itemData: _emberData.default.attr(),
    description: _emberData.default.attr('string'),
    addedAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date().toISOString();
      }
    }),

    syncData: Ember.computed('items.@each.syncData', function () {
      var store = this.get('store');

      var syncId = this.get('itemId');
      var article = store.peekAll('article').find(function (article) {
        return article.get('syncId') === syncId;
      });
      var itemData = this.get('itemData') || {
        articleTitle: '',
        journalTitle: '',
        journalCoverUrl: 'https://assets.thirdiron.com/default-journal-cover.png'
      };

      if (article) {
        itemData.articleTitle = article.get('title');
        itemData.articleDoi = article.get('doi');
        itemData.journalTitle = article.get('journal.title') || itemData.journalTitle;
        itemData.journalCoverUrl = article.get('journal.rawCoverURL') || itemData.journalCoverUrl;
      }

      var rawDoc = this.get('rawDoc');
      var syncData = {
        itemData: itemData,
        $schema: this.get('schema'),
        addedAt: this.get('addedAt'),
        itemId: this.get('itemId'),
        itemType: 'article'
      };

      return Object.assign({}, rawDoc, syncData);
    })
  });
});