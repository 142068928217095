define('browzine-web/services/window-location', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    assign: function assign(url) {
      window.location.assign(url);
    }
  });
});