define('browzine-web/services/clipboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    userAgent: Ember.inject.service('user-agent'),

    textarea: null,
    contentEditor: null,

    copyToClipboard: function copyToClipboard() {
      var contentEditor = this.get('contentEditor');
      var textarea = this.get('textarea');
      document.execCommand('copy');

      if (!contentEditor) {
        document.body.removeChild(textarea);
      }
    },
    selectText: function selectText() {
      var textarea = this.get('textarea');
      var userAgent = this.get('userAgent');

      if (userAgent.get('isIOS')) {
        var range = document.createRange();
        range.selectNodeContents(textarea);

        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        textarea.setSelectionRange(0, 999999);
      } else {
        textarea.select();
      }
    },
    createTextArea: function createTextArea(text, textarea) {
      var contentEditor = this.get('contentEditor');

      if (!contentEditor) {
        textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
      }

      this.set("textarea", textarea);
    },
    copy: function copy(text, textarea) {
      this.set('contentEditor', !!textarea);
      this.createTextArea(text, textarea);
      this.selectText();
      this.copyToClipboard();
    }
  });
});