define('browzine-web/mixins/trigger-on-enter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      triggerOnEnter: function triggerOnEnter() {
        var e = arguments[arguments.length - 1];
        if (e.keyCode === 13) {
          var newArguments = [];

          for (var i = 1; i < arguments.length - 1; i++) {
            newArguments.push(arguments[i]);
          }

          this.send.apply(this, [arguments[0]].concat(newArguments));
        }
      }
    }
  });
});