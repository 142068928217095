define('browzine-web/components/library-list/component', ['exports', 'browzine-web/mixins/accordion-container', 'ember-intl'], function (exports, _accordionContainer, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_accordionContainer.default, {
    classNames: ['library-list'],
    /*
    we set the value to the empty string here so that the library list doesn't get redrawn on blur
    see issue: https://github.com/emberjs/ember.js/issues/11922
    */
    search: '',

    chooseLibrarySearchLibraryNames: (0, _emberIntl.t)('choose_library_page.search_library_names'),

    focusSearchField: function () {
      var _this = this;

      var searchInput = Ember.$('.search-field input');

      searchInput.on('click', function () {
        if (_this.get('media.isMobile') || _this.get('media.isTablet')) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$('.search').offset().top
          }, 500);
        }
      });
    }.on('didInsertElement'),

    actions: {
      itemExpanded: function itemExpanded(item) {
        var library = item.get('library');

        if (library.get('webLoginInfo')) {
          this.set('expandedItem', item);
        } else {
          this.sendAction('viewLibrary', library);
        }
      },
      viewLibrary: function viewLibrary(library) {
        this.sendAction('viewLibrary', library);
      },
      gotoAnchor: function gotoAnchor(anchorName) {
        window.location.hash = '#' + anchorName;
      }
    }
  });
});