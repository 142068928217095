define('browzine-web/routes/library/my-articles', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myArticles: Ember.inject.service(),
    header: Ember.inject.service(),
    intl: Ember.inject.service(),

    setupController: function setupController(controller) {
      controller.set('library', this.modelFor('library'));
    },
    titleToken: function titleToken() {
      this.get('header').setProperties({
        subject: {
          value: 'BrowZine',
          template: false
        },
        title: {
          value: this.get('intl').t('my_articles.feature_name'),
          template: false
        }
      });

      return this.get('intl').t('my_articles.feature_name');
    }
  });
});