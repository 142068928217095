define('browzine-web/serializers/subject', ['exports', 'browzine-web/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      resourceHash.links.rankSortedJournals = resourceHash.links.journals + '?sort=rank';
      return this._super(modelClass, resourceHash);
    }
  });
});