define('browzine-web/routes/index', ['exports', 'browzine-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    errorReporter: Ember.inject.service(),
    auth: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    header: Ember.inject.service(),
    intl: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      var applicationSession = this.get('applicationSession');
      // This probably needs to change so that selected library remembered across
      // sessions is used instead of the library selected _this_ session.
      var selectedLibraryId = applicationSession.get('selectedLibrary');
      var mostRecentLibrary = applicationSession.get('mostRecentLibrary');

      // If the window is already pegged to a library, go to the top level of its
      // taxonomy
      if (selectedLibraryId) {
        this.transitionTo('library', selectedLibraryId);
      } else if (mostRecentLibrary) {
        // Next see if a previously used library has been set, if so go to _its_
        // taxonomy root
        this.transitionTo('library', mostRecentLibrary);
      } else {
        // Otherwise, see if we can detect the appropriate library for the user
        // based upon their IP address.
        return this.get('store').query('library', { detect: 'ip', subscription: 'bzweb' }).then(function (libraries) {
          if (libraries.get('length')) {

            // Authenticate direct then redirect to the top of that library's taxonomy tree
            var detectedLibraryId = libraries.get('firstObject').id;
            _this.get('auth').authenticateLibraryDirectOnly(detectedLibraryId).then(function () {
              return _this.transitionTo('library', detectedLibraryId);
            });
          }
        }).catch(function () {
          _this.get('errorReporter').reportLibraryDetectionError();
          _this.get('flashMessages').alert('Error detecting library');
        });
      }
      // When all else fails, the route proceeds and displays the welcome
      // page with a "Choose Library" button
    },
    titleToken: function titleToken() {
      this.get('header').setProperties({
        subject: {
          value: 'library.header.welcome_to',
          template: true
        },
        title: {
          value: 'BrowZine',
          template: false
        }
      });
    },


    actions: {
      selectOpenAccessLibrary: function selectOpenAccessLibrary() {
        var _this2 = this;

        this.get('auth').authenticateLibrary({ libraryId: _environment.default.openAccessLibraryId }).then(function () {
          _this2.transitionTo('library', _environment.default.openAccessLibraryId);
        }, function () {
          _this2.transitionTo('token-failure', _environment.default.openAccessLibraryId);
        });
      }
    }

  });
});