define('browzine-web/controllers/library/sso-login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['auto', 'disableRedirect'],
    auto: false,
    disableRedirect: false
  });
});