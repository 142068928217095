define('browzine-web/controllers/library/my-articles', ['exports', 'browzine-web/mixins/trigger-on-enter', 'ember-intl'], function (exports, _triggerOnEnter, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_triggerOnEnter.default, {
    myArticles: Ember.inject.service(),
    router: Ember.inject.service('router'),
    libraryController: Ember.inject.controller('library'),
    library: Ember.computed.alias('libraryController.model'),
    sync: Ember.inject.service(),
    intl: Ember.inject.service(),

    loginLinkText: (0, _emberIntl.t)('my_articles.pre_login_login_link_text'),
    signupLinkText: (0, _emberIntl.t)('my_articles.pre_login_signup_link_text'),
    primaryLocale: Ember.computed.alias('intl.primaryLocale'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var newCollectionModal = {};
      this.set('newCollectionModal', newCollectionModal);

      var handleKeyPress = function handleKeyPress(event) {
        //Esc Key
        if (event.keyCode === 27) {
          Ember.run.later(function () {
            if (_this.get('showCollections')) {
              _this.set('showCollections', false);
              Ember.$('.collections').focus();
            }

            if (_this.get('showSort')) {
              _this.set('showSort', false);
              Ember.$('.sort').focus();
            }
          }, 100);
        }
      };

      Ember.$(document).on('keyup', handleKeyPress);
      this.set('handleKeyPress', handleKeyPress);
    },
    didDestroyElement: function didDestroyElement() {
      Ember.$(document).unbind('keyup', this.get('handleKeyPress'));
    },


    actions: {
      createCollection: function createCollection() {
        this.set('showCollections', false);
        var newCollectionModal = this.get('newCollectionModal');
        newCollectionModal.show();
      },
      toggleCollections: function toggleCollections() {
        this.set('showSort', false);
        this.set('showCollections', !this.get('showCollections'));
      },
      toggleSort: function toggleSort() {
        this.set('showCollections', false);
        this.set('showSort', !this.get('showSort'));
      }
    }
  });
});