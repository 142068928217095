define('browzine-web/routes/library/sso-login', ['exports', 'browzine-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    header: Ember.inject.service(),
    myBookshelf: Ember.inject.service(),

    setupController: function setupController(controller) {
      controller.set('library', this.modelFor('library'));
    },
    resetController: function resetController(controller, isExiting, transition) {
      this._super(controller, isExiting, transition);

      if (isExiting) {
        controller.set('auto', false);
        controller.set('disableRedirect', false);
      }
    },
    titleToken: function titleToken() {
      this.get('header').setProperties({
        subject: {
          value: 'Library',
          template: false
        },
        title: {
          value: 'SSO Authentication',
          template: false
        }
      });

      return 'SSO Authentication';
    },


    actions: {
      login: function login() {
        var library = this.modelFor('library');
        var libraryId = library.get('id');
        var resumeUrl = this.get('myBookshelf.returnRoute');
        var queryParams = {};

        if (resumeUrl) {
          queryParams.intent = window.btoa(JSON.stringify({ url: resumeUrl }));
        }

        var successPath = this.get('router._routerMicrolib').generate('accept-token', libraryId, { queryParams: queryParams });
        var failurePath = this.get('router._routerMicrolib').generate('token-failure', libraryId);

        Ember.$.ajax({
          url: _environment.default.apiBaseUrl + '/sso/login',
          type: 'POST',
          data: JSON.stringify({
            libraryId: libraryId,
            client: 'bzweb',
            success: successPath,
            failure: failurePath
          }),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json'
        }).then(function (response) {
          var ssoGateway = response.ssoGateway;

          window.location.replace(ssoGateway);
        });
      }
    }
  });
});