define('browzine-web/adapters/article', ['exports', 'ember-data', 'browzine-web/mixins/authenticating-adapter', 'browzine-web/mixins/client-adapter', 'browzine-web/mixins/localization-adapter', 'browzine-web/config/environment'], function (exports, _emberData, _authenticatingAdapter, _clientAdapter, _localizationAdapter, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_authenticatingAdapter.default, _clientAdapter.default, _localizationAdapter.default, {
    namespace: _environment.default.apiNamespace,
    host: _environment.default.apiHost,

    handleResponse: function handleResponse(status, headers, payload) {

      var unauthorizedError = this.handleUnauthorizedResponse(status, headers, payload);

      if (unauthorizedError) {
        return unauthorizedError;
      }

      if (status === 404) {
        var error = new Error("article not found");
        error.status = 404;
        error.payload = payload;
        return error;
      }

      return this._super.apply(this, arguments);
    },
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      // Use default implementation if the
      // call doesn't specify a DOI
      if (!query.doi) {
        return this._super(query, modelName);
      }

      // If it does specify a DOI, build the
      // single DOI lookup URL then delete it
      // from the query data so it
      // doesn't affect the queryString
      var host = this.get('host');
      var prefix = this.urlPrefix();
      var url = [];
      var path = this.pathForType(modelName);
      url.push(path);
      url.push(encodeURIComponent('doi:' + query.doi));

      if (prefix) {
        url.unshift(prefix);
      }
      url = url.join('/');
      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      delete query.doi;

      return url;
    }
  });
});