define('browzine-web/adapters/journal', ['exports', 'ember-data', 'browzine-web/config/environment', 'browzine-web/mixins/belongs-to-library-adapter', 'browzine-web/mixins/client-adapter', 'browzine-web/mixins/localization-adapter', 'browzine-web/mixins/authenticating-adapter'], function (exports, _emberData, _environment, _belongsToLibraryAdapter, _clientAdapter, _localizationAdapter, _authenticatingAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_authenticatingAdapter.default, _clientAdapter.default, _localizationAdapter.default, _belongsToLibraryAdapter.default, {
    namespace: _environment.default.apiNamespace,
    host: _environment.default.apiHost,

    handleResponse: function handleResponse(status, headers, payload) {
      var unauthorizedError = this.handleUnauthorizedResponse(status, headers, payload);

      if (unauthorizedError) {
        return unauthorizedError;
      }

      return this._super.apply(this, arguments);
    }
  });
});