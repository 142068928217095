define('browzine-web/components/my-bookshelf-intro-flash-message/component', ['exports', 'browzine-web/mixins/click-on-enter', 'browzine-web/mixins/trigger-on-enter'], function (exports, _clickOnEnter, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_clickOnEnter.default, _triggerOnEnter.default, {
    actions: {
      dismissSyncMessage: function dismissSyncMessage() {
        localStorage.setItem('syncMessageDismissed', JSON.stringify(true));
      }
    }
  });
});