define('browzine-web/routes/library/subject', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    applicationSession: Ember.inject.service(),

    parentController: Ember.computed(function () {
      return this.controllerFor('library');
    }),

    setupController: function setupController(controller) {
      controller.set('parentController', this.get('parentController'));
      if (!this.get('parentController.sort')) {
        this.set('parentController.sort', 'title');
      }
    },
    model: function model(params) {
      return this.store.findRecord('subject', params.subject_id, { preload: { library: this.modelFor('library') } });
    },


    actions: {
      back: function back() {
        var routes = Ember.getOwner(this).lookup('router:main').currentPath.replace('.index', '').split('.');

        routes.splice(-1);

        var route = routes.join('.');

        this.transitionTo(route);
      }
    }
  });
});