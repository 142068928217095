define('browzine-web/components/sso-login/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var auto = this.get('auto');
      var disableRedirect = this.get('disableRedirect');

      if (auto && !disableRedirect) {
        this.sendAction('onContinue');
      }
    },


    actions: {
      continue: function _continue() {
        return this.sendAction('onContinue');
      }
    }
  });
});