define('browzine-web/services/a11y', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Service.extend({
    previousActiveElement: null,

    disableFocus: function disableFocus(selector) {
      [].concat(_toConsumableArray(document.querySelectorAll(selector))).forEach(function (element) {
        element.setAttribute('tabindex', -1);
        element.setAttribute('aria-hidden', true);
      });
    },
    enableFocus: function enableFocus(selector) {
      [].concat(_toConsumableArray(document.querySelectorAll(selector))).forEach(function (element) {
        if (selector === 'a') {
          element.removeAttribute('tabindex');
        } else {
          element.setAttribute('tabindex', 0);
        }

        element.removeAttribute('aria-hidden');
      });
    },
    headerSearchFocus: function headerSearchFocus() {
      var _this = this;

      Ember.run.later(function () {
        _this.set("previousActiveElement", document.querySelector('.search'));

        _this.disableFocus('a');
        _this.disableFocus('button');
        _this.disableFocus('.tabindex');
        _this.enableFocus('.search');
        _this.enableFocus('.search-pane .tabindex');
        _this.enableFocus('.result-container .result a');
        _this.enableFocus('.filter-container .filters .filter');
      }, 1000);
    },
    headerSearchBlur: function headerSearchBlur() {
      var _this2 = this;

      Ember.run.later(function () {
        var previousActiveElement = _this2.get('previousActiveElement');

        _this2.enableFocus('a');
        _this2.enableFocus('button');
        _this2.enableFocus('.tabindex');

        if (previousActiveElement) {
          previousActiveElement.focus();
        }
      }, 1000);
    },
    bzModalFocus: function bzModalFocus(modal) {
      var _this3 = this;

      Ember.run.next(function () {
        _this3.disableFocus('a');
        _this3.disableFocus('button');
        _this3.disableFocus('.tabindex');
        _this3.enableFocus('#' + modal.element.id + ' .tabindex');
      });
    },
    bzModalBlur: function bzModalBlur() {
      var _this4 = this;

      Ember.run.next(function () {
        var previousActiveElement = _this4.get('previousActiveElement');

        _this4.enableFocus('a');
        _this4.enableFocus('button');
        _this4.enableFocus('.tabindex');

        if (previousActiveElement) {
          previousActiveElement.focus();
        }
      });
    },
    issueNavFocus: function issueNavFocus() {
      var _this5 = this;

      Ember.run.next(function () {
        var selector = '.back-issue-items .issue:nth-child(1) a';
        var el = document.querySelector(selector);
        _this5.set('previousActiveElement', el);
        _this5.enableFocus(selector);
        if (el) {
          el.focus();
        }
      });
    },
    issueTitleFocus: function issueTitleFocus() {
      var _this6 = this;

      Ember.run.next(function () {
        var selector = '.issue .title';
        var el = document.querySelector(selector);
        _this6.set('previousActiveElement', el);
        _this6.enableFocus(selector);
        if (el) {
          el.focus();
        }
      });
    },
    responsiveMenuFocus: function responsiveMenuFocus() {
      this.set("previousActiveElement", document.querySelector('#menu'));

      this.disableFocus('a');
      this.disableFocus('button');
      this.disableFocus('.tabindex');
      this.enableFocus('#menu');
    },
    responsiveMenuBlur: function responsiveMenuBlur() {
      var previousActiveElement = this.get('previousActiveElement');

      this.enableFocus('a');
      this.enableFocus('button');
      this.enableFocus('.tabindex');

      if (previousActiveElement) {
        previousActiveElement.focus();
      }
    }
  });
});