define('browzine-web/components/centered-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    customizations: Ember.inject.service(),
    customStyle: Ember.computed('customStyleString', function () {
      var style = this.get('customStyleString');
      return Ember.String.htmlSafe(style);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('customStyleString', this.get('customizations').getCustomStyle());
    }
  });
});