define('browzine-web/models/article-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_ARTICLE_SCHEMA = 'http://thirdiron.com/jsonSchemas/couch-article/0';

  exports.default = Ember.Object.extend({
    isSyncing: false,
    isUnread: false,

    // A “placeholder” ArticleStatus is an object generated for an article that
    // doesn’t have a CouchDB document; if one is received, the ArticleListItem
    // already has a reference to the ArticleStatus that then has real data.
    isPlaceholder: Ember.computed.empty('id'),
    canBeMarkedUnread: Ember.computed('isPlaceholder', 'isDeleted', function () {
      return !(this.get('isPlaceholder') || this.get('isDeleted'));
    }),

    revisionObserver: Ember.observer('rev', function () {
      var pastRevisions = this.get('pastRevisions') || [];
      pastRevisions.push(this.get('rev'));
      this.set('pastRevisions', pastRevisions);
    }),

    unreadObserver: Ember.on('init', Ember.observer('isPlaceholder', 'isDeleted', 'markedRead', 'viewCount', function () {
      if (Ember.testing) {
        window.unreadObserverCount++;
      }
      var isPlaceholder = this.get('isPlaceholder');
      var isDeleted = this.get('isDeleted');
      var wasUnread = this.get('isUnread');
      var isUnread = void 0;

      if (isPlaceholder || isDeleted) {
        isUnread = false;
      } else {
        isUnread = !(this.get('markedRead') || this.get('viewCount'));
      }
      this.set('isUnread', isUnread);

      // Only update counts when going from unread to read
      if (!wasUnread || isUnread) {
        return;
      }

      var unreads = this.get('unreads');
      var issueId = this.get('issueId');
      var journalId = this.get('journalId');
      unreads.updateCounts({
        issueId: issueId,
        journalId: journalId
      });
    })),

    syncData: Ember.computed('id', 'rev', '$schema', 'issueId', 'journalId', 'viewCount', 'viewedAt', 'metadataUpdated', 'markedRead', {
      get: function get() {
        return {
          _id: this.get('id'),
          _rev: this.get('rev'),
          type: 'article',
          $schema: this.get('$schema') || DEFAULT_ARTICLE_SCHEMA,

          issue: this.get('issueId'),
          journal: this.get('journalId'),

          markedRead: this.get('markedRead'),
          viewCount: this.get('viewCount'),
          viewedAt: this.get('viewedAt'),
          metadataUpdated: this.get('metadataUpdated')
        };
      },
      set: function set(key, value, forced) {
        var rev = value._rev;
        var pastRevisions = this.get('pastRevisions') || [];
        var isPastRevision = pastRevisions.indexOf(rev) > -1;
        var isCurrentRevision = this.get('rev') === rev;
        if (!forced && (isCurrentRevision || isPastRevision)) {
          return;
        }
        this.setProperties({
          rev: rev,
          id: value._id,
          $schema: value.$schema,
          isDeleted: value._deleted,

          issueId: value.issue,
          journalId: value.journal,

          markedRead: value.markedRead,
          viewCount: value.viewCount,
          viewedAt: value.viewedAt,
          metadataUpdated: value.metadataUpdated
        });

        return value;
      }
    })
  });
});