define('browzine-web/components/splash-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['splash-panel'],
    classNameBindings: ['hide-header'],
    header: Ember.inject.service(),
    customizations: Ember.inject.service(),
    applicationSession: Ember.inject.service(),

    customStyle: Ember.computed('applicationSession.selectedLibrary', function () {
      var style = this.get('customizations').getCustomStyle();
      return Ember.String.htmlSafe(style);
    })
  });
});