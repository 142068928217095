define('browzine-web/components/hint-toggle/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    active: false,

    init: function init() {
      this._super.apply(this, arguments);
    },


    actions: {
      toggle: function toggle() {
        this.set('active', !this.get('active'));
      }
    }
  });
});