define('browzine-web/services/permissions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    applicationSession: Ember.inject.service(),

    hasPermission: function hasPermission(requestedPermissions, libraryPermissionRequestedFor) {
      var loggedInUser = this.get('applicationSession').get('loggedInUser');

      if (!loggedInUser) {
        return false;
      }

      var grantedPermissions = loggedInUser.permissionGrants;
      if (!grantedPermissions) {
        return false;
      }

      if (libraryPermissionRequestedFor) {
        // Component is checking for scoped permissions
        return requestedPermissions.every(function (requestedPermission) {
          return grantedPermissions.some(function (grant) {
            return grant.permissionCode === requestedPermission && (grant.libraryScope === 'all' || grant.libraryScope === libraryPermissionRequestedFor);
          });
        });
      } else {
        // Component is checking for system-wide function permissions
        return requestedPermissions.every(function (requestedPermission) {
          return grantedPermissions.some(function (grant) {
            return grant.permissionCode === requestedPermission;
          });
        });
      }
    }
  });
});