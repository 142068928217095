define('browzine-web/models/my-bookcase', ['exports', 'browzine-web/mixins/journal-container'], function (exports, _journalContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_journalContainer.default, {
    children: Ember.computed.alias('bookshelves'),

    syncData: Ember.computed('title', 'bookshelves.@each.syncData', function () {
      return {
        title: this.get('title'),
        bookshelves: this.get('bookshelves').mapBy('syncData')
      };
    }),

    id: Ember.computed('index', 'title', function () {
      var index = this.get('index');
      var title = this.get('title') || '';

      return index + '-' + title.dasherize();
    })
  });
});