define('browzine-web/components/require-permissions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    permissions: Ember.inject.service(),

    tagName: '',

    hasPermission: Ember.computed('requestedPermissions', 'library', function () {
      var permissions = this.get('permissions');
      var requestedPermissions = this.get('requestedPermissions').split(",");
      var libraryPermissionRequestedFor = this.get('library');

      return permissions.hasPermission(requestedPermissions, libraryPermissionRequestedFor);
    })
  });
});