define('browzine-web/routes/auth/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      forwardToSignup: function forwardToSignup(email) {
        this.transitionTo('auth.signup', { queryParams: { addPasswordFor: email } });
      }
    }
  });
});