define('browzine-web/components/article-list/component', ['exports', 'browzine-web/mixins/accordion-container', 'browzine-web/mixins/trigger-on-enter'], function (exports, _accordionContainer, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_accordionContainer.default, _triggerOnEnter.default, {
    classNames: ['article-list'],

    userAgent: Ember.inject.service('user-agent'),

    // This returnToTop-related code is inspired by a spike where I added
    // infinite scrolling to the search pane results, which was taken wholesale from
    // https://github.com/hhff/ember-infinity

    // It could be extracted to a mixin or made into a wrapper component if
    // the “back to top” functionality is useful elsewhere

    scrollDebounce: 10,
    displayBackToTop: false,

    scrollContainer: Ember.computed(function () {
      return Ember.$(window);
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._bindScroll();
      this._checkIfScrolled();

      Ember.run.schedule('afterRender', this, this._addToMyArticles);

      this.preloadResources();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.scrollToArticleInContext();
    },
    willDestroyElement: function willDestroyElement() {
      this._unbindScroll();
    },
    scrollToArticleInContext: function scrollToArticleInContext() {
      var _this = this;

      Ember.run.next(function () {
        var $shownInContext = Ember.$('.article-in-context-highlight');

        if (!$shownInContext.length) {
          return;
        }

        var isAndroid = _this.get('userAgent').get('isAndroid');
        var isSafari = _this.get('userAgent').get('isSafari');
        var isEdge = _this.get('userAgent').get('isEdge');
        var isChrome = _this.get('userAgent').get('isChrome');
        var safariVersion = _this.get('userAgent').get('safariVersion');
        var iOSVersion = _this.get('userAgent').get('iOSVersion');
        var isDesktop = _this.get('media.isDesktop') || _this.get('media.isJumbo');

        var deviceContainer = isSafari && safariVersion < 13 || iOSVersion < 13 && isChrome && !isAndroid && !isDesktop || isEdge ? 'body' : 'html';

        var defaultContainer = Ember.testing ? '#ember-testing-container' : deviceContainer;
        var container = defaultContainer;
        var $container = Ember.$(container);

        var scrollDuration = 1000;
        var scrollEasing = 'easeOutCubic';

        var offsetSelector = '.screen-header';
        var stickyHeaderOffsetSelector = '.journal-toc header.sticky';

        var offset = Ember.$(offsetSelector) ? Math.ceil(Ember.$(offsetSelector).height()) : 0;
        var stickyHeaderOffset = Ember.$(stickyHeaderOffsetSelector) ? Math.ceil(Ember.$(stickyHeaderOffsetSelector).height()) : 0;

        var containerHeight = 0;
        var scrollDestination = 0;
        var resultTopMargin = 15;

        if (_this.get('media.isDesktop') || _this.get('media.isJumbo')) {
          containerHeight = Math.ceil($container.height() - offset - stickyHeaderOffset);
          scrollDestination = Math.ceil($shownInContext.offset().top) - (offset + stickyHeaderOffset + resultTopMargin);
        } else {
          containerHeight = Math.ceil($container.height() - stickyHeaderOffset);
          scrollDestination = Math.ceil($shownInContext.offset().top) - (stickyHeaderOffset + resultTopMargin);
        }

        if (Ember.testing) {
          var testingOffset = 135;

          if (_this.get('media.isDesktop') || _this.get('media.isJumbo')) {
            scrollDestination -= testingOffset;
          } else {
            scrollDestination -= testingOffset * 2;
          }
        }

        if (scrollDestination && containerHeight && $shownInContext.length > 0) {
          $container.animate({ scrollTop: scrollDestination }, scrollDuration, scrollEasing);
        }
      });
    },
    _addToMyArticles: function _addToMyArticles() {
      var rawAddToMyArticles = localStorage.getItem('myArticles.addToMyArticles');
      /* jshint -W116 */
      if (rawAddToMyArticles === undefined || rawAddToMyArticles == null || rawAddToMyArticles == "undefined") {
        rawAddToMyArticles = null;
      }
      /* jshint +W116 */

      var addToMyArticles = JSON.parse(rawAddToMyArticles) || this.get('myArticles.addToMyArticles');

      if (addToMyArticles) {
        var syncId = JSON.parse(addToMyArticles);

        Ember.run.next(function () {
          if (Ember.$('article.' + syncId).length > 0) {
            Ember.$("html, body").animate({ scrollTop: Ember.$('article.' + syncId).offset().top - 110 }, "slow");

            Ember.run.later(function () {
              Ember.$('article.' + syncId + ' .tools .add-to-my-articles').click();

              localStorage.removeItem('myArticles.addToMyArticles');
            }, 1000);
          }
        });
      }
    },
    _bindScroll: function _bindScroll() {
      var _this2 = this;

      Ember.$(window).on('scroll.article-list', function () {
        Ember.run.debounce(_this2, _this2._checkIfScrolled, _this2.get('scrollDebounce'));
      });
    },
    _unbindScroll: function _unbindScroll() {
      Ember.$(window).off('scroll.article-list');
    },
    _checkIfScrolled: function _checkIfScrolled() {
      var scrollTop = Ember.$(window).scrollTop();
      var viewportHeight = Ember.$(window).height();
      var journalContainer = Ember.$('aside .journal .rest').height();
      var shouldPadContainer = scrollTop > viewportHeight && !this.get('articles.isUpdating');

      if (this.isDestroyed) {
        // if we don't check this, we get a sporadic error when running tests:
        // Uncaught Error: Assertion Failed: calling set on destroyed object
        return;
      }

      this.set('displayBackToTop', scrollTop > viewportHeight);

      if (scrollTop > journalContainer) {
        Ember.$('.journal-toc header.sticky').css({
          visibility: 'visible'
        });
      } else {
        Ember.$('.journal-toc header.sticky').css({
          visibility: 'hidden'
        });
      }

      if (shouldPadContainer) {
        Ember.$('.article-list').addClass('article-list-padding');
      }
    },
    preloadResources: function preloadResources() {
      if (this.get('media.isMobile') || this.get('media.isTablet')) {
        Ember.run.once(function () {
          Ember.$('.controls .issues').click();

          Ember.run.once(function () {
            Ember.$('.controls .related').click();

            Ember.run.once(function () {
              Ember.$('.controls .related').click();
            });
          });
        });
      }
    },


    actions: {
      loadMoreArticles: function loadMoreArticles() {
        var deferred = Ember.RSVP.defer();
        this.sendAction('loadMoreArticles', deferred);
        return deferred.promise;
      },
      returnToTop: function returnToTop() {
        Ember.$(window).scrollTop(0);
      },
      collectionListUpdated: function collectionListUpdated() {
        this.sendAction('refreshCollectionList');
      }
    }
  });
});