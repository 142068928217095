define('browzine-web/views/subject', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.View.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('resizeHandler', function () {
        Ember.run.debounce(_this, _this.resizeHoldingsContainer, 200);
      });

      Ember.$(window).on('resize', this.get('resizeHandler')).trigger('resize');
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off('resize', this.get('resizeHandler'));
    },
    resizeHoldingsContainer: function resizeHoldingsContainer() {
      this.$('.holdings-container').height(Ember.$(window).height() - Ember.$('nav').height());
    }
  });
});