define('browzine-web/components/citation-modal/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    classNames: ['citation-modal'],

    exporters: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var modal = this.get('modal');

      Ember.run.next(function () {
        modal.element = _this.element;
        modal.element.id = _this.element.id;
      });

      modal.ok = function () {
        _this.ok();
      };

      modal.cancel = function () {
        _this.cancel();
      };
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      var modal = this.get('modal');

      modal.onShow = function () {
        Ember.run.next(function () {
          _this2.$('ul.services li:first-child').focus();
        });
      };
    },


    actions: {
      ok: function ok() {
        var modal = this.get('modal');
        modal.hide();
      },
      zotero: function zotero() {
        var article = this.get('article');
        var library = this.get('library');
        var exporters = this.get('exporters');
        exporters.zotero(library, article);
        this.send('ok');
      },
      mendeley: function mendeley() {
        var article = this.get('article');
        var library = this.get('library');
        var exporters = this.get('exporters');
        exporters.mendeley(library, article);
        this.send('ok');
      },
      bibtex: function bibtex() {
        var article = this.get('article');
        var library = this.get('library');
        var exporters = this.get('exporters');
        exporters.bibtex(library, article);
        this.send('ok');
      },
      refworks: function refworks() {
        var article = this.get('article');
        var library = this.get('library');
        var exporters = this.get('exporters');
        exporters.refworks(library, article);
        this.send('ok');
      },
      endnote: function endnote() {
        var article = this.get('article');
        var library = this.get('library');
        var exporters = this.get('exporters');
        exporters.endnote(library, article);
        this.send('ok');
      },
      universal: function universal() {
        //Same output as Endnote RIS. Helps Sales overcome generic RIS use questions.
        this.send('endnote');
      },
      citavi: function citavi() {
        //Same output as Endnote RIS. Helps Sales overcome generic RIS use questions.
        this.send('endnote');
      }
    }
  });
});