define('browzine-web/mixins/click-on-enter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    attributeBindings: ['tabindex'],
    tabindex: 0,

    keyPress: function keyPress(e) {
      if (e.keyCode === 13) {
        this.click();
      }
    }
  });
});