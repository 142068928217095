define('browzine-web/components/search-pane/component', ['exports', 'browzine-web/mixins/trigger-on-enter'], function (exports, _triggerOnEnter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var exactMatchRank = -5;

  exports.default = Ember.Component.extend(_triggerOnEnter.default, {
    classNames: ['search-pane-container'],
    classNameBindings: ['position', 'complete', 'filtered'],

    disableScrollingOnEnter: false,
    disableScrollingOnInsert: false,

    notFiltered: Ember.computed.equal('filter', 'all'),
    filtered: Ember.computed.not('notFiltered'),

    maxTitleLength: 90,

    a11y: Ember.inject.service(),
    intl: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var handleKeyPress = function handleKeyPress(event) {
        //Esc Key
        if (event.keyCode === 27) {
          Ember.run.next(function () {
            _this.hide();
          });
        }
      };

      Ember.$(document).on('keyup', handleKeyPress);
      this.set('handleKeyPress', handleKeyPress);
    },
    didDestroyElement: function didDestroyElement() {
      var a11y = this.get('a11y');
      a11y.headerSearchBlur();

      Ember.$(document).unbind('keyup', this.get('handleKeyPress'));
    },


    setDefaultFilter: function () {
      var filter = this.get('filter');

      if (Ember.isBlank(filter)) {
        this.set('filter', 'all');
      }
    }.observes('filter'),

    presentationFilter: Ember.computed('filter', function () {
      var filter = this.get('filter');

      if (filter === 'bookshelves') {
        return 'subjects';
      } else if (filter === 'journals') {
        return filter;
      }
    }),

    willInsertElement: function willInsertElement() {
      if (this.get('disableScrollingOnInsert') && (this.get('media.isDesktop') || this.get('media.isJumbo'))) {
        Ember.$('body').addClass('no-scroll');
      }
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('body').removeClass('no-scroll');
    },
    mouseEnter: function mouseEnter() {
      if (this.get('disableScrollingOnEnter') && (this.get('media.isDesktop') || this.get('media.isJumbo'))) {
        Ember.$('body').addClass('no-scroll');
      }
    },
    mouseLeave: function mouseLeave() {
      if (this.get('disableScrollingOnEnter')) {
        Ember.$('body').removeClass('no-scroll');
      }
    },


    filteredResults: Ember.computed('filter', 'results', function () {
      var filter = this.get('filter');
      var results = this.get('results').slice(0);
      var filteredResults = void 0;

      if (results.length > 0) {
        var firstExternalNonExactMatchIndex = results.findIndex(function (result) {
          return result.type === 'journals' && result.external_link && result.rank !== exactMatchRank;
        });
        if (firstExternalNonExactMatchIndex !== -1) {
          results.splice(firstExternalNonExactMatchIndex, 0, {
            type: 'journals',
            showExternalResultsHeader: true
          });
        }
      }

      if (filter === 'all') {
        filteredResults = results;
      } else if (filter === 'subjects') {
        filteredResults = results.filter(function (item) {
          return item.type === 'bookcases' || item.type === 'bookshelves';
        });
      } else {
        filteredResults = results.filterBy('type', filter);
      }

      return filteredResults;
    }),

    resetScrollPosition: Ember.observer('results', function () {
      var searchPaneElement = this.$('.search-pane');

      if (searchPaneElement) {
        searchPaneElement.scrollTop(0);
      }
    }),

    hide: function hide() {
      this.sendAction('exit');
    },


    searchTitle: Ember.computed('intl.primaryLocale', function () {
      return this.get('intl').t('search.header');
    }),

    actions: {
      filter: function filter(type) {
        this.set('filter', type);
      },
      resetFilter: function resetFilter() {
        this.set('filter', 'all');
      },
      setQuery: function setQuery(query) {
        this.set('query', query);
      },
      clearHistory: function clearHistory() {
        this.sendAction('clearHistory');
      },
      exit: function exit() {
        this.sendAction('exit');
      },


      // This is a ridiculous hack to re-enable scrolling
      // that has been disabled when the mouse enters the
      // component.
      enteredField: function enteredField() {
        if (this.get('disableScrollingOnEnter')) {
          Ember.$('body').addClass('but-actually-scroll');
        }
      },
      leftField: function leftField() {
        if (this.get('disableScrollingOnEnter')) {
          Ember.$('body').removeClass('but-actually-scroll');
        }
      }
    }
  });
});