define('browzine-web/components/library-logo/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        library: Ember.computed.alias('for')
    });
});