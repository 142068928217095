define('browzine-web/routes/library', ['exports', 'browzine-web/mixins/reset-scroll-position', 'browzine-web/mixins/route-auth-helpers', 'browzine-web/errors/ApiSessionExpiredError', 'browzine-web/errors/AuthRequiredError'], function (exports, _resetScrollPosition, _routeAuthHelpers, _ApiSessionExpiredError, _AuthRequiredError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, _routeAuthHelpers.default, {
    auth: Ember.inject.service(),
    sync: Ember.inject.service(),
    myBookshelf: Ember.inject.service(),

    queryParams: {
      query: {
        replace: true
      },
      searchVisible: {
        replace: true
      }
    },

    applicationSession: Ember.inject.service(),
    errorReporter: Ember.inject.service(),
    analytics: Ember.inject.service(),

    model: function model(params) {
      return this.store.findRecord('library', params.library_id, { reload: true });
    },


    actions: {
      error: function error(err, transition) {
        // transition.intent.url seems to be the only way to find the previously-visited URL,
        // and it seems to not be in the public API, so want to access it and protect against
        // any future changes that may make this undefined
        if (transition && transition.intent && transition.intent.url) {
          err.lastTransitionUrl = transition.intent.url;
        }

        var sessionHasExpired = err instanceof _ApiSessionExpiredError.default;

        if (sessionHasExpired || err instanceof _AuthRequiredError.default) {
          return this.handleAPIAuthDemandError(err, transition.params.library.library_id, transition);
        }

        // Let unhandled errors bubble up for reporting/logging and putting the app into the error substate
        return true;
      },
      handleSearchError: function handleSearchError(err) {
        var _this = this;

        // The error action handler requires a transition to retry.  We just want to redo
        // search after the auth demand has been handled, so set up a refresh
        // transition and then attach some code to run after it that will
        // send property change notifications and cause the original search
        // to re-run
        var refreshTransition = this.refresh();
        refreshTransition.then(function () {
          _this.controllerFor('library').notifyPropertyChange('query');
        });

        return this.send('error', err, refreshTransition);
      }
    },

    afterModel: function afterModel(library, transition) {
      var _this2 = this;

      var applicationSession = this.get('applicationSession');
      var loggedInUser = applicationSession.get('loggedInUser');
      // libraryTokenRequired if:
      //   - the library path was changed (the user has followed a link into a different library's data)
      //   - the user is logged in (for My Bookshelf)

      // NOTE: We can no longer detect a change of library here because the application route
      // sets most recent library and selected library to be whatever it found in the path
      // before this code can execute.
      // By the time this code runs, selected library should be equal to whatever was found in the path
      var libraryTokenRequired = this.get('applicationSession.selectedLibrary') !== library.id || loggedInUser;

      var libraryTokenNotFound = !this.get('applicationSession').lookupLibraryToken(library.id);

      if (!library.get('subscriptions.web.active')) {
        this.get('flashMessages').alert('This library is not subscribed to BrowZine.');
        this.transitionTo('libraries');
      } else if (libraryTokenRequired && libraryTokenNotFound && transition.targetName !== 'library.unavailable' && transition.targetName !== 'library.vpn-required') {
        // If library token is required and
        // we're not specifically on the accept-token route,
        // library vpn-required route
        // or library unavailable route
        // Try to switch the user's authenticated library
        //
        // Note: it's kind of weird that we're doing this after model rather than before model.
        // Wouldn't we want to do this right away?
        //
        // set retry transition on session in case the auth flow takes the browser
        // away from browzine and through a library's proxy.
        transition.abort();
        this.get('auth').authenticateLibrary({ libraryId: library.id }, transition).then(function () {
          // If direct auth succeeded, we'll be in here with a valid token for
          // the library in application session & the selected library updated.
          // Retry the original transition
          transition.retry();
        }, function (err) {
          // misc. error happened trying to auth to the library
          // in the path.  We probably need to
          // create new UI to handle the on-site only & VPN cases.  back-end outage errors
          // perhaps could be handled with a flash message
          // For now transition to the token failure route
          // like the integration wayf does when it doesn't know what to do
          if (err.ipRangeError) {
            // There's a VPN, send the user to a screen
            // telling them to connect then retry
            _this2.get('applicationSession').set('transitionToRetryAfterAuth', transition);
            _this2.transitionTo('library.vpn-required', library.id);
          } else {
            _this2.transitionTo('library.unavailable', library.id);
          }
        });

        return;
      }

      this.get('analytics').setCustomDimension(1, library.id);

      if (loggedInUser) {
        Ember.run.schedule('afterRender', this, function () {
          _this2.get('sync').ensureDataLoaded().then(function () {
            var myBookshelf = _this2.get('myBookshelf');
            myBookshelf.set('library', library);
            return myBookshelf.get('model');
          }).catch(function (err) {
            console.log({ err: err });
          });
        });
      }
    },


    onLoggedInUserChange: Ember.observer('applicationSession.loggedInUser', 'sync.database', function (sender, key, value, rev) {
      var _this3 = this;

      // We need to refresh our model if the logged in user changes.  A change in user
      // should change the database property of the sync service.  If the design changes
      // such that that is no longer the case, update this so its dependent on some set of
      // ovservables that indicate the user has changed so we can properly refresh our model
      // to dump portions that pertained to the old user and pick up data pertinent to the
      // new user.

      // If the value actually changed, setup the my bookshelf service and perform a refresh
      if (this.get(key) != rev) {
        Ember.run.once(this, function () {
          if (_this3.get('applicationSession').get('loggedInUser')) {
            var myBookshelf = _this3.get('myBookshelf');
            myBookshelf.set('library', _this3.modelFor('library'));
            _this3.refresh();
          } else {
            _this3.refresh();
          }
        });
      }
    }),

    setupController: function setupController(controller, model) {
      controller.set('model', model);

      // This gives the library controller access to the current route so the
      // link in the header can not look active on the my-bookshelf and
      // my-articles child routes.
      controller.set('applicationController', this.controllerFor('application'));
      // Give the controller the ability to refresh the route
      controller.set('refreshCurrentRoute', this.refreshCurrentRoute.bind(this));
    },
    refreshCurrentRoute: function refreshCurrentRoute() {
      var applicationSession = this.get('applicationSession');
      applicationSession.set('languageSwitching', true);
      applicationSession.set('localizedModelsNeedReload', true);
      this.refresh();
    },
    titleToken: function titleToken(model) {
      return Ember.getWithDefault(model, 'name', 'Library');
    }
  });
});