define('browzine-web/services/window-open', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    open: function open(url, windowName, windowProperties) {
      window.open(url, windowName, windowProperties);
    }
  });
});