define('browzine-web/adapters/library', ['exports', 'ember-data', 'browzine-web/config/environment', 'browzine-web/mixins/authenticating-adapter', 'browzine-web/mixins/client-adapter', 'browzine-web/mixins/localization-adapter', 'browzine-web/utils/lodash-replacements'], function (exports, _emberData, _environment, _authenticatingAdapter, _clientAdapter, _localizationAdapter, _lodashReplacements) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_authenticatingAdapter.default, _clientAdapter.default, _localizationAdapter.default, {
    namespace: _environment.default.apiNamespace,
    host: _environment.default.apiHost,

    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      var changedAttributes = Object.keys(snapshot.changedAttributes());

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      data.data.attributes = (0, _lodashReplacements.pick)(data.data.attributes, changedAttributes);

      return this.ajax(url, 'PATCH', { data: data });
    },
    handleResponse: function handleResponse(status, headers, payload) {
      var unauthorizedError = this.handleUnauthorizedResponse(status, headers, payload);

      if (unauthorizedError) {
        return unauthorizedError;
      }
      return this._super.apply(this, arguments);
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    shouldReloadAll: function shouldReloadAll() {
      return true;
    }
  });
});