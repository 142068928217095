define('browzine-web/controllers/my-bookshelf/bookcase', ['exports', 'ember-intl'], function (exports, _emberIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    myBookshelf: Ember.inject.service(),

    editBookcase: false,

    bookcaseTitleEditableElementLabel: (0, _emberIntl.t)('my_bookshelf.bookcase_title_editable_element_label'),
    previousBookcaseLinkTitle: (0, _emberIntl.t)('my_bookshelf.previous_bookcase_link_title'),
    nextBookcaseLinkTitle: (0, _emberIntl.t)('my_bookshelf.next_bookcase_link_title'),

    checkEditBookcase: Ember.observer('editBookcase', 'media.isDesktop', 'media.isJumbo', function () {
      if (this.get('editBookcase') && (this.get('media.isDesktop') || this.get('media.isJumbo'))) {
        this.set('editBookcase', false);
      }
    }),

    actions: {
      updateTitle: function updateTitle(newTitle) {
        this.set('model.title', newTitle);
        this.get('myBookshelf').save();
      },
      editBookcase: function editBookcase() {
        this.set('editBookcase', !this.get('editBookcase'));
      }
    }
  });
});